import { Component, OnInit } from '@angular/core';
import { UserLocationService } from '../../app/_services/user.location.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Title } from '../../../node_modules/@angular/platform-browser';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { LoginService } from '../../app/_services/login.services';
@Component({
  selector: 'app-user-barcodes',
  templateUrl: './user-barcodes.component.html',
  styleUrls: ['./user-barcodes.component.css'],
  providers: [UserLocationService, LoginService]
})
export class UserBarcodesComponent implements OnInit {
  width = 3.8;
  width2 = 3.8;
  width3 = 3.99;
  width4 = 3.1;
  width5 = 3.0;
  width6 = 2.7;
  width7 = 2.4;
  width8 = 2.2;
  width9 = 2.0;
  width10 = 2.3;
  width1 = 2.3;
  uniqueId: any = '';
  userbarcodes: any = []
  companyId: any;
  title = "Print All Barcodes";
  userData: any;
  companyData: any
  companyCity:any;
  constructor(private titleService: Title,
    private router: Router,
     private BS: UserLocationService, private route: ActivatedRoute, private localStorage: LocalStorageService, private LS: LoginService) { }
  /**ngOnInit method
      * @constructor
      * the method to call on component loading
      */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    }


    this.route.queryParams.subscribe(params => {
      this.LS.getCompanyDetails({ 'uniqueId': params.uniqueId }).subscribe(data => {
        this.companyId = data['companyId'];
        this.uniqueId = data['uniqueId'];

        this.uniqueId = (params) ? params.uniqueId : this.companyData.uniqueId
        this.getUserBarCode();
        this.getCompanyDetails(params)
      })
    });
  }
  getCompanyDetails(x) {
    this.LS.getCompanyDetails({'uniqueId' :x.uniqueId}).subscribe(data=> {
      this.companyCity = data['companyCity'];

    });
  }
  /** getUserBarCode method : will give users based on input
     * @constructor
     * @param {Object} input- input for getLocationOrUserCode api
     * @param {Object} this.locations - output of getLocationOrUserCode */
  getUserBarCode() {
    let input = {};
    input = {
      'uniqueId': this.uniqueId,
      'locationFlag': 0,
      'companyId': this.companyId
    }
    /** getLocationOrUserCode api calling */
    this.BS.getLocationorUserCodes(input).subscribe(userData => {
      if (userData != null && userData != undefined) {
        /** this.userbarcodes array which holds list of users  */
        this.userbarcodes = userData;
        this.calculateBarcodeWidth(this.userbarcodes)

      }

    });
  }

  /** calculateBarcodeWidth method : which willc alculate the given barcode */
  calculateBarcodeWidth(data) {
    data.map((barcode, index) => {
      // if (barcode.userName.length === 1) {
      //   barcode.barcodeWidthFactor = 1 + ((200 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }else{
      //   barcode.barcodeWidthFactor = 1 + ((200 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }
      // if (barcode.userName.length === 1) {
      //   barcode.barcodeWidthFactor = 1 + ((200 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }else{
      //   barcode.barcodeWidthFactor = ((200 - 20))/((barcode.userName.length)*11)
      // }
      if (barcode.userName.length === 1) {
        barcode.barcodeWidthFactor = 1 + ((300 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      } else {
        barcode.barcodeWidthFactor = 1 + ((300 - 66 - (11 * (barcode.userName.length - 1))) / (46 + (11 * (barcode.userName.length - 1))))
      }
    })
  }
  back() {
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: this.uniqueId } });
  }
  printTable(id) {
    var query = {
      itemUniqueId: this.uniqueId,
      // companyId : this.companyId
    }
    window.open(
      `#/userBarcodePrint?itemUniqueId=${JSON.stringify(query)}`,
      "name",
      "width=1000,height=600"
    );
  }


}
