export class AppURL {
      //public static serverUrl = 'http://3.19.169.239:3010/api/';
      //  public static serverUrl = 'http://localhost:3010/api/'
      // *** In the master branch do not change or check in this file. This file should be checked out AS-IS and modified
      // *** in respective locals
      public static serverUrl = 'https://taptrac.com/api/'
      public static uiUrl = "https://taptrac.com/"
      // public static uiUrl = "http://3.19.169.239:4200";
      // public static uiUrl = "http://localhost:4200"
}
