import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';
import { CookieService } from 'angular2-cookie/core';
import { LoginService } from '../../../_services/login.services';
// import {IMyDpOptions} from '../../../../../node_modules/mydatepicker';
import { UserService } from "../../../_services/user.service";
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import * as _ from 'lodash';
import { ExcelService } from '../../../_services/excel.service';
@Component({
  selector: 'app-user-activity-report',
  templateUrl: './user-activity-report.component.html',
  styleUrls: ['./user-activity-report.component.css'],
  providers: [LoginService]
})
export class UserActivityReportComponent implements OnInit {
  userData: any = {};
  usersData: any = {};
  companyData: any = {};
  public TrnscationsReportsData: any = []
  title = 'TapTrack User Activity Report'
  userArr1: any = []
  userArr: any = []
  item: any = {}
  selectedId: any;
  error1 : any = false;
  Query: any = {}
  userArrIds:any=[];
  error: any = false;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm/dd/yyyy',
  };
  constructor(private excelService: ExcelService,private titleService: Title, private US: UserService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document, public local: LocalStorageService, private router: Router, private cookieService: CookieService, private Login: LoginService) {

    this.userData = this.local.get("loginInfo");

    this.userData = JSON.parse(this.userData);
    this.usersData = this.userData['userDetails'];
    this.companyData = this.userData['userDetails']['companyIdRelations'];
    this.Query['UserId'] = this.usersData.id
  }
  public loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = 'webshims.setOptions("forms-ext", {types: "date"}),webshims.polyfill("forms forms-ext")';
    script.src =
      ' webshims.setOptions("forms-ext", {types: "date"})'
    'webshims.polyfill("forms forms-ext")'
      ;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  ngOnInit() {
    this.loadScript();
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'webshims.setOptions("forms-ext", {types: "date"})';
    s.src = 'webshims.polyfill("forms forms-ext")';
    s.text = ``;
    this.renderer2.appendChild(this._document.body, s);
    this.titleService.setTitle(this.title);
    this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArrIds = []
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")" 
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);
      }
    });
  }

  //   GetReport(){
  //     if(this.Query.FromDate == null || this.Query.FromDate == '' || this.Query.ToDate == null || this.Query.ToDate == ''){
  //       alert('Please Select Required Fields')
  //     }else{
  //       this.Login.GetReportsData(this.Query).subscribe(reportData => {
  //         this.TrnscationsReportsData = reportData
  //       });
  //     }
  //   }
  itemreciever(items) {
    this.selectedId = JSON.stringify(this.retnum(items))
  }
  retnum(str) {
    var num = str.replace(/[^0-9]/g, '');
    return parseInt(num, 10);
  }
  generatehtml() {
    let fromDate;
    let Todate;
if(this.Query.FromDate && this.Query.ToDate) {
  let year = this.Query.FromDate.jsdate.getFullYear()
  let month = this.Query.FromDate.jsdate.getMonth() + 1
  let date = this.Query.FromDate.jsdate.getDate()
  let year1 = this.Query.ToDate.jsdate.getFullYear()
  let month1 = this.Query.ToDate.jsdate.getMonth() + 1
  let date1 = this.Query.ToDate.jsdate.getDate()
  if (date <= 9) {
    date = '0' + date
  }
  if (month <= 9) {
    month = '0' + month
  }
  if (date1 <= 9) {
    date1 = '0' + date1
  }
  if (month1 <= 9) {
    month1 = '0' + month1
  }
  fromDate = year + '-' + month + '-' + date
  Todate = year1 + '-' + month1 + '-' + date1
}
    
    // this.Login.GetReportsData(this.Query).subscribe(usrData => {
    //   this.reportsArry = usrData;
    // });
    // if(this.selectedId){
    //   this.Query['UserId'] = this.selectedId
    // }else{
    //   this.Query['UserId '] = this.Query.UserId 
    // }
   
    let input = {
      UserId: this.selectedId ? this.selectedId : this.Query.UserId
    }
    this.Query.UserId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
    if (this.Query.UserId == undefined) {
      // alert('select user')
      // return
      this.error = true;
    } else {
      this.error = false;
      let ipt = {};
      ipt = {
        'UserId' : this.Query.UserId,
        'fromDate' : (fromDate) ? fromDate : undefined,
        'toDate' : (Todate) ? Todate : undefined
      }
      window.open(`#/hrmlReportactivity?query=${JSON.stringify(ipt)}`, 'name', 'width=1000,height=600,scrollbars=1');
    }
  }
  clearfields() {
    this.Query.FromDate = '';
    this.Query.ToDate = '';

    this.item.reciever = '';
  this.error= false;
  this.error1  = false;


  }
  itmList: any = []
  usrData1 : any = [];
  dummy : any = [];
  usrId: any;
  getPdf() {
    let fromDate;
    let Todate;
    if (this.Query.FromDate && this.Query.ToDate) {
      let year = this.Query.FromDate.jsdate.getFullYear()
      let month = this.Query.FromDate.jsdate.getMonth() + 1
      let date = this.Query.FromDate.jsdate.getDate()
      let year1 = this.Query.ToDate.jsdate.getFullYear()
      let month1 = this.Query.ToDate.jsdate.getMonth() + 1
      let date1 = this.Query.ToDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      fromDate = year + '-' + month + '-' + date
       Todate = year1 + '-' + month1 + '-' + date1
    }
   
    if (this.item.reciever!=null && this.item.reciever!=undefined && this.item.reciever!='') {
      this.error = false;
      this.error1 = false;
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrData1 = usrData;
        let x = this.item.reciever.split("(")
        let y = x[1].split(")");
        this.usrId =  this.usrData1.find(getID);
        function getID(obj) {
          if (obj.username ==y[0]) {
            return obj.id
          }
        }
      let input = {
        'UserId': this.usrId.id,
        'fromDate' : fromDate,
        'toDate' : Todate
      }
      this.Login.GetReportsData(input).subscribe(usrData => {
        this.itmList = [];
        this.dummy = [];
        if (usrData != null  && usrData != undefined) {
          this.error1 = false;
          this.dummy = usrData;
          for(var i=0; i<this.dummy.length;i++) {
            let u = {} = this.dummy[i];
            let date = new Date(this.dummy[i].transactionTime);
            let month = (date.getMonth() + 1);
              let datee = date.getDate();
            u['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes());
            // u['transactionTime'] = date.getUTCDate() + '/'+ (date.getUTCMonth() + 1) + '/' + date.getFullYear().toString().substr(-2) + ' ' + this.formatAMPM(date);
            this.itmList.push(u);
          }
           this.itmList = usrData;
          const doc = new jsPDF();
          doc.setFontSize(10);
          // doc.setFontStyle("arial");
          doc.autoTable({
            styles: { width: 60, border: 2, halign: "center" },
            columnStyles: { text: { cellWidth: "auto", border: 2 } }, // European countries centered
            body: this.itmList,
            columns: [{ header: 'User', dataKey: 'name' }, { header: 'Transaction Date', dataKey: 'transactionTime' }, { header: 'Binders Processed', dataKey: 'binder' }],
            margin: { top: 40, bottom: 20 },
            tableLineWidth: 0.5,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              fontSize: 10,
              textColor: [25, 25, 25],
              theme: "plane",
              marginBottom: 20,
              lineWidth: 0.5,
              marginTop: 20,
              border: 4,
              // rowHeight: 6,
              lineColor: 200
            },
            beforePageContent: function(data) {
              doc.setFontSize(20),doc.setFontStyle("bold"), doc.text("User Activity Report", 75, 20);
            }
          });
          doc.save('UserActivityReport.pdf');
        } else {
          this.error1 = true;
        }
      });
    });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  }

   formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  onDateChanged(fromDate,toDate){
      if(new Date(fromDate.jsdate) < new Date(toDate.jsdate)){
        alert('todate should be greater than From date')
        this.Query.FromDate = ''
        this.Query.ToDate = ''
      }
   
  }

  itmexList: any = []
  usrexData1 : any = [];
  dummyex : any = [];
  usrIdex: any;
  generateExcel() {
    let fromDate;
    let Todate;
    if (this.Query.FromDate && this.Query.ToDate) {
      let year = this.Query.FromDate.jsdate.getFullYear()
      let month = this.Query.FromDate.jsdate.getMonth() + 1
      let date = this.Query.FromDate.jsdate.getDate()
      let year1 = this.Query.ToDate.jsdate.getFullYear()
      let month1 = this.Query.ToDate.jsdate.getMonth() + 1
      let date1 = this.Query.ToDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      fromDate = year + '-' + month + '-' + date
       Todate = year1 + '-' + month1 + '-' + date1
    }
   
    if (this.item.reciever!=null && this.item.reciever!=undefined && this.item.reciever!='') {
      this.error = false;
      this.error1 = false;
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrexData1 = usrData;
        let x = this.item.reciever.split("(")
        let y = x[1].split(")");
        this.usrIdex =  this.usrexData1.find(getID);
        function getID(obj) {
          if (obj.username ==y[0]) {
            return obj.id
          }
        }
      let input = {
        'UserId': this.usrIdex.id,
        'fromDate' : fromDate,
        'toDate' : Todate
      }
      this.Login.GetReportsData(input).subscribe(usrData => {
        this.itmexList = [];
        this.dummyex = [];
        if (usrData != null  && usrData != undefined) {
          this.error1 = false;
          this.dummyex = usrData;
          for(var i=0; i<this.dummyex.length;i++) {
            let u = {} = this.dummyex[i];
            let date = new Date(this.dummyex[i].transactionTime);
            let month = (date.getMonth() + 1);
              let datee = date.getDate();
            u['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes());
            // u['transactionTime'] = date.getUTCDate() + '/'+ (date.getUTCMonth() + 1) + '/' + date.getFullYear().toString().substr(-2) + ' ' + this.formatAMPM(date);
            this.itmexList.push(u);
          }
           this.itmexList = usrData;
           var title = 'User Activity Report';
           var header = ['User', 'Transaction Date','Binders Processed'];
           var fileName = 'UserActivityReport';
           var reportName = 'User Activity Report';
           const data1 = [] = this.itmexList;
           const data = [];
           let data2 = [];
           var self = this
           _.each(data1, function (obj) {
             data2=[];
             data2.push(obj.name)
             data2.push(obj.transactionTime)
             data2.push(obj.binder)
             data.push(data2)
           })
           let excelObj = {};
           excelObj = {
             'response' : data,
             'reportName' : reportName,
             'title' : title,
             'header' : header,
             'fileName' : fileName,
           }
           this.excelService.generateExcel(excelObj);
    
        } else {
          this.error1 = true;
        }
      });
    });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  }
}

