import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { AppURL } from "../../apiUrl";
import { map } from "rxjs/operators";
@Injectable()
export class ItemService {
  private messageSource = new BehaviorSubject<string>("default message");
  currentMessage = this.messageSource.asObservable();
  constructor(public http: HttpClient) { }

  createItemType(data) {
    return this.http
      .post(AppURL.serverUrl + "ItemTypes", data)
      .pipe(map((res: Response) => res));
  }

  getItemType(input) {
    return this.http
      .post(AppURL.serverUrl + "ItemTypes/getItemType", input)
      .pipe(map((res: Response) => res));
  }
  getscarchData(data) {
    // return this.http.get(AppURL.serverUrl + '/Users');
    return this.http.post(AppURL.serverUrl+ 'Items/getscarchusers', data).pipe(map((res: Response) => res));

  }
  getItemList(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItems", input)
      .pipe(map((res: Response) => res));
  }
  getItemListCount(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItemsCount", input)
      .pipe(map((res: Response) => res));
  }
  SentitemsTo(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/sendItemTransaction", input)
      .pipe(map((res: Response) => res));
  }
  getItemInvertory(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/binderInventoryReport", input)
      .pipe(map((res: Response) => res));
  }
  createItem(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/createItem", input)
      .pipe(map((res: Response) => res));
  }
  checkExistence(input){
    return this.http
    .post(AppURL.serverUrl + "Items/findUniqueItems", input)
    .pipe(map((res: Response) => res));
  }

  uIdValidation(input){
    return this.http
    .post(AppURL.serverUrl + "Companies/itemValidation", input)
    .pipe(map((res: Response) => res));
  }

  getItemReceived(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/getItemReceived", input)
      .pipe(map((res: Response) => res));
  }
  getItemReceivedCount(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/getItemReceivedCount", input)
      .pipe(map((res: Response) => res));
  }

  getItemsOwned(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItemsOwned", input)
      .pipe(map((res: Response) => res));
  }
  getItemsOwnedCount(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItemsOwnedCount", input)
      .pipe(map((res: Response) => res));
  }
  getItemsOwnedsearch(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItemsOwnedsearch", input)
      .pipe(map((res: Response) => res));
  }
  getItemSent(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/getItemSent", input)
      .pipe(map((res: Response) => res));
  }
  getItemSentsearch(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/getItemSentsearch", input)
      .pipe(map((res: Response) => res));
  }
  getItemSentCount(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/getItemSentCount", input)
      .pipe(map((res: Response) => res));
  }

  countofItems(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItemsCount", input)
      .pipe(map((res: Response) => res));
  }
  cancelSentItem(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/cancelSentItem", input)
      .pipe(map((res: Response) => res));
  }
  
  acknowledgeRecieveItem(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/acknowledgeRecieveItem", input)
      .pipe(map((res: Response) => res));
  }
  searchItemReceived(input) {
    return this.http
      .post(AppURL.serverUrl + "Transactions/searchItemReceived", input)
      .pipe(map((res: Response) => res));
  }
  itemClose(input) {
    return this.http
      .post(AppURL.serverUrl + "Items/getItemsOwned", input)
      .pipe(map((res: Response) => res));
  }
 
  scanitemclose(input){
    return this.http
    .post(AppURL.serverUrl + "Transactions/closeSelectedItems", input)
    .pipe(map((res: Response) => res));
  }

  itemCheckout(input){
    return this.http
    .post(AppURL.serverUrl + "Items/getCheckoutItems", input)
    .pipe(map((res: Response) => res));
  }

  checkoutItemSubmt(input){
    return this.http
    .post(AppURL.serverUrl + "Transactions/checkoutSelectedItems", input)
    .pipe(map((res: Response) => res));
  }


  printBar(input){
    return this.http
    .post(AppURL.serverUrl + "Items/findUniqueItems", input)
    .pipe(map((res: Response) => res));
  }
  printBar1(input){
    return this.http
    .post(AppURL.serverUrl + "Items/findUniqueItemsByCompany", input)
    .pipe(map((res: Response) => res));
  }

  userSubmit(input){
    return this.http
    .post(AppURL.serverUrl + "Transactions/assignItemTransaction", input)
    .pipe(map((res: Response) => res));
  }
  filter(input){
    return this.http
    .post(AppURL.serverUrl + "ItemStatuses/getItemStatusList", input)
    .pipe(map((res: Response) => res));
  }
  searchData(input){
    return this.http
    .post(AppURL.serverUrl + "Items/getItemsBasedonFilter", input)
    .pipe(map((res: Response) => res));
  }
  searchDataCount(input){
    return this.http
    .post(AppURL.serverUrl + "Items/getItemsBasedonFilterCount", input)
    .pipe(map((res: Response) => res));
  }
  searchsubmitdata(input){
    return this.http
    .post(AppURL.serverUrl + "Transactions/changeItemStatusTransaction", input)
    .pipe(map((res: Response) => res));
  }
  
  // report(input){
  //   return this.http
  //   .post("http://localhost:3011/api/ItemTypes/report", input)
  //   .pipe(map((res: Response) => res));
  // }
}
