import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppURL } from '../../apiUrl';
import { map } from 'rxjs/operators';
@Injectable()
export class UserLocationService {
    private messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource.asObservable();
    constructor(public http: HttpClient) { }

    getLocationorUserCodes(data) {
        return this.http.post(AppURL.serverUrl+ 'Users/getLocationOrUserCode', data).pipe(map((res: Response) => res));
      }    
       
      // getcommandBarCodes() {
      //   return this.http.get(AppURL.serverUrl + '/CommandBarcodes');
      // }
}