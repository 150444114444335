import { Injectable } from '@angular/core';
import {UserService} from '../../../_services/user.service';
@Injectable({
    providedIn: BookService
  })
export class BookService {
    constructor(private US: UserService) { }
    getBooks() {
   const data = ["1000",
    "1001",
    "1002",
    "1003",
    "1004",
    "1005",
    "1006",
    "1007",
    "1008",
    "1009",
    "1010"
]
return data;
}
}








