import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Title } from '../../../../../node_modules/@angular/platform-browser';
@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.css'],
  providers: [LoginService]
})
export class InviteUsersComponent implements OnInit {
  route: any;
  inviteShow: any =true;
  title = 'TapTrack Invite User';
  constructor(
    private titleService : Title,
    private ss : supervisorService, 
    public local: LocalStorageService,
    private router : Router
  ) { }
  userData1 :any;
  mailWrng: any = false;
  userId:any;
  emailvalidation = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {      
      this.userData1 = JSON.parse(this.userData1);
      this.userId = this.userData1['userId'];
    }
  }
  inviteUser:any;
  mailerror:any = false;
  inviteUserSubmit() {
    if(this.inviteUser == null  || this.inviteUser == ''){
      this.mailerror = true;
      this.mailWrng = false;
      return;
    }
    if(!this.emailvalidation.test(this.inviteUser)){
      this.mailerror = false;
      this.mailWrng = true;
      return;
    }
    else {
      this.mailerror = false;
      this.mailWrng = false;
    let input = {};
    input = {
      'toWhom':this.inviteUser,
      'userId':this.userId
    }
    this.ss.inviteUser(input).subscribe(data => {
      if(data.status == 1){
        this.router.navigate(['/infoInivite'])
      }
    }
   
  );
  }
  
}
clear(){
  this.inviteUser = '';
  this.mailerror = '';
  this.mailWrng = '';
}
}
