import { Component, OnInit } from '@angular/core';
declare var $;
import { ItemService } from '../../../_services/item.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';
import { UserService } from '../../../_services/user.service';
import { CompleterService, CompleterData } from 'ng2-completer';
import { Title } from '../../../../../node_modules/@angular/platform-browser';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-package-item',
  templateUrl: './add-package-item.component.html',
  styleUrls: ['./add-package-item.component.css'],
  providers: [ItemService]
})
export class AddPackageItemComponent implements OnInit {
  title = 'TapTrack Add Package Item'
  userData: any = {};
  companyData: any = {};
  getItemData: any = [];
  itemArray = [];
  item: any = {};
  itmTypeId: any;
  format: any = '999-9999999';
  userArr: any = [];
  userArr1: any = [];
  protected dataService: CompleterData;
  protected searchStr: string;
  protected captain: string;
  maxInputLength = 11
  errorMessage = ''
  disableButton = false
  userArrIds = [];
  succesShow = false;

  constructor(private IS: ItemService, private titleService: Title, private localStorage: LocalStorageService, private router: Router, private US: UserService) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData['userDetails']['companyIdRelations'];
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.getItemTypes();
    this.US.getUsers({ companyId: this.companyData.companyId }).subscribe(usrData => {
      this.userArr1 = [];
      this.userArrIds = [];
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = '';
        nameStr = this.userArr1[i]['userFirstName'] + ' ' + this.userArr1[i]['userLastName'] + '(' + this.userArr1[i]['username'] + ')';
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);
      }

    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkUniqIdExistence(value) {
    var self = this
    var query = {
      itemUniqueId: value,
      companyId: this.companyData.companyId
    }
    // this.IS.checkExistence(query).subscribe(existedItem => {
    //   if (existedItem.status) {
    //     self.showErrorMessage('ID Already Exist')
    //     this.item['uniqueId'] = ''
    //   }
    // });

    this.IS.uIdValidation(query).subscribe(existedItem => {

    }, error => {
      this.showErrorMessage("ID Already Exist");
      this.item['uniqueId'] = '';
    });
  }

  insertDash(item, value) {
    this.errorMessage = ''
    var nextChar = this.format.charAt(value.length)
    if (nextChar.toString() == '-') {
      var lastChar = value.charAt(value.length - 1)
      if (lastChar == '-') {
        item['uniqueId'] = value.slice(0, -1)
      } else {
        item['uniqueId'] = value + '-'
      }
    } else {
      item['uniqueId'] = value
    }
    if (value.length == this.format.length) {
      this.checkUniqIdExistence(value)
    }
  }
  showErrorMessage(msg) {
    this.disableButton = false
    var self = this
    this.errorMessage = 'Item Unique Id already exist'
    // setTimeout(function () {
    //   self.errorMessage = ' '
    // }, 1000)
  }

  checkBoxChanged(value) {
    this.item = {}
    this.item = {
      uniqueCheckbox: value
    }
  }
  createPackage() {
    this.disableButton = true
    console.log(this.item)

    if (this.item.uniqueId) {
      let itemInput = {};
      itemInput = this.item;
      itemInput['regexFormat'] = this.regexFormat;
      itemInput["companyId"] = this.companyData.companyId;
      itemInput['itemArray'] = [];
      itemInput['itemArray'].push((this.item.uniqueId) ? this.item.uniqueId : null);
      itemInput['userId'] = this.userData['userDetails']['id'];
      itemInput['package'] = true;
      itemInput["itemType"] = this.itmTypeId;
      if (!this.item.uniqueId && !this.item.uniqueCheckbox) {
        this.showErrorMessage("Please Enter Unique Id")
        return
      }
      else if (!this.item.tagName || this.item.tagName == ' ') {
        this.showErrorMessage("Please Enter Tag Name")
        return
      } else if (!this.item.tagDesc || this.item.tagDesc == ' ') {
        this.showErrorMessage("Please Enter Tag Description")
        return
      } else if (this.item.transFlag && (!this.item.reciever || this.item.reciever == '')) {
        this.showErrorMessage("Please Select User to be Assigned")
        return
      } else {
        itemInput['itemType'] = Number(itemInput['itemType'])
        // itemInput['reciever']=this.userArrIds[this.userArr.indexOf(this.item.reciever)]
        if (this.item.reciever) {
          let x = this.item.reciever.split("(");
          let y = x[1].split(")");
          itemInput["reciever"] = y[0];
        }
        this.IS.createItem(itemInput).subscribe(createdItem => {
          this.disableButton = false
          this.item = {};
          itemInput = {};
          this.succesShow = true;
        }, error => {
          // console.log('erererererrrrrrreeeeeerrrrrrr',error);
          this.showErrorMessage(error.error.error.message);
          this.item = {};
          itemInput = {};
          this.succesShow = false;
        });
      }
    } else {
      this.errorMessage = 'Enter item unique id'
    }
  }
  getItemTypes() {
    this.IS.getItemType({ companyId: this.companyData.companyId }).subscribe(getItemData => {
      this.getItemData = getItemData;
    });
  }
  regexFormat: any;
  public onChange(value): void {
    this.itmTypeId = value;
    var self = this
    _.each(this.getItemData, function (item) {
      if (item.itemTypeId == value) {
        self.regexFormat = item.regexFormat;
        self.format = item.regexFormat.toString()
        self.maxInputLength = self.format.length
      }
    })

  }
  isDisabled = true;
  triggerSomeEvent() {
    this.isDisabled = !this.isDisabled;
    return;
  }
  cancel() {
    this.router.navigate(['/home']);
    this.item = {}
  }
  back() {
    this.succesShow = false;
  }
}
