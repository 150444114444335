import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailMask'
})
export class EmailMaskPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    
    // let aa = "hchitalwala@penielsolutions.com";
    let aa = phoneNumber.split("@");
    const visibleDigits = aa[1].length;
    var az = aa[0]
    let maskedSection = aa[0].slice(0, -visibleDigits);
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmm ',aa[0],aa[1], aa[0].length, visibleDigits);
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmm ', phoneNumber.slice(3, 6));
    let visibleSection = aa[1].slice(-visibleDigits);
    // return maskedSection.replace(/./g, '*') + '-'+visibleSection;
return aa[0].replace(/./g, '*')+'@'+aa[1];
    // return phoneNumber.slice(0,3).replace(/./g, '*')+ '-'+ phoneNumber.slice(3, 6).replace(/./g, '*') + '-'+visibleSection
  }
}