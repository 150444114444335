import { supervisorService } from './_services/supervisor.service';
import { BrowserModule } from '@angular/platform-browser';
import { MyDatePickerModule } from 'mydatepicker';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './auth/authorization.interceptor';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { PrintBarCodesComponent } from './print-bar-codes/print-bar-codes.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LocationBarcodesComponent } from './location-barcodes/location-barcodes.component';
import { UserBarcodesComponent } from './user-barcodes/user-barcodes.component';
import { EasyScanningComponent } from './easy-scanning/easy-scanning.component';
import { CommandBarcodesComponent } from './command-barcodes/command-barcodes.component';
import { CookieService } from 'angular2-cookie/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { SuperAdminLoginComponent } from './super-admin-login/super-admin-login.component';
import { AddItemTypeComponent } from './home-page/item/add-item-type/add-item-type.component';
import { HomeMainComponent } from './home-page/home-main/home-main.component';
import { AddItemComponent } from './home-page/item/add-item/add-item.component';
import { AddPackageItemComponent } from './home-page/item/add-package-item/add-package-item.component';
import { ItemCheckoutScannerComponent } from './home-page/item/item-checkout-scanner/item-checkout-scanner.component';
import { ItemCloseScannerComponent } from './home-page/item/item-close-scanner/item-close-scanner.component';
import { AssignToUserComponent } from './home-page/item/assign-to-user/assign-to-user.component';
import { PrintBarcodeComponent } from './home-page/item/print-barcode/print-barcode.component';
import { ChangeItemStatusComponent } from './home-page/item/change-item-status/change-item-status.component';
import { ItemListComponent } from './home-page/item/item-list/item-list.component';
import { ItemCheckoutComponent } from './home-page/item/item-checkout/item-checkout.component';
import { ItemCloseComponent } from './home-page/item/item-close/item-close.component';
import { ItemInventorComponent } from './item-inventor/item-inventor.component';
import { AddProfileComponent } from './home-page/user/add-profile/add-profile.component';
import { EditProfileComponent } from './home-page/user/edit-profile/edit-profile.component';
import { HtmlReportInventoryComponent } from './item-inventor/html-report-inventory/html-report-inventory.component';
import { GlossaryComponent } from '../app/glossary/glossary.component';
import { MydashboardComponent } from './home-page/mydashboard/mydashboard.component';
import { PendingUsersComponent } from './home-page/supervisor/pending-users/pending-users.component';
import { SupervisorsGridComponent } from './home-page/supervisor/supervisors-grid/supervisors-grid.component';
import { InviteUsersComponent } from './home-page/supervisor/invite-users/invite-users.component';
import { MessagesComponent } from './home-page/supervisor/messages/messages.component';
import { AddMessageComponent } from './home-page/supervisor/messages/add-message/add-message.component';
import { MailRoomReportComponent } from './home-page/reports/mail-room-report/mail-room-report.component';
import { HtmlmailreportComponent } from './home-page/reports/mail-room-report/htmlreportmail/htmlmailreport/htmlmailreport.component';

import { ItemInventoryReportComponent } from './home-page/reports/item-inventory-report/item-inventory-report.component';
import { UserActivityReportComponent } from './home-page/reports/user-activity-report/user-activity-report.component';
import { UserTrackingReportComponent } from './home-page/reports/user-tracking-report/user-tracking-report.component';
import { ItemsReportComponent } from './home-page/reports/items-report/items-report.component';
import { ItemClosedFilterReportComponent } from './home-page/reports/item-closed-filter-report/item-closed-filter-report.component';
import { ItemSearchReportComponent } from './home-page/reports/item-search-report/item-search-report.component';
import { TimeHeldReportComponent } from './home-page/reports/time-held-report/time-held-report.component';
import { ItemsSendByUserReportComponent } from './home-page/reports/items-send-by-user-report/items-send-by-user-report.component';
import { ItemTypeReportComponent } from './home-page/reports/item-type-report/item-type-report.component';
import { Ng2CompleterModule } from 'ng2-completer';
import { BookService } from './home-page/item/add-item/users';
import { UserService } from './_services';
import { InfoMessageComponent } from './home-page/supervisor/messages/add-message/info-message/info-message.component';
import { HelpAddItemComponent } from '../app/tutorials/help-add-item/help-add-item.component';
import { InfoInviteUserComponent } from './home-page/supervisor/invite-users/info-invite-user/info-invite-user.component';
import { HelpAddItemTypeComponent } from './tutorials/help-add-item-type/help-add-item-type.component';
import { HelpCheckoutScannerComponent } from './tutorials/help-checkout-scanner/help-checkout-scanner.component';
import { HelpMessagesComponent } from './tutorials/help-messages/help-messages.component';
import { HelpPendingUsersComponent } from './tutorials/help-pending-users/help-pending-users.component';
import { HelpPrintBarCodesComponent } from './tutorials/help-print-bar-codes/help-print-bar-codes.component';
import { HelpRegisterCompanyComponent } from './tutorials/help-register-company/help-register-company.component';
import { HelpSupervisorGridComponent } from './tutorials/help-supervisor-grid/help-supervisor-grid.component';
import { InfoForgotComponent } from './forgot/info-forgot/info-forgot.component';
import { HtmlReportTrackingComponent } from './home-page/reports/user-tracking-report/html-report-tracking/html-report-tracking/html-report-tracking.component';
import { HtmlReportSentbyComponent } from './home-page/reports/items-send-by-user-report/html-report-sentby/html-report-sentby/html-report-sentby.component';
import { HtmlReportMailComponent } from './home-page/reports/mail-room-report/html-report-mail/html-report-mail/html-report-mail.component';
import { TimeHeldHtmlreportComponent } from './home-page/reports/time-held-report/time-held-report/time-held-htmlreport/time-held-htmlreport.component';
import { UserActivityHtmlreportComponent } from './home-page/reports/user-activity-report/user-activity-html/user-activity-htmlreport/user-activity-htmlreport.component';
import { ItemsReporthtmlComponent } from './home-page/reports/items-report/items-report-html/items-reporthtml/items-reporthtml.component';
import { ItemHtmlreportComponent } from './home-page/reports/item-closed-filter-report/item-html-report/item-htmlreport/item-htmlreport.component';
import { ItemReporthtmlComponent } from './home-page/reports/item-search-report/item-report-html/item-reporthtml/item-reporthtml.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BarcodeManagementComponent } from './barcode-management/barcode-management.component';
import { MatCardModule, MatToolbarModule, MatToolbar, MatButtonModule, MatButton ,MatMenuModule } from '@angular/material';
import { AddprofileUrlComponent } from './addprofile-url/addprofile-url.component';
import { ItemsReportsSubComponetComponent } from './home-page/itemsreport-subComponent/items-reports-sub-componet/items-reports-sub-componet.component';
import { ItemAuditreportComponent } from './home-page/item-tracking-audit/item-auditreport/item-auditreport.component';
import { PdfReportComponent } from './home-page/reports/mail-room-report/pdf-report/pdf-report.component';
import { ViewBarcodeComponent } from './view-barcode/view-barcode.component';
import { EditItemListtagComponent } from './home-page/item/item-list/Edit-Itemlist/edit-item-listtag/edit-item-listtag.component';
import { PrintUniqiebarcodesComponent } from './home-page/item/add-item/items-barcodes/print-uniqiebarcodes/print-uniqiebarcodes.component';
import { HelpChangeItemStatusComponent } from './tutorials/help-change-item-status/help-change-item-status.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { BindersProcessedReportComponent } from './home-page/reports/binders-processed-report/binders-processed-report.component';
import { HtmlBindersProcessedReportComponent } from './home-page/reports/binders-processed-report/html-binders-processed-report/html-binders-processed-report.component';
import { LocationPrintComponent } from './location-barcodes/location-print/location-print.component';
import { UserBarcodePrintComponent } from './user-barcodes/user-barcode-print/user-barcode-print.component';
import { CommandBarcodePrintComponent } from './command-barcodes/command-barcode-print/command-barcode-print.component';
import { NumberMaskPipe } from './login/number-mask.pipe';
import { EmailMaskPipe } from './login/email-mask.pipe';
import { ItemsHeldByUserReportComponent } from './items-held-by-user-report/items-held-by-user-report.component';
import { ItemHeldHtmlComponent } from './items-held-by-user-report/item-held-html/item-held-html.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    PrintBarCodesComponent,
    HomePageComponent,
    LocationBarcodesComponent,
    UserBarcodesComponent,
    EasyScanningComponent,
    CommandBarcodesComponent,
    SuperAdminLoginComponent,
    AddItemTypeComponent,
    HomeMainComponent,
    AddItemComponent,
    AddPackageItemComponent,
    ItemCheckoutScannerComponent,
    ItemCloseScannerComponent,
    AssignToUserComponent,
    PrintBarcodeComponent,
    ChangeItemStatusComponent,
    ItemListComponent,
    ItemCheckoutComponent,
    ItemCloseComponent,
    ItemInventorComponent,
    AddProfileComponent,
    EditProfileComponent,
    HtmlReportInventoryComponent,
    GlossaryComponent,
    MydashboardComponent,
    PendingUsersComponent,
    SupervisorsGridComponent,
    InviteUsersComponent,
    MessagesComponent,
    AddMessageComponent,
    MailRoomReportComponent,
    HtmlmailreportComponent,
    ItemInventoryReportComponent,
    UserActivityReportComponent,
    UserTrackingReportComponent,
    ItemsReportComponent,
    ItemClosedFilterReportComponent,
    ItemSearchReportComponent,
    TimeHeldReportComponent,
    ItemsSendByUserReportComponent,
    ItemTypeReportComponent,
    InfoMessageComponent,
    HelpAddItemComponent,
    InfoInviteUserComponent,
    HelpAddItemTypeComponent,
    HelpCheckoutScannerComponent,
    HelpMessagesComponent,
    HelpPendingUsersComponent,
    HelpPrintBarCodesComponent,
    HelpRegisterCompanyComponent,
    HelpSupervisorGridComponent,
    InfoForgotComponent,
    HtmlReportTrackingComponent,
    HtmlReportSentbyComponent,
    HtmlReportMailComponent,
    PdfReportComponent,
    TimeHeldHtmlreportComponent,
    UserActivityHtmlreportComponent,
    ItemsReporthtmlComponent,
    ItemHtmlreportComponent,
    ItemReporthtmlComponent,
    BarcodeManagementComponent,
    AddprofileUrlComponent,
    ItemsReportsSubComponetComponent,
    ItemAuditreportComponent,
    ViewBarcodeComponent,
    EditItemListtagComponent,
    PrintUniqiebarcodesComponent,
    HelpChangeItemStatusComponent,
    ErrorPageComponent,
    BindersProcessedReportComponent,
    HtmlBindersProcessedReportComponent,
    LocationPrintComponent,
    UserBarcodePrintComponent,
    CommandBarcodePrintComponent,
    NumberMaskPipe,
    EmailMaskPipe,
    ItemsHeldByUserReportComponent,
    ItemHeldHtmlComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxBarcodeModule,
    Ng2CompleterModule,
    NgxSpinnerModule,
    MyDatePickerModule,
    MatCardModule, MatToolbarModule, MatButtonModule,MatMenuModule,
  ],
  exports:[
    BrowserModule,
    MatCardModule, MatToolbarModule, MatButtonModule,MatMenuModule,
  ],
  providers: [
    CookieService,
    BookService,
    UserService,
    supervisorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
      
    },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
