import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../app/_services/login.services';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import * as _ from 'lodash';
import { ProfileService } from '../_services/profile.service';
declare var $; 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService,ProfileService]
})
export class LoginComponent implements OnInit {

  title ='Tap Track :: Login';
  usernameshw:any =false;
  passwordshw :any = false;
  wrngdetails :any = false;
  loginForm : any = {};
  allCompanynames: any = [];
  allCompanynames1: any = [];
  forgotShow :any =false;
  loginResponse: any = {};
  KEY = 'loginInfo';
  errorFormFieldsArr = [];
  activeLink:boolean=false;
  userProfileFormFields = [
    "username", "password"
  ]
  loginForm1: any = {};
  optShow:boolean=false;
  otpshw: boolean = false;
  mblFlag:boolean =  false;
  emlFlag:boolean =  false;
  phoneNumber: string;
  emailShow: any;
  mblshw: boolean;
  /** login method : 
   * @param {String} username - username of the user
   * @param {String} password - password
   * @param {Number} companyId - companyId
   */
  login() {
    this.usernameshw = false;
    this.passwordshw = false;
    this.wrngdetails = false;
    this.errorFormFieldsArr = [];
    this.userProfileFormFields.forEach((item)=>{
      if(this.loginForm[item] == null || this.loginForm[item] == ''){
        this.errorFormFieldsArr.push(item);
        this[item + "shw"] = true;
      }else{
        this[item + "shw"] = false;
      }
    });
    if(this.errorFormFieldsArr.length > 0){
      return;
    };


    // if(this.loginForm.username == null || this.loginForm.username == '' && this.loginForm.password == null || this.loginForm.password == ''){
    //   this.usernameshow = true;
    //   this.passwordshow = true;
    //   this.wrngdetails = false;
    //   return
    //     }else if(this.loginForm.username == null || this.loginForm.username == ''){
    //       this.usernameshow = true;
    //       this.passwordshow = false;
    //       this.wrngdetails = false;
    //     }else if(this.loginForm.password == null || this.loginForm.password == ''){
    //       this.usernameshow = false;
    //       this.passwordshow = true;
    //       this.wrngdetails = false;
    //     }
    // else {
    //   this.usernameshow = false;
    //   this.passwordshow = false;

    /** calling login api 
     *@param {Object} this.loginForm is an input
     * loginData  is a output from an login api
     * @param {Object} this.loginResponse - this object will hold the response.
    */

      
      
    this.LS.login(this.loginForm).subscribe(loginData => {
      this.loginResponse = loginData;
      this.otpshw = false;

      /** if login response exist it will allow you to enter into the application otherwise if it throw an error and it will be in login page only. */
      if (this.loginResponse) {
        // this.local.clear();
        this.local.set(this.KEY, JSON.stringify(this.loginResponse), 30, 'm');
        // this.router.navigate(['/home'])
        let usrInput ={"username" : this.loginForm.username,
        "companyId":this.loginForm.companyId}
        this.LS.getUserInfo(usrInput).subscribe(usrData => {
          let otpInput = {
            id : usrData['authId']
          }
          if(usrData['authFlag'] == 0) {
            
            this.router.navigate(['/home'])
          } else if(usrData['authFlag'] == 1) {
            if((usrData['userMobile'] == null || usrData['userMobile'] == '') && usrData['mobileFlag'] == 1) {
              this.mblFlag = true;
            }
            else {
              this.phoneNumber = usrData['userMobile'];
              this.emailShow = usrData['email']
              if(usrData['mobileFlag'] == 1) {
                this.LS.requestSms(otpInput).subscribe(smsData => {
                  console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
                });
              } else if (usrData['emailFlag'] == 1) {
                this.emlFlag = true;
                this.LS.emailOTP(otpInput).subscribe(smsData => {
                  console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
                });
              }
             
              // console.log('emtered');
           
          //   setTimeout(function() {
          //     console.log('sssssssssssssssssssssssssssss ', document.getElementById('resendOTP'));
          //     // document.getElementById('resendOTP').disabled = false;
          // }, 30000);

          // setTimeout(function () {
          //   this.activeLink = true;
          //   document.getElementById('resendOTP');
          // }, 30000);
           
            }
           
            document.getElementById("openModalButton").click();
          } else {
            this.router.navigate(['/login'])
          }
         
      });
        
      } else {
        this.router.navigate(['/login'])
      }


    },
    /** throws an error if login credentials does not match */
    error => {
      if (error.error) {
        this.wrngdetails = true;
        this.usernameshw = false;
        this.passwordshw = false;
      }
      else if (error.error.error.statusCode == "401") {
        this.wrngdetails = true;
        this.usernameshw = false;
        this.passwordshw = false;
        return;
      }else{
        this.wrngdetails = false;
      }
    }
  );
  // }
  }


  constructor(private PS: ProfileService, private LS: LoginService,private titleService: Title, private router: Router,public local: LocalStorageService,public session: SessionStorageService){

  }
  /**ngOnInit method
   * @constructor
   * the method to call on component loading
   */

   close() {
    document.getElementById("closeBtn").click();
    this.otpshw = false;
    this.loginForm1 = {};
   }
  GoHome() {
    
    let usrInput ={"username" : this.loginForm.username,
    "companyId":this.loginForm.companyId}
    this.LS.getUserInfo(usrInput).subscribe(usrData => {
      let otpInput = {
        id : usrData['authId'],
        token : parseInt(this.loginForm1.otp)
      }
      this.LS.verify(otpInput).subscribe(usrData1 => {
        // console.log('nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn ', otpInput);
        if(usrData1['error'] == 'OTP verification failed.') {
          this.otpshw = true;
        } else {
          
          
          this.otpshw = false;
          document.getElementById("closeBtn").click();
          // document.getElementById("openModalButton1").click();
          this.router.navigate(['/home'])
        }
      },
      error=>{
        // console.log('eeeeeeeeeeeeeeeeeeeeeeeeee ', error);
        this.otpshw = true;
      })
    })
    
  }
  
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.allCompanies();
    // this.phoneNumber = "8331807928";
  }
  companayvalue:any;
  selectcompany(event){
    this.companayvalue =event.target.value;
  }
  forgot(){
    this.forgotShow = true;
  }
  companyId:any;
  /** allCompanies method:
   * @constructor
   * the method to fetch company information and also it will filter Norcross company not to include in the response.
   */
  allCompanies() {
    var self =this
    self.companyId = self.companayvalue;
    /** getCompanies api calling */
    self.LS.getCompanies().subscribe(
        data => {
        self.loginForm.companyId = data[1].companyId
        self.allCompanynames1 = data;
        _.each(self.allCompanynames1, function (datas) {
          // console.log(datas)
          if (datas.companyId != 72) { /** Dont show 'Norcross' on UI - hence filtering**/
            self.allCompanynames.push(datas)
          } else {
            // console.log(data)
          }
        })
      },
        error => {

      }
    )

  }
/** comapnyRout method:
 * on clicking on company it will move to barcode management.
 */
  comapnyRout(id){
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: id } });
  }

  resendOTP() {
    let usrInput ={"username" : this.loginForm.username,
        "companyId":this.loginForm.companyId}
        this.LS.getUserInfo(usrInput).subscribe(usrData => {
          let otpInput = {
            id : usrData['authId']
          }
          if(usrData['authFlag'] == 0) {
            this.router.navigate(['/home'])

          } else if(usrData['authFlag'] == 1) {
            // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaa ', usrData, usrInput,otpInput);
            if(usrData['mobileFlag'] == 1) {
              this.LS.requestSms(otpInput).subscribe(smsData => {
              });
            } else if (usrData['emailFlag'] == 1) {
              this.LS.emailOTP(otpInput).subscribe(smsData => {
                
              });
            }
           
            // document.getElementById("openModalButton").click();
          } else {
            this.router.navigate(['/login'])
          }
         
      });

  }
  type:any={};
  updateMobile() {
    if(this.loginForm1.mobile) {
      // console.log('9999999999999');
      if(this.loginForm1.mobile.toString().length == 10) {
        this.mblshw = false;
        // console.log('*************************************** ', this.loginForm1.mobile.toString().length);
         let usrInput ={"username" : this.loginForm.username,
    "companyId":this.loginForm.companyId}
    this.LS.getUserInfo(usrInput).subscribe(usrData => {
      this.type = usrData;
      this.type.userMobile = this.loginForm1.mobile;
      this.type.password = this.loginForm.password;
      this.type.confirmPassword = this.loginForm.password;
      delete this.type.userName;
      let regInput = {};
      regInput = {
        email : this.type['email'],
        phone : this.type['userMobile'],
        countryCode : this.type['countryCode']
      }
      this.LS.register(regInput).subscribe(regResp => {
      this.type.authId = regResp['user']['id'];
      this.PS.editProfile(this.type).subscribe(getUserData => {
    
        this.mblFlag = false;
        let otpInput = {
          id : regResp['user']['id']
        }
        this.phoneNumber = usrData['userMobile'];
        this.emailShow = usrData['email'];
        this.LS.requestSms(otpInput).subscribe(smsData => {
          // console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr1');
        });
      });
    });
    });
      } else {
        this.mblshw = true;
        
      }
    } else {
      // console.log('eeeeeeeeellllllllssssssseeeeeeee');
      this.mblshw = true;
    }
    
   

  }

  // comapnyRout(id){
  //   this.router.navigate(['/barCodeManagement/'+id]);
  // }
}
