import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '../../../node_modules/@angular/router';
import { Title } from '../../../node_modules/@angular/platform-browser';
import { LoginService } from '../../app/_services/login.services';
@Component({
  selector: 'app-barcode-management',
  templateUrl: './barcode-management.component.html',
  styleUrls: ['./barcode-management.component.css'],
  providers: [LoginService]
})
export class BarcodeManagementComponent implements OnInit {
  title = 'Barcode Management'
  uniqueId : any = '';
  constructor(private route: ActivatedRoute, private router: Router, private titleService: Title,private LS: LoginService) { }
  companyId: any;
  companyCity:any;
   /**ngOnInit method
   * @constructor
   * the method to call on component loading
   */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(params => {
      this.companyId = params.companyId;
      this.getCompanyDetails(params)
  })
  }

  printBarcode() {
    this.router.navigate(['/printBarCodes'], { queryParams: { companyId: this.companyId } });
  }
  getCompanyDetails(x) {
    this.LS.getCompanyDetails({'uniqueId' :x.companyId}).subscribe(data=> {
      this.companyCity = data['companyCity'];

    });
  }
  /** location method to navigate to Location Barcodes page on clicking of Location Barcodes */
  loaction(){
    this.router.navigate(['/locationBarcodes'], { queryParams: { companyId: this.companyId } });
  }
  /** user method to navigate to User Barcodes page on clicking of User Barcodes */
  user(){
    this.router.navigate(['/userBarcodes'], { queryParams: { uniqueId: this.companyId } });
  }
  command(){
    this.router.navigate(['/commandBarcodes'], { queryParams: { uniqueId: this.companyId } });
  }

  add(){
    this.router.navigate(['/addProfile'], { queryParams: { companyId: this.companyId } });
  }
  /** user method to navigate toTap Track Easy Scanning page on clicking of Tap Track Easy Scanning */
  easyScanning(){
    this.router.navigate(['/tapTrackEasyScanning'], { queryParams: { companyId: this.companyId } });
  }
  /** user method to navigate toTap Track Item Inventory Report page on clicking of Tap Track Item Inventory Report */
  inventory(){
    this.router.navigate(['/itemInventor'], { queryParams: { companyId: this.companyId } });
  }

  login() {
    this.router.navigate(['/login']);
  }

  downloadMyFile(){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/DemoTaptrackVideos/');
    link.setAttribute('download', `TAPTRACKScannerScreenShots.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
}
               