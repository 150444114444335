import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../app/_services/login.services';
import { Router } from '@angular/router';
import { Title } from '../../../node_modules/@angular/platform-browser';
import * as _ from 'lodash';
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css'],
  providers: [LoginService]
})
export class ForgotComponent implements OnInit {
  title ='Tap Track :: Forgot Password'
  loginForm : any = {};
  allCompanynames: any = [];
  allCompanynames1: any = [];
  usernameshow:any = false;
  wrngEntry : any = false;
  constructor(private LS: LoginService, private router: Router, private titleService: Title) { }
 /**ngOnInit method
   * @constructor
   * the method to call on component loading
   */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.allCompanies();
  }
  /** forgotPassword Method :
   * @constructor
   * forgot password will send an email for given username and companyId
   */
  forgotPassword() {
    if(this.loginForm.username==null || this.loginForm.username == ''){
      this.usernameshow = true;
      this.wrngEntry = false;
      return;
    }
    /** forgotPassword api calling 
     * @param {Object} this.loginForm - input for forgot password
     */
    this.LS.forgotPassword(this.loginForm).subscribe(loginData => {
      this.loginForm = {};
      this.allCompanies();
      this.router.navigate(['/infoForgot'])
    },
    error =>{
      if(error.error.error.statusCode == "500"){
        this.wrngEntry = true;
        this.usernameshow = false;
      }
    }
  );
  }
  companayvalue:any;
  companyId:any;
/** allCompanies method :
 * @constructor
 * method will filter the companies except Norcross company
 */
  allCompanies() {
    var self =this
    self.companyId = self.companayvalue;
    self.LS.getCompanies().subscribe(
        data => {
        self.loginForm.companyId = data[1].companyId
        self.allCompanynames1 = data;
        _.each(self.allCompanynames1, function (datas) {
          console.log(datas)
          if (datas.companyId != 72) { /** Dont show 'Norcross' on UI - hence filtering**/
            self.allCompanynames.push(datas)
          } else {
            console.log(data)
          }
        })
      },
        error => {

      }
    )

  }
}
