import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-print-bar-codes',
  templateUrl: './help-print-bar-codes.component.html',
  styleUrls: ['./help-print-bar-codes.component.css']
})
export class HelpPrintBarCodesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
