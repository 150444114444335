import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import { LocalStorageService } from '../../../../../node_modules/angular-web-storage';
import { ItemService } from '../../../_services/item.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  providers: [ItemService]
})
export class MessagesComponent implements OnInit {
  userData: any = {};
  companyData: any = {};
  itemList: any = [];
  page_size: any;
  page: any;
  recordsCount: any;
  pagination_numbers: any = {};
  title = 'TapTrack Messages'
  constructor(private titleService: Title, private ss: supervisorService, public local: LocalStorageService) {
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
    this.userData = this.local.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    }
  }
  loginForm: any = {};
  userData1: any;
  companyId: any;
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {
      this.userData1 = JSON.parse(this.userData1);
      this.companyId = this.userData1.userDetails.companyId;
    }
    this.meassages(this.page, this.page_size);
    this.initCount();
  }
  initCount() {
    let input = {};
    input = {
      companyId: this.companyData.companyId,
      status : 1
    };
    // this.meassages(input)
    this.ss.getmessagesCount(input).subscribe(recordsCount => {
      this.recordsCount = recordsCount;
      this.pagination_numbers.totalNumber = this.recordsCount;
      this.nextPage("first");
    })
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
      if (this.pagination_numbers.toNumber == this.pagination_numbers.totalNumber)
      return
    else
    this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.meassages(this.page, this.page_size);
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.meassages(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
  }
  // messages() {
  //   let input = {};
  //   input = {
  //     'companyId': this.companyId
  //   }
  //   this.ss.getMessages(input).subscribe((userData: any) => {
  //     if (userData != null) {
  //       this.recordsCount = userData;
  //       this.pagination_numbers.totalNumber = this.recordsCount;
  //       this.nextPage("first");
  //       this.recordsCount = userData;
  //       this.recordsCount.forEach((item) => {
  //         item.isChecked = false;
  //       });
  //     }
  //   });

  // }
  
  meassages(page, page_size) {
    let input = {};
    if (page_size == 0) {
      page_size = 7
    }
    input = {
      'companyId': this.companyId,
      skip: page * page_size,
      limit: page_size
    };
    this.ss.getMessages(input).subscribe((itemList :any) => {
      
      this.itemList = itemList;
      // this.itemList.sort((a,b)=>{
      //   return new Date(b.addTime).getTime() - new Date(a.addTime).getTime();
     
      // });

    });
  }
  messagesToSend = [];
  idsOfMessagesToSend = [];
  storeMessagesToSend(isChecked, data) {
    if (isChecked === true) {
      if (this.messagesToSend.length === 0) {
        this.messagesToSend.push(data);
        this.idsOfMessagesToSend.push(data.messageId);
      } else {
        if (this.idsOfMessagesToSend.indexOf(data.messageId) === -1) {
          this.messagesToSend.push(data);
          this.idsOfMessagesToSend.push(data.messageId);
        }
      }
    } else if (isChecked === false) {
      this.messagesToSend.splice(this.idsOfMessagesToSend.indexOf(data.messageId), 1);
      this.idsOfMessagesToSend.splice(this.idsOfMessagesToSend.indexOf(data.messageId), 1);
    }
  }
  sendStoredMessages() {
    if (this.messagesToSend.length > 0) {
      var messagesToSendFinal = JSON.parse(JSON.stringify(this.messagesToSend));
      messagesToSendFinal.forEach((item) => {
        if (item.status === 0) {
          item.status = 1;
        } else if (item.status === 1) {
          item.status = 0;
        }
      });
      this.ss.editMessages(messagesToSendFinal).subscribe((userData: any) => {
        this.messagesToSend = [];
        this.idsOfMessagesToSend = [];
        this.meassages(this.page, this.page_size);
      });
    }
  }
}
