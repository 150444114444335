import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../_services/profile.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';
import { Title } from '../../../../../node_modules/@angular/platform-browser';
import { LoginService } from '../../../_services/login.services';
LoginService
@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.css'],
  providers: [ProfileService, LoginService]
})
export class AddProfileComponent implements OnInit {
  title = 'Taptrack User Registration'
  userData: any = {};
  companyData: any = {};
  getUserData: any = [];
  type: any = {};
  companyName: any = '';
  getStateData: any = [];
  profileData: any = [];
  successfulSave: boolean = false;
  successfulSave1: boolean = true;
  usernameshw: any = false;
  locationFlagshw: any = false;
  passwordshw: any = false;
  confirmPasswordshw: any = false;
  userFirstNameshw: any = false;
  userLastNameshw: any = false;
  UserStreet1shw: any = false;
  userCityshw: any = false;
  userZipshw: any = false;
  userMobileshw: any = false;
  landLineshw: any = false;
  emailshw: any = false;
  stateIdshw: any = false;
  mailWrng: any = false;
  userTypeIdshw: any = false;
  usernameErr: boolean = false;
  mobileErr : boolean = false;
  profileErr: boolean = false;
  mblFlag : boolean = false;
  lanFlag : boolean = false;
  afzal:any;
  constructor(private PS: ProfileService,private LS:LoginService, private titleService: Title, private localStorage: LocalStorageService, private router: Router) {

    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData['userDetails']['companyIdRelations'];
      this.companyName = this.companyData.companyName;
      this.type['companyName'] = this.companyName;
      this.type['uniqueId'] = this.companyData.uniqueId;
      this.type['emailFlag'] = 'emailFlag';
      this.afzal = "emailFlag";
      // this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
      // this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
    }
    else {
      this.router.navigate(['/login']);
    }
  }
  defaultFlag:any;
  emailvalidation = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.getUserypes();
    this.getStates();
    // console.log('emailFlagemailFlag ', this.type);
    // this.type['emailFlag'] = "emailFlag";
    // this.defaultFlag = "emailFlag";
  }
  getUserypes() {
    this.PS.getUserType().subscribe(getUserData => {
      this.getUserData = getUserData;
    });
  }
  passwrdmatch: any = false;
  getStates() {
    this.PS.getState().subscribe(getStateData => {
      this.getStateData = getStateData;
    });
  }
  cancelProfile() {
    this.router.navigate(['/home']);
  }
  errorFormFieldsArr = [];
  userProfileFormFields = [
    "username", "locationFlag", "password", "confirmPassword", "userFirstName", "userLastName",
    "UserStreet1", "userCity", "userZip", "userMobile", "email", "stateId", "userTypeId",
  ]
  createProfile() {
    this.errorFormFieldsArr = [];
    this.userProfileFormFields.forEach((item) => {
      if (item == 'locationFlag') {
        if (this.type['locationFlag'] == undefined || this.type['locationFlag'] == '' || this.type['locationFlag'] == '') {
          this.locationFlagshw = false;
        }
        if (this.type['locationFlag']) {
          if (this.type['location']) {
            this.locationFlagshw = false;
          } else {
            this.errorFormFieldsArr.push(item);
            this.locationFlagshw = true;
          }

        }
      } else if(item == 'userMobile') {
         if(this.type['mobileFlag'] == "mobileFlag") {
          if(this.type[item] == null || this.type[item] == '' || this.type[item] == undefined) {
            this.userMobileshw = true;
            this.errorFormFieldsArr.push(item);
          } else {
            this.userMobileshw = false;
          }
         
        } else {
          this.userMobileshw = false;
        }

      } else {
        if (this.type[item] == null || this.type[item] == '') {
          this.errorFormFieldsArr.push(item);
          this[item + "shw"] = true;
        } else {
          this[item + "shw"] = false;
        }
      }
      

    });
    // if(this.type.password != this.type.confirmPassword){
    //   this.passwrdmatch = true;
    //   return;

    // }else{
    //   this.passwrdmatch = false;
    // }
    if (this.type.password != this.type.confirmPassword) {
      this.passwrdmatch = true;
      return;

    } else {
      this.passwrdmatch = false;
    }


    // if (this.type['locationFlag'] == undefined || this.type['locationFlag'] == '' || this.type['locationFlag'] == '') {
    //   this.locationFlagshw = false;
    // }
    // if (this.type['locationFlag']) {
    //   if (this.type['location']) {
    //     this.locationFlagshw = false;
    //   } else {
    //     this.locationFlagshw = true;
    //   }

    // }
    if (!this.emailvalidation.test(this.type.email)) {
      this.mailWrng = true;
      return;
    } else {
      this.mailWrng = false;
    }
    if (this.errorFormFieldsArr.length > 0) {
      return;
    };
    let profileInput = {};
    profileInput = this.type;
    profileInput['companyId'] = this.companyData.companyId;
    profileInput['toolTipFlag'] = 0;
    profileInput['userApproved'] = 0;
    profileInput['userMI'] = '';
    profileInput['userPrefix'] = '';
    profileInput['userStatus'] = 0;
   
    // profileInput['userStatus'] = 1;
    profileInput['locationFlag'] = (this.type.locationFlag) ? 1 : 0;
    let idd = this.type['userTypeId']
    let usrtype = {};
    usrtype = this.getUserData.find(getID);
    profileInput['userType'] = usrtype['userType'];
    function getID(obj) {
      if (obj.userTypeId == idd) {
        return obj.userType
      }

    }
    
    if (profileInput['password'] == profileInput['confirmPassword']) {
      let regInput = {};
      var series = 770;
      profileInput['emailFlag'] = (profileInput['emailFlag'] == 'emailFlag') ? 1 : 0;
      profileInput['mobileFlag'] = (profileInput['mobileFlag'] == 'mobileFlag') ? 1 : 0;
      // console.log('pppppppppppppppppppppppp ', this.type['emailFlag']);
      regInput = {
        email : profileInput['email'],
        phone : (profileInput['mobileFlag'] == 1) ?  profileInput['userMobile'] : (series.toString()) + Math.floor(1000000 + Math.random() * 9000000).toString(),  //Math.floor(Math.random() * 10000000).toString(), // profileInput['landLine'],
        // countryCode : profileInput['countryCode']
        countryCode : 1
      }
      profileInput['countryCode'] = 1;
      
      // if(profileInput['landLine'].toString().length == 10) {
        this.mblFlag = false;
        this.lanFlag = false;
        if(profileInput['mobileFlag'] == 1) {
          if(profileInput['userMobile'].toString().length == 10) {
            this.LS.register(regInput).subscribe(regResp => {
              profileInput['authId'] = regResp['user']['id'];
              profileInput['authFlag'] = 1;
            this.PS.createProfile(profileInput).subscribe(profileData => {
              this.profileData = profileData;
              this.type = {};
              this.successfulSave = true;
              this.successfulSave1 = false;
            }, error => {
              // console.log('eeeeeeeeeeeeeeeeeeeeeee ', error.error.error);
              this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
            this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
                if (error.error.error.statusCode == "422") {
                  // console.log('eeeeeeeeeeeeeeeeeeeeeee ', error.error.error);
                  this.usernameErr = true;
                }
                if (error.error.error.statusCode == "500") {
                  this.profileErr = true;
                }
                if (error.error.error.statusCode == "421") {
                  this.mobileErr = true;
                }
              }
      
            );
            });
          } else {
            this.mblFlag = true;
          }
        } else if(profileInput['emailFlag'] == 1) {
          this.LS.register(regInput).subscribe(regResp => {
            profileInput['authId'] = regResp['user']['id'];
            profileInput['authFlag'] = 1;
          this.PS.createProfile(profileInput).subscribe(profileData => {
            this.profileData = profileData;
            this.type = {};
            this.successfulSave = true;
            this.successfulSave1 = false;
          }, error => {
            this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
            this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
            console.log('ppppppppppppppppppppppppppppppp', this.type, profileInput);
              if (error.error.error.statusCode == "422") {
                this.usernameErr = true;
              }
              if (error.error.error.statusCode == "500") {
                this.profileErr = true;
              }
              if (error.error.error.statusCode == "421") {
                this.mobileErr = true;
              }
            }
    
          );
          });
        }
      
      // } else {
      //   // console.log('llllllaaaaaaaaaaaaaaaa');
      //   this.mblFlag = false;
      //   this.lanFlag = true;
      // }
    
    }
  }
  isNumberKey(evt) {

    if (isNaN(evt)) {

      this.type.userZip = ''

    }

  }

  isDisabled = true;
  triggerSomeEvent() {
    this.isDisabled = !this.isDisabled;
    return;
  }
  emailF: boolean;
  mobileF:boolean = true;
  triggerEvent1() {
    if(this.type['mobileFlag']=='mobileFlag') {
      this.type['emailFlag'] = ""
      this.emailF = true;
    } else {
      this.type['emailFlag'] = "emailFlag"
      this.emailF = false;
    }
  }
 
  triggerEvent() {
    if(this.type['emailFlag']=='emailFlag') {
      this.type['mobileFlag'] = "";
    } else {
      this.type['mobileFlag'] = "mobileFlag";
    }
    
  }
}
