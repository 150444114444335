import { Component, OnInit } from '@angular/core';
import { UserLocationService } from '../../app/_services/user.location.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Title } from '../../../node_modules/@angular/platform-browser';
import * as _ from 'lodash';
import { LoginService } from '../../app/_services/login.services';
@Component({
  selector: 'app-command-barcodes',
  templateUrl: './command-barcodes.component.html',
  styleUrls: ['./command-barcodes.component.css'],
  providers: [UserLocationService, LoginService]
})
export class CommandBarcodesComponent implements OnInit {
  commandbar: any;
  title = 'Print All Barcodes';
  uniqueId: any = 'Print All Barcodes';
  locations: any = [];
  companyCity:any;
  constructor(private titleService: Title, private BS: UserLocationService, private route: ActivatedRoute,private router: Router,private LS: LoginService) { }
  /**ngOnInit method
      * @constructor
      * the method to call on component loading
      */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    // this.getcommandBarCode();
    this.route.queryParams.subscribe(paramss => {
      this.uniqueId = paramss.uniqueId;
      this.getCompanyDetails(paramss)
    });
  }
  getCompanyDetails(x) {
    this.LS.getCompanyDetails({'uniqueId' :x.uniqueId}).subscribe(data=> {
      this.companyCity = data['companyCity'];
// console.log('==================================== ', data, x);
    });
  }
  // getcommandBarCode() {    
  //   this.BS.getcommandBarCodes().subscribe(data => {        
  //       this.commandbar = data;
  //   });
  // }

  back() {
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: this.uniqueId } });
  }

  printTable(id) {
    var query = {
      itemUniqueId: this.uniqueId,
      // companyId : this.companyId
    }
    window.open(
      `#/commandBarcodePrint?itemUniqueId=${JSON.stringify(query)}`,
      "name",
      "width=1000,height=600"
    );
  }

}
