import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../../node_modules/angular-web-storage';
import { ItemService } from '../../../_services/item.service';

@Component({
  selector: 'app-item-close',
  templateUrl: './item-close.component.html',
  styleUrls: ['./item-close.component.css'],
  providers: [ItemService]
})
export class ItemCloseComponent implements OnInit {
  title = 'TapTrack Item Close';
  userData1: any;
  companyId: any;
  userId: any;
  selectedFilterValue = 7
  pageSkip = 0
  pageLimit = 7
  itemcloselisterror: any;
  searchQuery = {
  }
  datePicker: any = {}
  totalItemsCount: any = 0

  totalItemsCount1: any = 0
  page: any;
  page_size: any;
  pagination_numbers: any = {};

  constructor(private titleService: Title, public local: LocalStorageService, private IS: ItemService) {
    this.page = 0;
    this.page_size = 7;
    this.totalItemsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {
      this.userData1 = JSON.parse(this.userData1);
      this.userId = this.userData1.userId;
      this.companyId = this.userData1.userDetails.companyId;
    }
    this.itemClose(this.pageSkip, this.pageLimit);
    this.getItemsCount()
  }


  itemcloselist: any = [];
  itemClose(skip, limit) {
    console.log(skip)
    if (skip < 0) {
      this.pageSkip = this.pageSkip
      this.pageLimit = this.pageLimit
      return
    } else {
      console.log(limit)

      var input = {
        'userId': this.userId,
        'companyId': this.companyId,
        'limit': limit ? limit : 7,
        'skip': skip ? skip : 0,
        'searchQuery': this.searchQuery
      }
      this.IS.itemClose(input).subscribe((userData: any) => {
        this.itemcloselist = userData;
        if (this.itemcloselist.length < 6) {
          this.pagination_numbers.toNumber = this.itemcloselist.length
          this.pagination_numbers.totalNumber = this.itemcloselist.length;
          this.pagination_numbers.fromNumber = 0
        }
      },
        error => {
          this.itemcloselisterror = error.error.error.message
          console.log(this.itemcloselisterror)
        });
    }
  }

  itemcloseToSend = [];
  idsOfItemsToSend = [];
  itemCloseSend(isChecked, data) {
    if (isChecked === true) {
      if (this.itemcloseToSend.length === 0) {
        this.itemcloseToSend.push(data);
        this.idsOfItemsToSend.push(data.itemId);
      } else {
        if (this.idsOfItemsToSend.indexOf(data.itemId) === -1) {
          this.itemcloseToSend.push(data);
          this.idsOfItemsToSend.push(data.itemId);
        }
      }
    } else if (isChecked === false) {
      this.itemcloseToSend.splice(this.idsOfItemsToSend.indexOf(data.itemId), 1);
      this.idsOfItemsToSend.splice(this.idsOfItemsToSend.indexOf(data.itemId), 1);
    }
  }


  scancloseSubmit() {
    let itemIds = [];
    this.itemcloseToSend.map((item) => {
      itemIds.push(item.itemUniqueId);
    });
    let input = {};
    input = {
      "reciever": this.userId,
      "sender": this.userId,
      "nextTransactionId": "",
      "transStatusId": 1,
      "itemIds": [...itemIds],
      "transMailFlag": 0,
      "deletedFlag": 0,
      "addedByAdminFlag": 0,
      "transComment": "string",
      "companyId": this.companyId

    }
    if (this.itemcloseToSend.length > 0) {
      this.IS.scanitemclose(input).subscribe((userData: any) => {
        if (userData) {
          this.searchQuery = {}

          alert('Selected Items are closed')
          this.page = 0;
          this.page_size = 7;
          this.totalItemsCount = 0;
          this.pagination_numbers.fromNumber = 0;
          this.pagination_numbers.toNumber = this.page_size;
          this.itemcloseToSend = [];

          this.idsOfItemsToSend = [];
          this.initconut()
          // this.itemClose(0, this.selectedFilterValue);

        }
      });
    }
  }
  initconut() {
    this.itemClose(0, this.selectedFilterValue);
    this.getItemsCount()

  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.itemClose(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.totalItemsCount) {
      if (
        this.pagination_numbers.toNumber ==
        this.pagination_numbers.totalNumber
      )
        return;
      else this.page = this.page + 1;
      // this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.totalItemsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.totalItemsCount) {
      this.page = Math.floor(this.totalItemsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.itemClose(this.page, this.page_size);
  }
  getItemsCount() {
    var query = {
      'companyId': this.companyId,
      'itemStatusId': 2,
      'userId': this.userId
    }
    this.IS.getItemListCount(query).subscribe((count) => {
      this.totalItemsCount = count
      this.pagination_numbers.totalNumber = this.totalItemsCount;

      // this.totalItemsCount1 = this.totalItemsCount
    })
  }
  // End ItemCount

  clearSearch() {
    this.searchQuery = {}
    this.datePicker = {
      fromDate: '',
      toDate: ''
    }
    this.pageSkip = 0
    this.pageLimit = this.selectedFilterValue
    this.itemClose(this.pageSkip, this.selectedFilterValue)
  }
  searchItems(datePicker) {
    if (datePicker.fromDate && datePicker.fromDate.jsdate)
      this.searchQuery['fromDate'] = datePicker.fromDate.jsdate
    if (datePicker.toDate && datePicker.toDate.jsdate)
      this.searchQuery['toDate'] = datePicker.toDate.jsdate
    this.pageSkip = 0
    this.pageLimit = this.selectedFilterValue
    this.itemClose(this.pageSkip, this.selectedFilterValue)
  }
  onDateChanged(fromDate, toDate) {
    if (new Date(fromDate.jsdate) < new Date(toDate.jsdate)) {
      alert('todate should be greater than From date')
      this.datePicker = {
        fromDate: '',
        toDate: ''
      }
    }

  }
}
