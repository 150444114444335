import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import { LocalStorageService } from '../../../../../node_modules/angular-web-storage';
import * as _ from 'lodash';
import * as async from 'async';
import {
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import { Title } from '../../../../../node_modules/@angular/platform-browser';
@Component({
  selector: 'app-pending-users',
  templateUrl: './pending-users.component.html',
  styleUrls: ['./pending-users.component.css'],
  providers: [LoginService]
})
export class PendingUsersComponent implements OnInit {
  title = 'TapTrack Pending Users ';
  loginForm: any = {};
  Suprevisors: any;
  userData: any = {};
  companyData: any = {};
  itemList: any = [];
  page_size: any;
  page: any;
  Sarchfileds: any = {};
  editField: any;
  editMode: any = false
  updatedData: any = []
  Suprevisorsre: any = {}
  recordsCount: any;
  pagination_numbers: any = {};
  searchQuery: any = {
    username: { type: 'startsWith', field: 'username' },
    userFirstName: { type: 'startsWith', field: 'userFirstName' },
    userLastName: { type: 'startsWith', field: 'userLastName' }
  }
  constructor(private titleService: Title, private ss: supervisorService, private LS: LoginService, private localStorage: LocalStorageService, private router: Router) {
    this.userData = this.localStorage.get("loginInfo");
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    }
  }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.initCount();

  }
  initCount() {
    let input = {};
    input = {
      companyId: this.companyData.companyId
    };
    this.ss.getusersCount(input).subscribe(recordsCount => {
      this.recordsCount = recordsCount;
      this.pagination_numbers.totalNumber = this.recordsCount;
      this.nextPage("first");
      // console.log('rrrrrrrrrrrrrrrrr ', this.recordsCount);
    })
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
      if (this.pagination_numbers.toNumber == this.pagination_numbers.totalNumber)
        return
      else
        this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "first" && this.recordsCount == 0) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.getItemList(this.page, this.page_size);
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.getItemList(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
  }
  getItemList(page, page_size) {
    let input = {};
    if (page_size == 0) {
      page_size = 7
    }
    input = {
      companyId: this.companyData.companyId,
      skip: page * page_size,
      limit: page_size
    };
    this.ss.getUsersList(input).subscribe(Suprevisors => {
      this.Suprevisors = Suprevisors;
      _.each(this.Suprevisors, function (data) {
        if (data.userApproved == 0) {
          data['status'] = 'InActive'
        } else {
          data['status'] = 'Active'
        }
      })

    });
  }
  newData(data) {
    var self = this
    return new Promise(async function (reslove, reject) {
      self.ss.updateFields(data).subscribe(res => {
        if (res) {
          reslove(res)
        } else {
          reject('error')
        }
      })
    })
  }
  MaketableEdit(m) {
    let index = _.findIndex(this.Suprevisors, { id: m.id })
    this.Suprevisors[index]['editMode'] = true
  }
  update(data) {
    // if (data.userApproved == 1) {
    //   data.userApproved = 0;
    //   data.userStatus = 0;
    //   this.updatedData.push(data)
    // } else {
      data.userApproved = 1;
      data.userStatus = 1;
      this.updatedData.push(data)
    // }
  }
  Maketablesave() {
    var self = this
    async.eachSeries(this.Suprevisors, async function (data, cb) {
      var res = await self.newData(data)
      if (res) {
        cb()
      }
    }, function (err, res) {
      if (err) {
      } else {
        self.initCount();
        alert('Selected Items Updated')
        self.editMode = false
      }
    })
  }
  ClearFields(value) {
    if (value == 'username') {
      this.Sarchfileds.userFirstName = ''
    }
  }
  ClsSarchfileds() {
    this.searchQuery = {
      username: { type: 'startsWith', field: 'username' },
      userFirstName: { type: 'startsWith', field: 'userFirstName' },
      userLastName: { type: 'startsWith', field: 'userLastName' }
    }
    this.initCount();
  }
  Scearchdata() {
    let input = {}
    let text
    if(this.searchQuery.username.value || this.searchQuery.userFirstName.value || this.searchQuery.userLastName.value){
      input = {
        companyId: this.companyData.companyId,
        itemStatusId: 1,
        skip: 0,
        limit: 7,
        searchQuery: this.searchQuery,
      };
      this.ss.getscarchData(input).subscribe(Suprevisors => {
        this.Suprevisors = Suprevisors;
        this.recordsCount = this.Suprevisors.length;
        this.pagination_numbers.totalNumber = this.recordsCount;
        if(7>this.pagination_numbers.totalNumber){
          this.pagination_numbers.toNumber = this.recordsCount
  
        }else{
          this.pagination_numbers.toNumber = 7
        }
        _.each(this.Suprevisors, function (data) {
          if (data.userApproved == 0) {
            data['status'] = 'InActive'
          } else {
            data['status'] = 'Active'
          }
        })
      });
    }else{
      this.initCount();
    }
  
  }
  //   updateList(id: number, property: string, event: any) {
  //     const editField = event.target.textContent;
  //     this.Suprevisors[id][property] = editField;
  //   }
  changeValue(id: number, property: string, event: any) {
    var self = this
    this.editField = event.target.textContent;
    _.each(this.Suprevisors, function (data) {
      if (data.id == id) {
        Object.keys(data).forEach((key, index) => {
          if (key == property) {
            data[key] = self.editField
          }
        });
      }

    })
  }
}
