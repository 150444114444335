import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import * as _ from 'lodash';
import * as async from 'async';
import * as await from 'async';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
@Component({
  selector: 'app-item-auditreport',
  templateUrl: './item-auditreport.component.html',
  styleUrls: ['./item-auditreport.component.css'],
  providers: [LoginService]
})
export class ItemAuditreportComponent implements OnInit {
  userData: any;
  companyData: any;
  reciever:any;
  auditreport:any;
  page_size: any;
  page: any;
  pagination_numbers: any = {};
  recordsCount:any;
  constructor(private titleService: Title, private ss: supervisorService, private LS: LoginService, private routes: ActivatedRoute, private localStorage: LocalStorageService, private route: Router) {
    this.userData = this.localStorage.get("loginInfo");
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
    }
   }

  ngOnInit() {
    const routeParams = this.routes.snapshot.params;
    this.reciever = routeParams.id;
    let input ={
      reciever:this.reciever,skip: 0,
      limit: 7
    }
    if(this.reciever){
      let input = {
        "reciever": this.reciever
      }
      this.LS.getCountitemsauditsreport(input).subscribe(listcount =>{
        this.recordsCount = listcount
        this.pagination_numbers.totalNumber = this.recordsCount;
      })
    }
    this.LS.getauditreports(input).subscribe(usrData => {
      this.auditreport =usrData
      _.each(this.auditreport,function (data) {
        // (1000 * 60 * 60 * 24)
     if(data.transStatusId == 1){
       data['status'] = 'Successe'
     }else{
      data['status'] = 'Failed'

     }
      })
    },
   
  );
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
    this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.getItemList(this.page, this.page_size);
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    // this.getItemList(this.page, this.page_size);
    this.page = 0;
    this.nextPage("first");
  }
  getItemList(page,page_size){
    let input ={
      reciever:this.reciever,skip: page * page_size,
      limit: page_size
    }
    this.LS.getauditreports(input).subscribe(usrData => {
      this.auditreport =usrData
      _.each(this.auditreport,function (data) {
     if(data.transStatusId == 1){
       data['status'] = 'Successe'
     }else{
      data['status'] = 'Failed'

     }
      })
    },
   
  );
  }
}
