import { Injectable } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import * as _ from 'lodash';
import * as async from 'async';
import * as await from 'async';


import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import { promise } from 'protractor';
// import { async } from '@angular/core/testing';
@Component({
  selector: 'app-supervisors-grid',
  templateUrl: './supervisors-grid.component.html',
  styleUrls: ['./supervisors-grid.component.css'],
  providers: [LoginService]
})
@Injectable()

export class SupervisorsGridComponent implements OnInit {
  loginForm: any = {};
  Sarchfileds: any = {};

  Suprevisors: any;
  userData: any = {};
  companyData: any = {};
  itemList: any = [];
  page_size: any;
  page: any;
  // this:any;
  res: any;
  editField: any;
  username: any
  usertypes:any=[]
  // editMode: any = false
  updatedData: any = []
  Suprevisorsre: any = {}
  // userData : any = {}
  recordsCount: any;
  pagination_numbers: any = {};
  title = 'TapTrack Supervisor Grid ';
  searchQuery: any = {
    username: { type: 'startsWith', field: 'username' },
    userFirstName: { type: 'startsWith', field: 'userFirstName' },
    userLastName: { type: 'startsWith', field: 'userLastName' }
  }
  constructor(private titleService: Title, private ss: supervisorService, private LS: LoginService, private localStorage: LocalStorageService, private router: Router) {

    this.userData = this.localStorage.get("loginInfo");
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    }
  }


  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.initCount();
    this.LS.getCompanies().subscribe(
      data => {
        this.loginForm.companyId = data[0].companyId
      },
      error => {

      }
    )
    this.LS.getuserTypes().subscribe(
      data => {
        this.usertypes = data
        console.log(this.usertypes)
      },
      error => {

      }
    )
  }
  initCount() {
    let input = {};
    input = {
      companyId: this.companyData.companyId
    };
    this.LS.getusersCount(input).subscribe(recordsCount => {
      this.recordsCount = recordsCount;
      this.pagination_numbers.totalNumber = this.recordsCount;
      this.nextPage("first");
    })
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
      if (this.pagination_numbers.toNumber == this.pagination_numbers.totalNumber)
        return
      else
        this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.getItemList(this.page, this.page_size);
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.getItemList(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
  }
  getItemList(page, page_size) {
    let input = {};
    if (page_size == 0) {
      page_size = 7
    }
    input = {
      companyId: this.companyData.companyId,
      skip: page * page_size,
      limit: page_size
    };
    this.LS.getUsersList(input).subscribe(Suprevisors => {
      this.Suprevisors = Suprevisors;
      // console.log('gggggggggggggggggggggggg ',  JSON.stringify(this.Suprevisors));
      
      _.each(this.Suprevisors, function (data) {
        if (data.userStatus == 0) {
          data['status'] = 'InActive'
        } else {
          data['status'] = 'Active'
        }
        if(data.UserType){
          data['userType'] =data.UserType.userType
        }
      })
    });
  }
  newData(data) {
    if(data.userType) {
      data.userType = data.userType.replace(/\s/g,'')
      _.each(this.usertypes,function(user){
        user.userType = user.userType.replace(/\s/g,'')
        if(user.userType.toLowerCase() == data.userType.toLowerCase()){
          data.userTypeId = user.userTypeId
        }
      })
    }
    if(data.userTypeId) {
      data.userTypeId = data.userTypeId
    }
   
    var self = this
    return new Promise(async function (reslove, reject) {
      self.LS.updateFields(data).subscribe(res => {
        if (res) {
          reslove(res)
        } else {
          reject('error')
        }
      })
    })
  }

  // MaketableEdit(m) {
  //   let index = _.findIndex(this.Suprevisors, { id: m.id })
  //   this.Suprevisors[index]['editMode'] = true
  // }
  update(data) {
    if (data.userStatus == 1) {
      // data.userApproved = 0
      data.userStatus = 0;
      this.updatedData.push(data)
    } else {
      // data.userApproved = 1;
      data.userStatus = 1;
      this.updatedData.push(data)
    }
  }
  Maketablesave() {
    var self = this
    async.eachSeries(this.Suprevisors, async function (data, cb) {
      var res = await self.newData(data)
      if (res) {
        cb()
      }
    }, function (err, res) {
      if (err) {
      } else {
        self.initCount();
        alert('Selected Items Updated')
        // self.editMode = false
      }
    })

    // })

  }

  // newData (data){
  //   return new Promise(async function (reslove, reject) {
  // return this.http.patch(this.serverUrl+ 'Users/' + data.id, data).pipe(map((res: Response) => reslove(res)));

  //   })
  // }

  ClsSarchfileds() {
    this.searchQuery = {
      username: { type: 'startsWith', field: 'username' },
      userFirstName: { type: 'startsWith', field: 'userFirstName' },
      userLastName: { type: 'startsWith', field: 'userLastName' }
    }
    this.initCount();
  }
  Scearchdata() {
    let input = {}
    if(this.searchQuery.username.value || this.searchQuery.userFirstName.value || this.searchQuery.userLastName.value){
      input = {
        companyId: this.companyData.companyId,
        skip: 0,
        limit: 7,
        searchQuery: this.searchQuery,
      };
  
      this.LS.getscarchData(input).subscribe(Suprevisors => {
        this.Suprevisors = Suprevisors;
        this.recordsCount = this.Suprevisors.length;
        this.pagination_numbers.totalNumber = this.recordsCount;
        if(7>this.pagination_numbers.totalNumber){
          this.pagination_numbers.toNumber = this.recordsCount
  
        }else{
          this.pagination_numbers.toNumber = 7
        }
        _.each(this.Suprevisors, function (data) {
          if (data.userStatus == 0) {
            data['status'] = 'InActive'
          } else {
            data['status'] = 'Active'
          }
        })
      });
    }else{
      this.initCount();
    }
  
  }
  changeValue(id: number, property: string, event: any) {
    var self = this
    this.editField = event.target.textContent;
    _.each(this.Suprevisors, function (data) {
      if (data.id == id) {
        Object.keys(data).forEach((key, index) => {
          if (key == property) {
            data[key] = self.editField
          }
        });
      }

    })
  }
}
