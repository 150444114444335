import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../_services/login.services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { IMyDpOptions } from 'mydatepicker';
import { ExcelService } from '../../../_services/excel.service';
declare var $;
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import * as _ from 'lodash';
@Component({
  selector: 'app-binders-processed-report',
  templateUrl: './binders-processed-report.component.html',
  styleUrls: ['./binders-processed-report.component.css'],
  providers: [LoginService]

})
export class BindersProcessedReportComponent implements OnInit {
  title = 'TapTrack Binders Processed Report'
  userData: any = {};
  usersData: any = {};
  companyData: any = {};
  Query: any = {};
  error: any = false;
  error1: any = false;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm/dd/yyyy',
  };
  constructor(private titleService: Title, public local: LocalStorageService, private Login: LoginService,private excelService: ExcelService) {
    this.userData = this.local.get("loginInfo");
    this.userData = JSON.parse(this.userData);
    this.usersData = this.userData['userDetails'];
    this.companyData = this.userData['userDetails']['companyIdRelations'];
    this.Query['UserId'] = this.usersData.id
    this.Query['companyId '] = this.companyData.companyId


  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  onDateChanged(fromDate, toDate) {
    if (fromDate && toDate){
      if (new Date(fromDate.jsdate) < new Date(toDate.jsdate) || new Date(toDate.jsdate) >new Date(fromDate.jsdate)) {
        alert('todate should be greater than From date')
        this.Query.fromDate = ''
        this.Query.toDate = ''
      }
    }
  }

  clearfilds(fromDate, toDate) {
    this.Query = {}
    // this.Query.fromDate = {
    //   jsdate: {

    //   }
    // }
    this.Query.fromDate=''
    this.Query.toDate = ''
    this.error = false;
    this.error1 = false;
  }

  getItemList() {
    if (this.Query.fromDate && this.Query.toDate) {
      this.error = false;
      let year = this.Query.fromDate.jsdate.getFullYear()
      let month = this.Query.fromDate.jsdate.getMonth() + 1
      let date = this.Query.fromDate.jsdate.getDate()
      let year1 = this.Query.toDate.jsdate.getFullYear()
      let month1 = this.Query.toDate.jsdate.getMonth() + 1
      let date1 = this.Query.toDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      let fromDate = year + '-' + month + '-' + date
      let toDate = year1 + '-' + month1 + '-' + date1
      let input = {};
      input = {
        'fromDate': fromDate,
        'toDate': toDate,
        'companyId': this.companyData.companyId
      }

      window.open(`#/htmlBindersProcessedReport?Query=${JSON.stringify(input)}`, 'name', 'width=850,height=600,scrollbars=1');
    } else {
      this.error = true;
    }
  }

  itmList: any = []
  itmList1: any = []
  getPdf() {

    if (this.Query.fromDate && this.Query.toDate) {

      this.error = false;
      let year = this.Query.fromDate.jsdate.getFullYear()
      let month = this.Query.fromDate.jsdate.getMonth() + 1
      let date = this.Query.fromDate.jsdate.getDate()
      let year1 = this.Query.toDate.jsdate.getFullYear()
      let month1 = this.Query.toDate.jsdate.getMonth() + 1
      let date1 = this.Query.toDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      let fromDate = year + '-' + month + '-' + date
      let toDate = year1 + '-' + month1 + '-' + date1
      // this.Query.fromDate = fromDate
      // this.Query.toDate = toDate
      let input = {};
      input = {
        'fromDate': fromDate,
        'toDate': toDate,
        'companyId': this.companyData.companyId
      }
      this.Login.getBinderProcessed(input).subscribe(reportData => {
        if (reportData != null && reportData != undefined) {
          this.error = false;
          this.error1 = false;
          this.itmList = reportData;
          const doc = new jsPDF();
          this.itmList1 = []
          for(var i=0;i<this.itmList.length;i++) {
            let obj = {};
            if(i==0) {
              obj = this.itmList[i];
              this.itmList1.push(obj)
            } else if (i>0 && this.itmList[i-1].itemUniqueId==this.itmList[i].itemUniqueId) {
              obj  = this.itmList[i];
              obj['itemUniqueId'] = ''
              this.itmList1.push(obj)
            } else {
              obj = this.itmList[i];
              this.itmList1.push(obj)
            }
          }
          // var self = this
          // _.each(this.itmList, function (data) {

          //   if (data['i'] > 0) {
          //     data['itemUniqueId'] = ''
          //   }
          //   // console.log('datadatadatadata', data);
          // });
          doc.setFontSize(10);
          // doc.setFont("sans-serif");
          // doc.text(20, 30, "Item Inventory" );
          doc.text(20, 25, "Item Count =       " + this.itmList1[0].bindersLength);
          doc.text(20, 35, "Date Range :       " + input['fromDate']+"       " + "-       "+input['toDate']);

          doc.autoTable({
            styles: { width: 10, border: 2, halign: "center" },
            columnStyles: { text: { cellWidth: "auto", border: 2 } }, // European countries centered
            body: this.itmList1,
            columns: [{ header: 'Item', dataKey: 'itemUniqueId' }, { header: 'Sender', dataKey: 'senderName' }, { header: 'Sender To Date', dataKey: 'transactionSuccessTime' }, { header: 'Holder', dataKey: 'recieverName' }],
            margin: { top: 40, bottom: 20, width: 300 },
            tableLineWidth: 0.5,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              fontSize: 10,
              textColor: [25, 25, 25],
              theme: "plane",
              marginBottom: 20,
              lineWidth: 0.5,
              marginTop: 20,
              border: 4,
              // rowHeight: 6,
              lineColor: 200
            },
            beforePageContent: function (data) {
              doc.setFontSize(20), doc.setFontStyle('bold'), doc.text("Binders Processed Report", 75, 20);
            }
          });
          doc.save('BindersProcessedReport.pdf');
          // let dataSrc = doc.output("datauristring");
          // let win = window.open("", "myWindow");
          // win.document.write("<html><head><title>jsPDF</title></head><body style=width='100%' height='100%'><embed src=" +
          //   dataSrc + "></embed></body></html>");

        } else {
          this.error1 = true;
        }
      });
    } else {
      this.error = true;
    }

  }
  generateExcel() {
    if (this.Query.fromDate && this.Query.toDate) {

      this.error = false;
      let year = this.Query.fromDate.jsdate.getFullYear()
      let month = this.Query.fromDate.jsdate.getMonth() + 1
      let date = this.Query.fromDate.jsdate.getDate()
      let year1 = this.Query.toDate.jsdate.getFullYear()
      let month1 = this.Query.toDate.jsdate.getMonth() + 1
      let date1 = this.Query.toDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      let fromDate = year + '-' + month + '-' + date
      let toDate = year1 + '-' + month1 + '-' + date1
      // this.Query.fromDate = fromDate
      // this.Query.toDate = toDate
      let input = {};
      input = {
        'fromDate': fromDate,
        'toDate': toDate,
        'companyId': this.companyData.companyId
      }
      this.Login.getBinderProcessed(input).subscribe(reportData => {
        if (reportData != null && reportData != undefined) {
          this.error = false;
          this.error1 = false;
          this.itmList = reportData;
          var title = 'Binders Processed Report';
          var header = ['Item', 'Sender', 'Sender To Date', 'Holder'];
          var subTitleRow = 'Item Count =    '+this.itmList[0].bindersLength;
          var Dat = 'Date Range:     '+fromDate+'   -   '+toDate;
          var fileName = 'BindersProcessedReport';
          var reportName = 'Binders Processed Report';
          const data1 = [] = this.itmList;
          const data = [];
          let data2 = [];
          var self = this
          _.each(data1, function (obj) {
            data2=[];
            data2.push(obj.itemUniqueId)
            data2.push(obj.senderName)
            data2.push(obj.transactionSuccessTime)
            data2.push(obj.recieverName)
            data.push(data2)
          })
          let excelObj = {};
          excelObj = {
            'response' : data,
            'reportName' : reportName,
            'title' : title,
            'header' : header,
            'subTitleRow' : subTitleRow,
            'fileName' : fileName,
            'dateRangeSubTitle' : Dat
          }
          this.excelService.generateExcel(excelObj);
        } else {
          this.error1 = true;
          this.error = false;
        }
      });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  }

}
