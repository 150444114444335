import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css']
})
export class GlossaryComponent implements OnInit {
  title = 'tapTrack Glossary';
  constructor(private titleService : Title) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
