import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../_services/login.services';
import { NgxSpinnerService } from '../../../../../../node_modules/ngx-spinner';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { query } from '@angular/animations';
import { UserService } from 'src/app/_services';
declare var $;
@Component({
  selector: 'app-pdf-report',
  templateUrl: './pdf-report.component.html',
  styleUrls: ['./pdf-report.component.css'],
  providers: [LoginService, UserService]
})
export class PdfReportComponent implements OnInit {
  Mailreports: any = []
  userData: any = {};
  usersData: any = {};
  companyData: any = {};
  finalOut: any;
  constructor(private US: UserService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private Login: LoginService, public local: LocalStorageService) {

    this.userData = this.local.get("loginInfo");
    this.userData = JSON.parse(this.userData);
    this.usersData = this.userData['userDetails'];
    this.companyData = this.userData['userDetails']['companyIdRelations'];
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let input = {};
      input = params.Query;
      // this.getData(input)
      this.getPdf({ Query: params.Query })
    });
  }

  itmList: any = []
  pdff: any;
  getPdf(Query) {
    let fromDate;
    let toDate
    if (Query.fromDate && Query.toDate) {
      let year = Query.fromDate.jsdate.getFullYear()
      let month = Query.fromDate.jsdate.getMonth() + 1
      let date = Query.fromDate.jsdate.getDate()
      let year1 = Query.toDate.jsdate.getFullYear()
      let month1 = Query.toDate.jsdate.getMonth() + 1
      let date1 = Query.toDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      fromDate = year + '-' + month + '-' + date
      toDate = year1 + '-' + month1 + '-' + date1
      Query.fromDate = fromDate
      Query.toDate = toDate
    }

    let input = {};
    input = {
      'fromDate': fromDate,
      'toDate': toDate,
      'userId': this.usersData.id,
      'companyId': this.companyData.companyId
    }
    this.Login.getmailreports(input).subscribe(reportData => {
      if (reportData != null && reportData != undefined) {
        this.itmList = reportData;
        const doc = new jsPDF();
        //   // doc.autoTable({ html: '#my-table' });
        doc.setFontSize(10);
        doc.setFontStyle('arial');
        doc.text(11, 12, "Mail Room Report");
        doc.text(17, 18, "Number Of Item Types =       " + this.itmList.length);
        doc.autoTable({
          styles: { width: 60, border: 2, halign: 'center' },
          columnStyles: { text: { cellWidth: 'auto', border: 2 } }, // European countries centered
          body: this.itmList,
          columns: [{ header: 'ItemType', dataKey: 'itemType' }, { header: 'Number Of Items', dataKey: 'itemsList' }],
          margin: { top: 70, bottom: 20 },
          tableLineWidth: 0.5,
          theme: 'grid',
          headStyles: {
            fillColor: [250, 250, 250],
            fontSize: 10,
            textColor: [25, 25, 25],
            theme: 'grid',
            marginBottom: 20,
            marginTop: top

          }
        });

       
        this.pdff =   doc.output('datauristring');
        // doc.output('dataurlnewwindow');
        // this.finalOut = doc.output('datauristring')
        // $("#pdf_preview").attr("src", "data:application/pdf;base64," +  doc.output('blob'));
        // doc.autoPrint();
        // var url = doc.output('dataurlstring');
        // $("<iframe>").hide().attr("src", url).appendTo("body");
        // $("<iframe>").attr("src", doc.output('datauristring'))  
        // var iframe = document.getElementById('pdf_preview');
        // iframe.setAttribute('src', doc.output('dataurlstring'))

        // iframe['src'] = doc.output('dataurlstring');

      
      }
    });
  }

}
