import { SessionStorage } from 'angular-web-storage';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../../app/_services/login.services';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Title } from '../../../node_modules/@angular/platform-browser';
declare var $;
@Component({
  selector: 'app-easy-scanning',
  templateUrl: './easy-scanning.component.html',
  styleUrls: ['./easy-scanning.component.css'],
  providers: [LoginService]
})
export class EasyScanningComponent implements OnInit {
  login: any = true;
  casebind: any = false;
  sendto: any = false;
  user: any = false;
  ownerName: any = false;
  itemUinque: any = false;
  successfulSave: any = false;
  loader: any = false;
  uniqueId: any = '';
  savedata: any;
  itemUnique: any;
  companyId: any;
  currentOwner: any;
  easyErr: any = false;
  easyError: any = '';
  uniqId: any;
  private myInputautofocusElement: ElementRef;
  private userFormInputElement: ElementRef;
  private casebinderElement: ElementRef;
  private uniqueInputElement: ElementRef;
  private ownerIputElement: ElementRef;
  private saveinputElement: ElementRef;
  private gotoInputElement: ElementRef;

  @ViewChild('myInputautofocus', { static: false }) set content0(content0: ElementRef) {
    this.myInputautofocusElement = content0;
  }
  @ViewChild('userFormInput', { static: false }) set content(content: ElementRef) {
    this.userFormInputElement = content;
  }
  @ViewChild('casebinderInput', { static: false }) set content1(content1: ElementRef) {
    this.casebinderElement = content1;
  }
  @ViewChild('uniqueIdInput', { static: false }) set content2(content2: ElementRef) {
    this.uniqueInputElement = content2;
  }
  @ViewChild('ownerInput', { static: false }) set content3(content3: ElementRef) {
    this.ownerIputElement = content3;
  }
  @ViewChild('saveInput', { static: false }) set content4(content4: ElementRef) {
    this.saveinputElement = content4;
  }
  @ViewChild('goToIput', { static: false }) set content5(content5: ElementRef) {
    this.gotoInputElement = content5;
  }
  title = 'TapTrack Scanner Entry Module'
  constructor(private titleService: Title, private LS: LoginService, private route: ActivatedRoute,private router: Router) {
    // $(document).ready(function () {
    //   $('input').keyup(function () {
    //     if ($(this).val().length == 10 || ($(this).val().indexOf("-") >= 0 && $(this).val().length == +10 + 1)) {
    //       var i = $('input').index(this);
    //       $('input').eq(i + 1).focus();
    //     }
    //   });
    // });

    this.route.queryParams.subscribe(params => {
      this.LS.getCompanyDetails({ 'uniqueId': params.companyId }).subscribe(data => {
        this.companyId = data['companyId'];
        this.uniqId = params.companyId;
      })

    })
  }
  loginID: any;
  username: any = '';
  casebinder: any;
  loginForm: any = {};
  companyCity:any;
  /**ngOnInit method
      * @constructor
      * the method to call on component loading
      */
  ngOnInit() {
    setTimeout(function () { document.getElementById('myInput').focus(); }, 10);
    this.titleService.setTitle(this.title);
    this.LS.getCompanies().subscribe(
      data => {
        this.loginForm.companyId = data[0].companyId
      },
      error => {

      }
    )
    this.route.queryParams.subscribe(params => {
      this.uniqueId = params.companyId;
      this.getCompanyDetails(params)
    });
  }

  back() {
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: this.uniqId } });
  }
  getCompanyDetails(x) {
    this.LS.getCompanyDetails({'uniqueId' :x.companyId}).subscribe(data=> {
      this.companyCity = data['companyCity'];

    });
  }
  /** insertDash method to insert dash after 3 digits */
  insertDash(itemuniqueId) {
    if (itemuniqueId.value.length === 10 && itemuniqueId.value.indexOf("-") === -1) {
      itemuniqueId.value = `${itemuniqueId.value.substring(0, 3)}-${itemuniqueId.value.substring(3, 10)}`;
    }
    // else {
    //   itemuniqueId.value = `${itemuniqueId.value.substring(0, itemuniqueId.value.indexOf("-"))}${itemuniqueId.value.substring(itemuniqueId.value.indexOf("-") + 1, itemuniqueId.value.length)}`;
    // }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  /** userLogin : validates the login text */
  userLogin() {
    if (this.loginID.toUpperCase() === 'LOGIN') {
      this.login = false;
      this.user = true;
      setTimeout(() => {
        this.userFormInputElement.nativeElement.focus();
      })
    }
    this.loginID = '';
  }
  usernotexist: any = false;
  userDetails: any;
  /** usernameSubmit Method : validate given username is exist for given company
   * @constructor
   * @param {Object} input - input for validating username
   */
  usernameSubmit() {
    let input = {};
    input = {
      'username': this.username,
      'uniqueId': this.uniqueId
    }
    /** calling getUserName api */
    this.LS.validateUserName(input).subscribe(userData => {
      /**this.userDetails holds the response */
      this.userDetails = userData;
      if (userData != null) {
        this.casebind = true;
        setTimeout(() => {
          this.casebinderElement.nativeElement.focus();
        })
        this.login = false;
        this.user = false;
        this.usernotexist = false;
      }
      this.username = '';

    },
      error => {
        this.username = '';
        if (error.error.error.statusCode == "500") {
          this.usernotexist = true;
        }
      }
    );
    // if(this.username == 'huzefa' ){
    //   this.casebind = true;
    //   this.login = false;
    //   this.user = false;
    // }

  }

  itemuniqueId: any = {};
  textAreaString: string = "";
  /** casebinderSubmit : validates casebinder text 
   * @constructor
  */
  casebinderSubmit() {

    if (this.casebinder.toUpperCase() === 'CASEBINDER') {
      this.casebind = false;
      this.login = false;
      this.user = false;
      this.itemUinque = true;
      setTimeout(() => {
        this.uniqueInputElement.nativeElement.focus();
      })
      this.casebinder = '';
    } else {
      this.casebinder = '';
      this.login = true;
      this.casebind = false;
      setTimeout(() => {
        this.myInputautofocusElement.nativeElement.focus();
      })
    }
  }
  uniqueIds = [];
  /** itemUniqueSubmit Method : check whether input has itemUniqueId's
   * @constructor
   * @param {string} this.itemUniqueId - input
   * after entering the text sendto it will validate
   */
  itemUniqueSubmit() {
    if (this.itemuniqueId.value.toLowerCase() !== "sendto") {
      // let input = {
      //   itemUniqueId: this.itemuniqueId.value
      // };
      if (this.itemuniqueId != null) {
        if (this.uniqueIds.indexOf(`${this.itemuniqueId.value.substring(0, 3)}-${this.itemuniqueId.value.substring(3, this.itemuniqueId.value.length)}`) >= 0) {

          this.itemuniqueId.value = '';
          setTimeout(() => {
            this.uniqueInputElement.nativeElement.focus();
          })
          return;
        }
        this.casebind = false;
        this.login = false;
        this.user = false;
        this.itemUinque = true;
        setTimeout(() => {
          this.uniqueInputElement.nativeElement.focus();
        })
        this.sendto = false;
        if (this.itemuniqueId.value.length === 10 && this.itemuniqueId.value.indexOf("-") === -1) {
          this.itemuniqueId.value = `${this.itemuniqueId.value.substring(0, 3)}-${this.itemuniqueId.value.substring(3, 10)}`;
        }
        this.uniqueIds.push(this.itemuniqueId.value);
        if (this.textAreaString) {
          this.textAreaString += `\n${this.itemuniqueId.value}`;
        } else {
          this.textAreaString = this.itemuniqueId.value;
        }
        this.itemuniqueId.value = '';

      }
      // this.LS.validateUniqueId(input).subscribe((data:any) => {
      //   if (data.status) {
      //     this.casebind = false;
      //     this.login = false;
      //     this.user = false;
      //     this.uniqueIds.push(data.details);
      //     this.itemuniqueId.value = '';
      //     if(this.textAreaString){
      //     this.textAreaString += `\n${data.details.itemUniqueId}`;
      //     }else{
      //       this.textAreaString = data.details.itemUniqueId;
      //     }
      //   }else{
      //     this.login = true;
      //     this.itemUinque = false;        
      //   } 
      // });
      this.itemuniqueId.value = '';
    } else {
      if (this.textAreaString.length == 0) {
        alert("Please enter atleast one casebinder Id");
        this.itemuniqueId.value = '';
      } else {
        this.ownerName = true;
        this.itemuniqueId.value = '';
        this.textAreaString = '';
        setTimeout(() => {
          this.ownerIputElement.nativeElement.focus();
        })
        this.itemUinque = false;
      }

    }

  }
  recieverId: any;
  /** ownerNameSubmit Method : validates the given username
   * @constructor
   * @param {Object} input - works as input
   */
  ownerNameSubmit() {
    let input = {};
    input = {
      'username': this.currentOwner,
      'uniqueId': this.uniqueId
    }
    /** getUserName api calling */
    this.LS.validateUserName(input).subscribe(userData => {
      if (userData != null) {
        this.recieverId = userData['id']

        this.sendto = true;

        setTimeout(() => {
          this.saveinputElement.nativeElement.focus();
        })
        this.ownerName = false;
        this.usernotexist = false;
        this.currentOwner = ''
      }
    },
      error => {
        this.currentOwner = ''
        if (error.error.error.statusCode == "500") {
          this.usernotexist = true;
        }
      });
  }
  itemId = this.uniqueIds;
  failuerobj: string;
  /** saveSubmit Method : calls the create transaction method and creates the items and transactions
   * @constructor
   * @param {Object} input - input for createTransactions api
   */
  saveSubmit() {
    this.loader = true;

    if (this.savedata.toUpperCase() === 'SAVE') {

      let input = {};
      input = {
        "reciever": this.recieverId,
        "sender": this.userDetails.id,
        "nextTransactionId": "",
        "transStatusId": 1,
        "itemIds": this.uniqueIds,
        // "itemId" : 1737206,
        "transMailFlag": 0,
        "deletedFlag": 0,
        "addedByAdminFlag": 1,
        "companyId": this.companyId
      }
      /** createTransaction api calling 
       * userData response
      */
      this.LS.saveDataofEasy(input).subscribe((userData: any) => {

        if (userData != null) {

          this.loader = false;

          setTimeout(() => {
            this.gotoInputElement.nativeElement.focus();
            this.myInputautofocusElement.nativeElement.focus();
          })
          this.sendto = false;
          if (userData.failuerobj.length > 0) {
            this.successfulSave = true;
            this.failuerobj = '';
            userData.failuerobj.forEach((item, index) => {
              if (index === 0) {
                this.failuerobj += item.id;
              } else {
                this.failuerobj += `\n${item.id}`;
              }
            });
          } else if (userData.failuerobj.length == 0) {
            this.itemUinque = true;
            setTimeout(() => {
              this.uniqueInputElement.nativeElement.focus();
            })
            this.sendto = false;
            this.successfulSave = false;
            this.uniqueIds = [];
            this.textAreaString = '';
            this.currentOwner = '';
            this.savedata = '';
            this.goTo = '';
            this.easyErr = false;
            this.easyError = '';
          }
        }
      }, error => {
        if (error) {
          this.easyErr = true;
          this.easyError = 'Something went wrong!.'
        }
      });

    } else {
      this.easyErr = false;
      this.easyError = '';
      this.savedata = '';
      this.uniqueIds = [];
      this.login = true;
      this.loader = false;
      this.sendto = false;
      setTimeout(() => {
        this.myInputautofocusElement.nativeElement.focus();
      })
    }
  }

  goTo: any;
  gotoSubmit() {
    if (this.goTo.toUpperCase() === 'LOGIN') {
      this.user = true;
      setTimeout(() => {
        this.userFormInputElement.nativeElement.focus();
      })
      this.successfulSave = false;
      this.casebinder = '';
      this.uniqueIds = [];
      this.textAreaString = '';
      this.currentOwner = '';
      this.savedata = '';
    } else if (this.goTo.toUpperCase() === 'CASEBINDER') {
      this.casebind = false;
      this.login = false;
      this.user = false;
      this.itemUinque = true;
      setTimeout(() => {
        this.uniqueInputElement.nativeElement.focus();
      })
      this.successfulSave = false;
      this.uniqueIds = [];
      this.textAreaString = '';
      this.currentOwner = '';
      this.savedata = '';
    } else {
      this.login = true;
      setTimeout(() => {
        this.myInputautofocusElement.nativeElement.focus();
      })
      this.successfulSave = false;
      this.uniqueIds = [];
      this.textAreaString = '';
      this.currentOwner = '';
      this.savedata = '';
      this.casebinder = '';
    }
    this.goTo = '';
  }
}
