import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { UserService } from "../_services/user.service";
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import * as _ from 'lodash';
import { ExcelService } from '../_services/excel.service';

@Component({
  selector: 'app-items-held-by-user-report',
  templateUrl: './items-held-by-user-report.component.html',
  styleUrls: ['./items-held-by-user-report.component.css']
})
export class ItemsHeldByUserReportComponent implements OnInit {
  userData: any = {};
  companyData: any = {};
  title = 'TapTrack Items Held By User Report ';
  userArr1: any = []
  userArrIds: any = []
  userArr: any = []
  item: any = {}
  input: any = {}
  error = true;
  FromDate: any;
  ToDate: any;
  error2 : any = false;
  error1: any = false;
  showHtmlReport = false;
  constructor(private titleService: Title, private US: UserService, private localStorage: LocalStorageService, private router: Router,private excelService: ExcelService) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }
   }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.US.getUsersLocations({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArrIds = [];
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")"
          ;
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);

      }
    });
  }

  itemreciever(items) {
    this.input['userId'] = JSON.stringify(this.retnum(items))
  }
  retnum(str) {
    var num = str.replace(/[^0-9]/g, '');
    return parseInt(num, 10);
  }

  generatehtmlreport(userId, FromDate, ToDate) {
    if (this.input.userId) {
      if (FromDate && ToDate) {
        let year = FromDate.jsdate.getFullYear()
        let month = FromDate.jsdate.getMonth() + 1
        let date = FromDate.jsdate.getDate()
        let year1 = ToDate.jsdate.getFullYear()
        let month1 = ToDate.jsdate.getMonth() + 1
        let date1 = ToDate.jsdate.getDate()
        if (date <= 9) {
          date = '0' + date
        }
        if (month <= 9) {
          month = '0' + month
        }
        if (date1 <= 9) {
          date1 = '0' + date1
        }
        if (month1 <= 9) {
          month1 = '0' + month1
        }
        let fromDate = year + '-' + month + '-' + date
        let Todate = year1 + '-' + month1 + '-' + date1
        this.input.fromDate = fromDate
        this.input.toDate = Todate
        if (this.input.userId == null || this.input.userId == '') {
          this.error2 = true;
        } else {
          this.input.userId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
          this.showHtmlReport = true;
          this.input.companyId  = this.companyData.companyId;
          window.open(`#/htmlReportitemHeld?qeary=${JSON.stringify(this.input)}`, 'name', 'width=850,height=600,scrollbars=1');
          this.error2 = false;
        }
      } else {
        if (this.input.userId == null || this.input.userId == '') {
          this.error2 = true;
        } else {
          this.input.userId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
          this.input.companyId  = this.companyData.companyId;
          this.showHtmlReport = true;
          window.open(`#/htmlReportitemHeld?qeary=${JSON.stringify(this.input)}`, 'name', 'width=850,height=600,scrollbars=1');
          this.error2 = false;
        }
      }
    } else {
      alert('Please Select Username')
    }

  }
  onDateChanged(fromDate, toDate) {
    if (new Date(fromDate.jsdate) < new Date(toDate.jsdate)) {
      alert('todate should be greater than From date')
      this.FromDate = ''
      this.ToDate = ''
    }

  }

  itmList: any = [];
  trackingArr: any = [];
  usrData1: any = [];
  usrId: any;

  itmListEx: any = [];
  trackingArrEx: any = [];
  usrData1Ex: any = [];
  usrIdEx: any;

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  getPdf() {
    let itemInput = {};
    if (this.item.reciever) {
      this.US.getUsersLocations({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrData1 = usrData;
        let x = this.item.reciever.split("(")
        let y = x[1].split(")");
        this.usrId = this.usrData1.find(getID);
        function getID(obj) {
          if (obj.username == y[0]) {
            return obj.id
          }
        }
        let fromDate;
        let toDate;
        if (this.FromDate && this.ToDate) {
          let year = this.FromDate.jsdate.getFullYear()
          let month = this.FromDate.jsdate.getMonth() + 1
          let date = this.FromDate.jsdate.getDate()
          let year1 = this.ToDate.jsdate.getFullYear()
          let month1 = this.ToDate.jsdate.getMonth() + 1
          let date1 = this.ToDate.jsdate.getDate()
          if (date <= 9) {
            date = '0' + date
          }
          if (month <= 9) {
            month = '0' + month
          }
          if (date1 <= 9) {
            date1 = '0' + date1
          }
          if (month1 <= 9) {
            month1 = '0' + month1
          }
           fromDate = year + '-' + month + '-' + date
           toDate = year1 + '-' + month1 + '-' + date1
        }

        let input = {}
        input = { 'userId': this.usrId.id, 'fromDate' : fromDate, 'toDate' : toDate, companyId:this.companyData.companyId }
        this.US.getItemsHeldByUser(input).subscribe(usrData => {
          this.error1 = false;
          this.error2 = false;
          var daysHeldArr = []
          this.trackingArr = [];
          if (usrData != null && usrData != undefined) {
            this.itmList = usrData;
  
            for(var i=0; i<this.itmList.length;i++) {
              let obj = {} = this.itmList[i];
              let date = new Date(this.itmList[i].recievedDate);
              let month = (date.getMonth() + 1);
              let datee = date.getDate();
              
              // obj['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ date.getHours() +':'+ date.getMinutes(); // +':'+date.getSeconds() + '.' + date.getMilliseconds();
              obj['recievedDate'] =  ((month.toString().length == 1) ? ('0' + month) : month) + '/' + ((datee.toString().length == 1) ? ('0' +datee) : datee) + '/' + date.getFullYear();
              this.trackingArr.push(obj)
            }
  
            const doc = new jsPDF();
            // doc.autoTable({ html: '#my-table' });
            doc.setFontSize(10);
            // doc.setFontStyle('arial');
            // doc.text(11, 12, "Items Sent By User Report");
            // doc.text(17, 18, "Items Count =       " + this.itmList.length);
            
            // console.log('monthhhhhhhhhhhhhhhhhhh ', this.trackingArr[0]);
            doc.text(20, 25, "User Name  :     " + this.trackingArr[0].userName);
            doc.text(120, 25, "Number of items   :    " + this.trackingArr.length);
            doc.autoTable({
              styles: { width: 60, border: 2, halign: 'center' },
              columnStyles: { text: { cellWidth: 'auto', border: 2 } }, // European countries centered
              body: this.trackingArr,
              columns: [{ header: 'Item', dataKey: 'item' }, { header: 'User Name', dataKey: 'userName' }, { header: 'Recieved Date', dataKey: 'recievedDate' }],
              margin: { top: 40, bottom: 20 },
              tableLineWidth: 0.5,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                fontSize: 10,
                textColor: [25, 25, 25],
                theme: "plane",
                marginBottom: 20,
                lineWidth: 0.5,
                marginTop: 20,
                border: 4,
                // rowHeight: 6,
                lineColor: 200
              },
              beforePageContent: function(data) {
                doc.setFontSize(20),doc.setFontStyle("bold"), doc.text("Items Held By User Report", 55, 10);
              }
            });
            doc.save('ItemsHeldByUserReport.pdf');
          } else {
            this.error1 = true;
            this.error2 = false;
          }
        });
  
      });
    } else {
      this.error2 = true;
      this.error1 = false;
    }
   

  }
  clearfields(){
    this.item.reciever = ''
    this.FromDate = ''
    this.ToDate=''
    this.error2= false;
  this.error1= false;
  }

  generateExcel() {
    let itemInput = {};
    if (this.item.reciever) {
      this.US.getUsersLocations({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrData1Ex = usrData;
        let x = this.item.reciever.split("(")
        let y = x[1].split(")");
        this.usrIdEx = this.usrData1Ex.find(getID);
        function getID(obj) {
          if (obj.username == y[0]) {
            return obj.id
          }
        }
        let fromDate;
        let toDate;
        if (this.FromDate && this.ToDate) {
          let year = this.FromDate.jsdate.getFullYear()
          let month = this.FromDate.jsdate.getMonth() + 1
          let date = this.FromDate.jsdate.getDate()
          let year1 = this.ToDate.jsdate.getFullYear()
          let month1 = this.ToDate.jsdate.getMonth() + 1
          let date1 = this.ToDate.jsdate.getDate()
          if (date <= 9) {
            date = '0' + date
          }
          if (month <= 9) {
            month = '0' + month
          }
          if (date1 <= 9) {
            date1 = '0' + date1
          }
          if (month1 <= 9) {
            month1 = '0' + month1
          }
           fromDate = year + '-' + month + '-' + date
           toDate = year1 + '-' + month1 + '-' + date1
        }

        let input = {}
        input = { 'userId': this.usrIdEx.id, 'fromDate' : fromDate, 'toDate' : toDate , companyId:this.companyData.companyId}
        this.US.getItemsHeldByUser(input).subscribe(usrData => {
          this.error1 = false;
          this.error2 = false;
          var daysHeldArr = []
          this.trackingArrEx = [];
          if (usrData != null && usrData != undefined) {
            this.itmListEx = usrData;
  
            for(var i=0; i<this.itmListEx.length;i++) {
              let obj = {} = this.itmListEx[i];
              let date = new Date(this.itmListEx[i].recievedDate);
              let month = (date.getMonth() + 1);
              let datee = date.getDate();
              // obj['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ date.getHours() +':'+ date.getMinutes(); // +':'+date.getSeconds() + '.' + date.getMilliseconds();
              obj['recievedDate'] =  ((month.toString().length == 1) ? ('0' + month) : month) + '/' + ((datee.toString().length == 1) ? ('0' +datee) : datee) + '/' + date.getFullYear();
              this.trackingArrEx.push(obj)
            }
             
            var title = 'Items Held By User Report';
            var header = ['Item','User Name','Received Date'];
            var fileName = 'ItemsHeldByUserReport';
            var reportName = 'Items Held By User Report';
            // var subTitleRow = 'Name :       '+ this.usrIdEx.userFirstName + ' ' + this.usrIdEx.userLastName + ' (' + this.usrIdEx.username + ' )';
            var userNmaeSub = "User Name  :     " + this.trackingArrEx[0].userName;
            var avgDays = 'Number of items    :    ' + this.trackingArrEx.length;
            // var nbItems = 'Number of Items         :    '+this.trackingexArr.length;
            const data1 = [] = this.itmListEx;
            const data = [];
            let data2 = [];
            var self = this
            _.each(data1, function (obj) {
              data2=[];
              data2.push(obj.item)
              data2.push(obj.userName)
              data2.push(obj.recievedDate)
              data.push(data2)
            }) 
            let excelObj = {};
            excelObj = {
              'response' : data,
              'reportName' : reportName,
              'title' : title,
              'header' : header,
              'fileName' : fileName,
              'subTitleRow' : userNmaeSub,
              // 'userNmaeSub' : userNmaeSub,
              'avgDays' : avgDays,
              // 'nbItems' : nbItems
            }
            this.excelService.generateExcel(excelObj);
           
          } else {
            this.error1 = true;
            this.error2 = false;
          }
        });
  
      });
    } else {
      this.error2 = true;
      this.error1 = false;
    }
   

  }

}
