import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppURL } from '../../apiUrl';
import { map } from 'rxjs/operators';

// import { User } from '../_models';

@Injectable()
export class supervisorService {
    constructor(private http: HttpClient) { }

    savePendingUsers(data) {
        return this.http.post(AppURL.serverUrl + 'Users/getPendingUsers', data).pipe(map((res: Response) => res));
      }
      getUsersList(input) {
        // return this.http.get(AppURL.serverUrl + '/Users');
        return this.http.post(AppURL.serverUrl+ 'UserTypes/getpendingusers', input).pipe(map((res: Response) => res));
    
      }
    
      updateFields(data) {
        return this.http.patch(AppURL.serverUrl+ 'Users/' + data.id, data).pipe(map((res: Response) => res));
      }
      getscarchData(data) {
        // return this.http.get(AppURL.serverUrl + '/Users');
        return this.http.post(AppURL.serverUrl+ 'UserTypes/getscarchuserspending', data).pipe(map((res: Response) => res));
    
      }
      getusersCount(input) {
        return this.http.post(AppURL.serverUrl+ 'UserTypes/poendinggetusersCount', input).pipe(map((res: Response) => res));
      } 
      inviteUser(data) {
        return this.http.post(AppURL.serverUrl + 'Users/inviteEmail', data).pipe(map((res: Response) => res));
      }
      messageCreate(data) {
        return this.http.post(AppURL.serverUrl + 'Messages', data).pipe(map((res: Response) => res));
      }
      getMessages(data){
        return this.http.post(AppURL.serverUrl + 'Messages/getCompanyMessages', data).pipe(map((res: Response) => res));
      }
     
      editMessages(data){
        return this.http.post(AppURL.serverUrl + 'Messages/updateMessageStatus', data).pipe(map((res: Response) => res));
      }

      getmessagesCount(input) {
        return this.http.post(AppURL.serverUrl+ 'Messages/getCompanyMessagesCount', input).pipe(map((res: Response) => res));
      } 
}