import { Component, OnInit } from '@angular/core';
import { Title } from '../../../node_modules/@angular/platform-browser';
import {LoginService} from '../../app/_services/login.services';
@Component({
  selector: 'app-super-admin-login',
  templateUrl: './super-admin-login.component.html',
  styleUrls: ['./super-admin-login.component.css'],
  providers: [LoginService]
})
export class SuperAdminLoginComponent implements OnInit {
  title = 'Tap Track :: Super Admin :: Login'
  loginForm : any = {};
  constructor(private titleService: Title, private LS: LoginService) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  login() {
    this.LS.login(this.loginForm).subscribe(loginData => {
    });
    
  }

}
