import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ItemService } from "../../../_services/item.service";
import { ExcelService } from '../../../_services/excel.service';
import { UserService } from "../../../_services/user.service";
import * as _ from 'lodash';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";

import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
@Component({
  selector: 'app-item-closed-filter-report',
  templateUrl: './item-closed-filter-report.component.html',
  styleUrls: ['./item-closed-filter-report.component.css'],
  providers: [ItemService, UserService]

})
export class ItemClosedFilterReportComponent implements OnInit {
  title = 'TapTrack Item Closed Filter Report';
  userData: any = []
  FromDate: any
  ItemValid:any;
  ToDate: any
  itemList: any;
  data:any;
  companyData: any = []
  reportsArry: any = []
  Valid: any = []
  error2: any = false;
  errResults: any = false;
  error3:any=false
  ItemNumber : any;
  itemId : any;
  error1 : any = false;
  error : any = false;
  constructor(private titleService: Title,
    private excelService: ExcelService,
    private localStorage: LocalStorageService, private US: UserService, private IS: ItemService,
    private router: Router, ) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.IS.getItemType({companyId : this.companyData.companyId}).subscribe(itemList => {
      this.itemList = itemList;
    });
  }
  generatehtml(FromDate, ToDate, ItemNumber, itemId) {
    if (ItemNumber && itemId) {
      let input = {
        companyId: this.companyData.companyId,
        itemUniqueId: ItemNumber,
        itemTypeId: itemId
      }
      this.US.validate(input).subscribe(count => {
        this.Valid = count
        if (this.Valid > 0) {
          if (FromDate && ToDate) {
            let year = FromDate.jsdate.getFullYear()
            let month = FromDate.jsdate.getMonth() + 1
            let date = FromDate.jsdate.getDate()
            let year1 = ToDate.jsdate.getFullYear()
            let month1 = ToDate.jsdate.getMonth() + 1
            let date1 = ToDate.jsdate.getDate()
            if (date <= 9) {
              date = '0' + date
            }
            if (month <= 9) {
              month = '0' + month
            }
            if (date1 <= 9) {
              date1 = '0' + date1
            }
            if (month1 <= 9) {
              month1 = '0' + month1
            }
            let fromDate = year + '-' + month + '-' + date
            let Todate = year1 + '-' + month1 + '-' + date1
            let query = {
              "startDate": fromDate,
              "endDate": Todate,
              "companyId": this.companyData.companyId,
              "itemUniqueId": ItemNumber,
              "itemTypeId": itemId
            }
            window.open(`#/hrmlReportmailssclosed?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
          } else {
            this.error2 = false
            let query = {
              "companyId": this.companyData.companyId,
              "itemUniqueId": ItemNumber,
              "itemTypeId": itemId
            }
            window.open(`#/hrmlReportmailssclosed?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
          }

        } else {
          this.error2 = true;
          this.error3 = false
          return
        }
      })
    } else
      if (FromDate && ToDate) {
        let year = FromDate.jsdate.getFullYear()
        let month = FromDate.jsdate.getMonth() + 1
        let date = FromDate.jsdate.getDate()
        let year1 = ToDate.jsdate.getFullYear()
        let month1 = ToDate.jsdate.getMonth() + 1
        let date1 = ToDate.jsdate.getDate()
        if (date <= 9) {
          date = '0' + date
        }
        if (month <= 9) {
          month = '0' + month
        }
        if (date1 <= 9) {
          date1 = '0' + date1
        }
        if (month1 <= 9) {
          month1 = '0' + month1
        }
        let fromDate = year + '-' + month + '-' + date
        let Todate = year1 + '-' + month1 + '-' + date1
        let query = {
          "startDate": fromDate,
          "endDate": Todate,
          "companyId": this.companyData.companyId,
          "itemUniqueId": ItemNumber,
          "itemTypeId": itemId
        }
        window.open(`#/hrmlReportmailssclosed?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
      } else if(ItemNumber) {
        this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
          this.data = usrData
          if (this.data > 0) {
            this.error = false
            this.error2 = false
            let query = {
              "companyId": this.companyData.companyId,
              "itemUniqueId": ItemNumber,
              // "itemTypeId": itemId
            }
        window.open(`#/hrmlReportmailssclosed?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');

          } else {
            this.error3 = true
          }
        })
        
      }else if(itemId){
        let query = {
          "companyId": this.companyData.companyId,
          // "itemUniqueId": ItemNumber,
          "itemTypeId": itemId
        }
        window.open(`#/hrmlReportmailssclosed?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
      }else{
        this.error = true
      }


  }
  clearfields() {
    this.FromDate = ''
    this.ToDate = ''
    this.itemId = ''
    this.ItemNumber = ''
    this.error = false
    this.error1 = false
    this.errResults = false;
    this.error2 = false
    this.error3 = false
  }

  itmList: any = [];
  closeArr: any = [];
  closeDays: any;
  getPdf() {
    let itemInput = {};
    let input = {}
    let fromDate;
    let Todate ;
    if (this.ItemNumber || this.itemId) {
      if (this.FromDate && this.ToDate) {
     let year = this.FromDate.jsdate.getFullYear()
        let month = this.FromDate.jsdate.getMonth() + 1
        let date = this.FromDate.jsdate.getDate()
        let year1 = this.ToDate.jsdate.getFullYear()
        let month1 = this.ToDate.jsdate.getMonth() + 1
        let date1 = this.ToDate.jsdate.getDate()
        if (date <= 9) {
          date = '0' + date
        }
        if (month <= 9) {
          month = '0' + month
        }
        if (date1 <= 9) {
          date1 = '0' + date1
        }
        if (month1 <= 9) {
          month1 = '0' + month1
        }
         fromDate = year + '-' + month + '-' + date
         Todate = year1 + '-' + month1 + '-' + date1
      }
        input = {
        "startDate": fromDate,
        "endDate": Todate,
        "companyId": this.companyData.companyId,
        itemUniqueId: this.ItemNumber,
        itemTypeId: this.itemId
      }
      

      if (this.ItemNumber && this.itemId) {
        this.US.validate(input).subscribe(count => {
          this.Valid = count
          if (this.Valid > 0) {
            this.getResults(input);
          } else {
            this.error1 = false;

            this.error = false;

            this.error2 = true;

            return
          }
        });
      } 
      if (this.ItemNumber && !this.itemId) {
        this.US.validateItemUniqueId(input).subscribe(resp => {
          this.ItemValid = resp;
          if (this.ItemValid > 0) {
            this.error3 = false;
            this.getResults(input)
          } else {
            this.error3 = true;
          }
        })
      } 
      if (!this.ItemNumber && this.itemId) {
        this.getResults(input)
      } 
      
      // else {
      //   this.getResults(input)
      // }
    
    } else if (this.FromDate && this.ToDate) {
     
        let year = this.FromDate.jsdate.getFullYear()
        let month = this.FromDate.jsdate.getMonth() + 1
        let date = this.FromDate.jsdate.getDate()
        let year1 = this.ToDate.jsdate.getFullYear()
        let month1 = this.ToDate.jsdate.getMonth() + 1
        let date1 = this.ToDate.jsdate.getDate()
        if (date <= 9) {
          date = '0' + date
        }
        if (month <= 9) {
          month = '0' + month
        }
        if (date1 <= 9) {
          date1 = '0' + date1
        }
        if (month1 <= 9) {
          month1 = '0' + month1
        }
        let fromDate = year + '-' + month + '-' + date
        let Todate = year1 + '-' + month1 + '-' + date1
        input = {
        "startDate": fromDate,
        "endDate": Todate,
        "companyId": this.companyData.companyId,
        itemUniqueId: this.ItemNumber,
        itemTypeId: this.itemId
      }
      this.getResults(input);
    } else {
      this.error = true;
      this.error1 = false;
      this.error3 = false;
      this.error2 = false;
    }

  }

  // Excel generation
  itmListEx: any = [];
  closeArrEx: any = [];
  closeDaysEx: any;
  generateExcel() {
    let itemInput = {};
    let input = {}
    let fromDate;
    let Todate ;
    if (this.ItemNumber || this.itemId) {
      if (this.FromDate && this.ToDate) {
     let year = this.FromDate.jsdate.getFullYear()
        let month = this.FromDate.jsdate.getMonth() + 1
        let date = this.FromDate.jsdate.getDate()
        let year1 = this.ToDate.jsdate.getFullYear()
        let month1 = this.ToDate.jsdate.getMonth() + 1
        let date1 = this.ToDate.jsdate.getDate()
        if (date <= 9) {
          date = '0' + date
        }
        if (month <= 9) {
          month = '0' + month
        }
        if (date1 <= 9) {
          date1 = '0' + date1
        }
        if (month1 <= 9) {
          month1 = '0' + month1
        }
         fromDate = year + '-' + month + '-' + date
         Todate = year1 + '-' + month1 + '-' + date1
      }
        input = {
        "startDate": fromDate,
        "endDate": Todate,
        "companyId": this.companyData.companyId,
        itemUniqueId: this.ItemNumber,
        itemTypeId: this.itemId
      }
      

      if (this.ItemNumber && this.itemId) {
        this.US.validate(input).subscribe(count => {
          this.Valid = count
          if (this.Valid > 0) {
            this.getExcelResult(input);
          } else {
            this.error1 = false;

            this.error = false;

            this.error2 = true;

            return
          }
        });
      } 
      if (this.ItemNumber && !this.itemId) {
        this.US.validateItemUniqueId(input).subscribe(resp => {
          this.ItemValid = resp;
          if (this.ItemValid > 0) {
            this.error3 = false;
            this.getExcelResult(input)
          } else {
            this.error3 = true;
          }
        })
      } 
      if (!this.ItemNumber && this.itemId) {
        this.getExcelResult(input)
      } 
      
      // else {
      //   this.getResults(input)
      // }
    
    } else if (this.FromDate && this.ToDate) {
     
        let year = this.FromDate.jsdate.getFullYear()
        let month = this.FromDate.jsdate.getMonth() + 1
        let date = this.FromDate.jsdate.getDate()
        let year1 = this.ToDate.jsdate.getFullYear()
        let month1 = this.ToDate.jsdate.getMonth() + 1
        let date1 = this.ToDate.jsdate.getDate()
        if (date <= 9) {
          date = '0' + date
        }
        if (month <= 9) {
          month = '0' + month
        }
        if (date1 <= 9) {
          date1 = '0' + date1
        }
        if (month1 <= 9) {
          month1 = '0' + month1
        }
        let fromDate = year + '-' + month + '-' + date
        let Todate = year1 + '-' + month1 + '-' + date1
        input = {
        "startDate": fromDate,
        "endDate": Todate,
        "companyId": this.companyData.companyId,
        itemUniqueId: this.ItemNumber,
        itemTypeId: this.itemId
      }
      this.getExcelResult(input);
    } else {
      this.error = true;
      this.error1 = false;
      this.error3 = false;
      this.error2 = false;
    }

  }
  onDateChanged(fromDate, toDate) {
    if (new Date(fromDate.jsdate) < new Date(toDate.jsdate)) {
      alert('todate should be greater than From date')
      this.FromDate = ''
      this.ToDate = ''
    }
  }

  clsArr : any = [];
  getResults(input) {
    this.US.getfilterreports(input).subscribe(usrData => {
      var daysHeldArr = []

      if (usrData != null && usrData != undefined) {
        this.error = false;
        this.error1 = false;
        this.itmList = usrData;
        if(Object.keys(this.itmList).length != 0) {
        this.clsArr = [];
        this.closeArr = [];
         for (var i = 0; i < this.itmList.closeList.length; i++) {
          let obj = {} = this.itmList.closeList[i];
          let date = new Date(obj.dateClosed);
          let month = (date.getMonth() + 1);
          let datee = date.getDate();
          obj['dateClosed'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) +'-'+ ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ ((date.getHours().toString().length == 1) ? ('0'+date.getHours()) : date.getHours()) +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes()); // +':'+date.getSeconds() + '.' + date.getMilliseconds();
          this.clsArr.push(obj);    
          this.closeArr.push(this.itmList.closeList[i].daysinTracking)
        }
        if (this.closeArr.reduce((a, b) => a + b, 0) == 0) {
          this.closeDays = 0
        } else {
          this.closeDays = this.closeArr.reduce((a, b) => a + b, 0) == 0 / (this.itmList.closeList.length)
        }
        const doc = new jsPDF();
        // doc.autoTable({ html: '#my-table' });
        doc.setFontSize(10);
        // doc.setFontStyle('arial');
        // doc.text(11, 12, "Item Closed Filter Report");
        // doc.text(17, 18, "Items Count =       " + this.itmList.length);
        doc.text(20, 35, "Total Closed :       " + this.itmList.closeList.length);
        doc.text(140, 35, "Days In Tracking Avg   :    " + this.closeDays);
        doc.autoTable({
          styles: { width: 60, border: 2, halign: 'center' },
          columnStyles: { text: { cellWidth: 'auto', border: 2 } }, // European countries centered
          body: this.clsArr,
          columns: [{ header: 'Item Number', dataKey: 'itemNumber' }, { header: 'Item Type', dataKey: 'ItemType' }, { header: 'Date Closed', dataKey: 'dateClosed' }, { header: 'Last Held By', dataKey: 'lastHeldBy' }, { header: 'Days In Tracking', dataKey: 'daysinTracking' }],
          margin: { top: 40, bottom: 20 },
          tableLineWidth: 0.5,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            fontSize: 10,
            textColor: [25, 25, 25],
            theme: "plane",
            marginBottom: 20,
            lineWidth: 0.5,
            marginTop: 20,
            border: 4,
            // rowHeight: 6,
            lineColor: 200
          },
          beforePageContent: function(data) {
            doc.setFontSize(20),doc.setFontStyle("bold"), doc.text("Item Closed Filter Report", 75, 20);
          }
        });
        doc.save('ClosedItemsFilterReport.pdf');
      } else {
        this.errResults = true;
        this.error = false;
        this.error3 = false;
        this.error1 = false;
        this.error2 = false;
      }
      } else {
        this.error = false;
        this.error3 = true;
        this.error1 = false;
        this.error2 = false;
        this.errResults = false;
      }
    } ,
    error => {
      this.error = false;
      this.error3 = true;
      this.error1 = false;
      this.error2 = false;
      this.errResults = false;
    });
  }
  // excel call method

  clsArrEx : any = [];
  getExcelResult(input) {
    this.US.getfilterreports(input).subscribe(usrData => {
      var daysHeldArr = []

      if (usrData != null && usrData != undefined) {
        this.error = false;
        this.error1 = false;
        this.itmListEx = usrData;
        // if(this.itmListEx.length > 0) {

        // }
        if(Object.keys(this.itmListEx).length != 0) {
        this.clsArrEx = [];
        this.closeArrEx = [];
         for (var i = 0; i < this.itmListEx.closeList.length; i++) {
          let obj = {} = this.itmListEx.closeList[i];
          let date = new Date(obj.dateClosed);
          let month = (date.getMonth() + 1);
          let datee = date.getDate();
          // obj['dateClosed'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) +'-'+ ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ date.getHours() +':'+ date.getMinutes() ; // +':'+date.getSeconds() + '.' + date.getMilliseconds();
          obj['dateClosed'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) +'-'+ ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ ((date.getHours().toString().length == 1) ? ('0'+date.getHours()) : date.getHours()) +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes()); // +':'+date.getSeconds() + '.' + date.getMilliseconds();
          this.clsArrEx.push(obj);    
          this.closeArrEx.push(this.itmListEx.closeList[i].daysinTracking)
        }
        if (this.closeArrEx.reduce((a, b) => a + b, 0) == 0) {
          this.closeDaysEx = 0
        } else {
          this.closeDaysEx = this.closeArrEx.reduce((a, b) => a + b, 0) == 0 / (this.itmListEx.closeList.length)
        }
        var title = 'Item Closed Filter Report';
        // 'Tag Name',
        var header = ['Item Number','Item Type','Date Closed','Last Held By','Days In Tracking'];
        var fileName = 'ItemClosedFilterReport';
        var reportName = 'Item Closed Filter Report';
        var subTitleRow = 'Total Closed :       '+this.itmListEx.closeList.length;
        var itemTotal = 'Days In Tracking Avg   :    ' + this.closeDaysEx;
        const data1 = [] = this.clsArrEx;
        const data = [];
        let data2 = [];
        var self = this
        _.each(data1, function (obj) {
          data2=[];
          data2.push(obj.itemNumber)
          data2.push(obj.ItemType)
          data2.push(obj.dateClosed)
          data2.push(obj.lastHeldBy)
          data2.push(obj.daysinTracking)
          data.push(data2)
        })
        let excelObj = {};
        excelObj = {
          'response' : data,
          'reportName' : reportName,
          'title' : title,
          'header' : header,
          'fileName' : fileName,
          'subTitleRow' : subTitleRow,
          'itemTotal' : itemTotal
        }
        this.excelService.generateExcel(excelObj);
      } else {
        this.error = false;
        this.error3 = false;
        this.error1 = false;
        this.error2 = false;
        this.errResults = true;
      }
      } else {
        this.error = false;
        this.error3 = true;
        this.error1 = false;
        this.error2 = false;
        this.errResults = false;
      }
    } ,
    error => {
      this.error = false;
      this.error3 = true;
      this.error1 = false;
      this.error2 = false;
      this.errResults = false;

    });
  }
}
