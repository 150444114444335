import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../_services/supervisor.service';
import { LocalStorageService } from '../../../../node_modules/angular-web-storage';
import { ItemService } from '../../_services/item.service';
import { Title } from '../../../../node_modules/@angular/platform-browser';
declare var $; 
@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.css'],
  providers: [ItemService]
})
export class HomeMainComponent implements OnInit {
  title ='TapTrack Home';
  authText:any;
  constructor(private ss : supervisorService, public local: LocalStorageService, private IS: ItemService,private titleService: Title) {
    console.log('cccccccc');
   }
  loginForm:any ={};
  messageData:any ;
  userData1 :any;
  companyId:any;
  /**ngOnInit method
      * @constructor
      * the method to call on component loading
      */
  ngOnInit() {
    // this.authText = "W.e.f from May xx, 2020 you will have an additional authentication at login < OTP based > to access application." + "This is a step towards application security enhancement and data privacy policy." + "Kindly navigate to your profile section and update your Mobile Number."
    // this.authText = "As a step towards application security and data privacy, we are under a process to add Multifactor Authentication (OTP Based) at login. <br> Kindly navigate to your Profile screen to verify and update your Email and current Mobile Number";  
      this.titleService.setTitle(this.title);

    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {      
      this.userData1 = JSON.parse(this.userData1);
       this.companyId = this.userData1.userDetails.companyId;
    }
    // console.log('-------------------------- ', this.userData1);
    this.messages();
    this.countofAllItems();
    // if(this.userData1.userDetails.authFlag == 0) {
    //   document.getElementById("openModalButton1").click();
    // }
    
  }

  close() {
    document.getElementById("closeBtn").click();
   }
  showmessageTable :any = false;
  /** messages Method :
   * @constructor
   * @param {Object} input - input for getCompanyMessages
   * @param {Array} messageData - output for getCompanyMessages
   * get messages based on companyId
   */
  messages() {
    let input = {};
    input = {
      'companyId':this.companyId,
      "status": 1
    }
    /** getCompanyMessages api calling */
    this.ss.getMessages(input).subscribe((userData :any) => {
      if (userData != null) {       
        this.messageData = userData;
        if(this.messageData.length>0){
          this.showmessageTable = true;
        }else{
          this.messageData = false;
        }
      }
    });
    
  }
  count:any;
  /** countofAllItems Method : 
   * @constructor
   * @param {Object} input - input for getItemsCount service
   * It will return the list of items( casebinder )
   */
  countofAllItems() {
    let input = {};
    input = {
      'companyId':this.companyId
    }
    /** getItemsCount api calling */
    this.IS.countofItems(input).subscribe((userData :any) => {
      if (userData != null) {       
        this.count = userData;
      }
    });
  }
}
