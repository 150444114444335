import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-supervisor-grid',
  templateUrl: './help-supervisor-grid.component.html',
  styleUrls: ['./help-supervisor-grid.component.css']
})
export class HelpSupervisorGridComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
