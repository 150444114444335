import { Title } from '@angular/platform-browser';
import { Component, OnInit } from "@angular/core";
import { ItemService } from "../../../_services/item.service";
import { AppURL } from '../../../../apiUrl';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
@Component({
  selector: "app-item-list",
  templateUrl: "./item-list.component.html",
  styleUrls: ["./item-list.component.css"],
  providers: [ItemService]
})
export class ItemListComponent implements OnInit {
  title = "TapTrack Item List"
  userData: any = {};
  companyData: any = {};
  itemList: any = [];
  page_size: any;
  parsedUrl:any;
  page: any;
  itemObjerror :any;
  // baseUrl:any;
  recordsCount: any;
  pagination_numbers: any = {};
  searchQuery: any = {
    itemUniqueId: { type: 'startsWith', field: 'itemUniqueId' },
    tagName: { type: 'startsWith', field: 'tagName' },
    tagDesc: { type: 'startsWith', field: 'tagDesc' }
  }
  itemObj: any = {}


  constructor(
    private IS: ItemService,
    private localStorage: LocalStorageService,
    private router: Router,
    private titleService: Title
  ) {
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
    // this.parsedUrl = new URL(window.location.href);
    // this.baseUrl = this.parsedUrl.origin;
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    // this.getItemList(this.page, this.page_size);
    // this.initCount();\
    this.getItemList(0, this.page_size)
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
      if (
        this.pagination_numbers.toNumber ==
        this.pagination_numbers.totalNumber
      )
        return;
      else this.page = this.page + 1;
      // this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.getItemList(this.page, this.page_size);
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.getItemList(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
  }
  initCount() {
    let input = {};
    input = {
      companyId: this.companyData.companyId,
    };
    this.IS.getItemListCount(input).subscribe(recordsCount => {
      this.recordsCount = recordsCount;
      this.pagination_numbers.totalNumber = this.recordsCount;
      this.nextPage("first");
    });
  }

  getItemList(page, page_size) {
    let input = {};
    input = {
      companyId: this.companyData.companyId,
      itemStatusId: 1,
      skip: Number(page * page_size),
      limit: Number(page_size),
      searchQuery: this.searchQuery,
    };
    this.IS.getItemList(input).subscribe(itemObj => {
      this.itemObj = itemObj
      this.recordsCount = this.itemObj.itemListCount;
      this.pagination_numbers.totalNumber = this.recordsCount;
      this.itemList = this.itemObj.itemsList;
    
    },error=>{
      this.itemObjerror = error.error.error.message
      console.log(this.itemObjerror)
      // console.log(JSON.stringify(error.error.error.message))
    });
  }
  searchData() {
    this.nextPage("first");
  }
  clear() {
    this.searchQuery = {
      itemUniqueId: { type: 'startsWith', field: 'itemUniqueId' },
      tagName: { type: 'startsWith', field: 'tagName' },
      tagDesc: { type: 'startsWith', field: 'tagDesc' }
    }
    this.getItemList(0, this.page_size)
  }
  viewBarcode(item) {
    window.open( AppURL.uiUrl+"/#/viewBarcode?itemUniqId=" + item.itemUniqueId,
      "mywindow", "width=350,height=250");
  }
  route(data){
    // itemsReport/:id
    this.router.navigate(['itemListTag/'+data.itemUniqueId]);
  }
}
