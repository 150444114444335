import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class NumberMaskPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    const visibleDigits = 4;
    let maskedSection = phoneNumber.slice(0, -visibleDigits);
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmm ', phoneNumber.slice(0,3));
    // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmm ', phoneNumber.slice(3, 6));
    let visibleSection = phoneNumber.slice(-visibleDigits);
    // console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv ', visibleSection);
    // return maskedSection.replace(/./g, '*') + '-'+visibleSection;

    return phoneNumber.slice(0,3).replace(/./g, '*')+ '-'+ phoneNumber.slice(3, 6).replace(/./g, '*') + '-'+visibleSection
  }
}