import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppURL } from '../../apiUrl';
import { map } from 'rxjs/operators';

// import { User } from '../_models';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }
  private messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource.asObservable();

  getUsers(input) {
    return this.http.post(AppURL.serverUrl + 'Users/getListOfUsers', input).pipe(map((res: Response) => res));
  }

  getUsersLocations(input) {
    return this.http.post(AppURL.serverUrl + 'Users/getUsersLocations', input).pipe(map((res: Response) => res));
  }

  getTagDetails(itemUniqueId) {
    return this.http.get(AppURL.serverUrl + 'Items?filter[include]=tag&filter[where][itemUniqueId]=' + itemUniqueId).pipe(map((res: Response) => res));
  }
  updateFields(data) {
    return this.http.patch(AppURL.serverUrl + 'Tags/' + data.tagId, data).pipe(map((res: Response) => res));
  }
  getusertrackingreports(data) {
    return this.http.post(AppURL.serverUrl + 'Items/userTrackingReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  getusersentreports(data) {
    return this.http.post(AppURL.serverUrl + 'Items/itemsSentReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  gettemiheldreports(data) {
    return this.http.post(AppURL.serverUrl + 'Items/timeHeldReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  getitemsearchreport(data) {
    return this.http.post(AppURL.serverUrl + 'Items/itemSearchReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  getfilterreports(data) {
    return this.http.post(AppURL.serverUrl + 'Items/itemCloseFilterReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  getdatausingtagid(data) {
    return this.http.post(AppURL.serverUrl + 'Transactions/getdatausingtagid', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  getitemsreportsarray(data) {
    return this.http.post(AppURL.serverUrl + 'Items/itemsReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  findexistance(data) {
    return this.http.post(AppURL.serverUrl + 'Items/findexistance', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  validate(data) {
    return this.http.post(AppURL.serverUrl + 'Items/validateitems', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }

  validateItemUniqueId(data) {
    return this.http.post(AppURL.serverUrl + 'Items/validateItemId', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  getmailreports(data) {
    return this.http.post(AppURL.serverUrl + 'ItemTypes/mailRoomReport', data).pipe(map((res: Response) => res));
    // return this.http.post(AppURL.serverUrl+ 'Items/userTrackingReport', {}).pipe(map((res: Response) => res));
  }
  // getAll() {
  //     return this.http.get<User[]>(`${config.apiUrl}/users`);
  // }

  // getById(id: number) {
  //     return this.http.get(`${config.apiUrl}/users/` + id);
  // }

  // register(user: User) {
  //     return this.http.post(`${config.apiUrl}/users/register`, user);
  // }

  // update(user: User) {
  //     return this.http.put(`${config.apiUrl}/users/` + user.id, user);
  // }

  // delete(id: number) {
  //     return this.http.delete(`${config.apiUrl}/users/` + id);
  // }

  getItemsHeldByUser(data) {
    return this.http.post(AppURL.serverUrl + 'Items/itemHeldbyUserReport', data).pipe(map((res: Response) => res));
  }

}