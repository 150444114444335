import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ItemService } from '../../_services/item.service';
import { LoginService } from '../../../../../_services/login.services';


@Component({
  selector: 'app-html-report-inventory',
  templateUrl: './htmlmailreport.component.html',
  styleUrls: ['./htmlmailreport.component.css'],
  providers: []
})
export class HtmlmailreportComponent implements OnInit {
    Mailreports:any;
  constructor(
    private route: ActivatedRoute,private Login: LoginService, private spinner: NgxSpinnerService
  ) { }
  itemInvetordata:any =[];
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
       this.getData({Query: params.Query})
      });
  }
  getData(Query){
    this.spinner.show();
      this.Login.getmailreports(JSON.parse(Query.Query)).subscribe(reportData => {
        this.Mailreports = reportData;
        
        if(this.Mailreports.length >0){
          this.spinner.hide();
          this.Mailreports = reportData;
        }
      },
      error =>{
        this.spinner.hide();
      }
    );
    }
}
