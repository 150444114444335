import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { ItemService } from "../../../_services/item.service";
import { Router } from "@angular/router";
declare var $;
@Component({
  selector: "app-add-item-type",
  templateUrl: "./add-item-type.component.html",
  styleUrls: ["./add-item-type.component.css"],
  providers: [ItemService, LocalStorageService]
})
export class AddItemTypeComponent implements OnInit {
  title = "TapTrack Add Item Type";
  item: any = {};
  userData: any = {};
  companyData: any = {};
  validationError: any = false;
  // getItemData : any  = [];
  arrayOne(n: number): any[] {
    return Array(n);
  }
  constructor(
    private IS: ItemService,
    private titleService: Title,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }
  }



  numberOnly(e) {
    $(document).ready(function () {
      $('#iFormat').keydown(function (x) {
      });
    });
    if (e.code == "Backslash" || e.code == "Backquote" || e.key == '|' || e.key == ' ' || e.key == '.' || e.key == '!' || e.key == '$' || e.key == '@' || e.key == '#' || e.key == '%' || e.key == '^' || e.key == '&' || e.key == '*' || e.key == '(' || e.key == ')' || e.key == ',' || e.key == '.' || e.key == '/' || e.key == '?' || e.key == '<' || e.key == '>' || e.key == '+' || e.key == '=' || e.key == '_' || e.key == ';' || e.key == ':' || e.key == "'" || e.key == '"' || e.key == '{' || e.key == '}' || e.key == '[' || e.key == ']') {
      e.preventDefault();
    }
  }


  ngOnInit() {
    this.titleService.setTitle(this.title);
    // this.getItemTypes();
  }
  itemtypeshow: any = false;
  itemtypDescshw: any = false;
  formatShw: any = false;
  timeLimitShw: any = false;
  showDiv: any = true;
  createItemType() {
    this.validationError = false;
    if (
      (this.item.itemType == null ||
        this.item.itemType == "") ||
      (this.item.itemTypeDesc == null ||
        this.item.itemTypeDesc == "") ||
      (this.item.regexFormat == null ||
        this.item.regexFormat == "") ||
      (this.item.limitTime == null ||
        this.item.limitTime == "")
    ) {
      this.itemtypeshow = true;
      this.itemtypDescshw = true;
      this.formatShw = true;
      this.timeLimitShw = true;
    }
    if (this.item.regexFormat) {
      this.formatShw = false;
    }
    if (this.item.itemTypeDesc) {
      this.itemtypDescshw = false;
    }
    if (this.item.limitTime) {
      this.timeLimitShw = false;
    }
    if (this.item.itemType) {
      this.itemtypeshow = false;
    }
    if ((this.item.itemType) && (this.item.itemTypeDesc) && (this.item.limitTime) && (this.item.regexFormat)) {
      let input = {};
      input = this.item;
      input["companyId"] = this.companyData.companyId;
      this.IS.createItemType(this.item).subscribe(
        itemTypeData => {
          this.showDiv = false;
          this.item = {};
        },
        err => {
          this.validationError = true
        }
      );
    }else{
      return
    }
  }


  // getItemTypes() {
  //   this.IS.getItemType().subscribe(getItemData => {
  //     this.getItemData = getItemData;
  //   });
  // }
}
