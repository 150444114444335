import { Component, OnInit } from "@angular/core";
import { ItemService } from "../../_services/item.service";
import { UserService } from "../../_services/user.service";
import * as _ from "lodash";

import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import { Title } from "../../../../node_modules/@angular/platform-browser";
@Component({
  selector: "app-mydashboard",
  templateUrl: "./mydashboard.component.html",
  styleUrls: ["./mydashboard.component.css"],
  providers: [ItemService, UserService]
})
export class MydashboardComponent implements OnInit {
  title = "TapTrack My Dashboard";
  userData: any = {};
  companyData: any = {};
  userArr1: any = [];
  userArr: any = [];
  item: any = {};
  selectedItems1: any = [];
  selectedItems: any = [];
  SentData: any = [];
  itemList1: any = [];
  itemList2: any = [];
  itemList3: any = [];
  page_size1: any;
  page_size2: any;
  page_size3: any;
  page1: any;
  page2: any;
  page3: any;
  itemList1error: any;
  itemList2error: any;
  itemList3error: any;
  ErrorSentmsg: any = []
  recordsCount1: any;
  recordsCount2: any;
  recordsCount3: any;
  pagination_numbers1: any = {};
  pagination_numbers2: any = {};
  pagination_numbers3: any = {};
  userId: any;
   /**
      * @constructor
      * the method to call on component loading
      */
  constructor(
    private IS: ItemService,
    private US: UserService,
    private localStorage: LocalStorageService,
    private router: Router,
    private titleService: Title
  ) {
    this.page1 = 0;
    this.page2 = 0;
    this.page3 = 0;
    this.page_size1 = 7;
    this.page_size2 = 7;
    this.page_size3 = 7;
    this.recordsCount1 = 0;
    this.recordsCount2 = 0;
    this.recordsCount3 = 0;
    this.pagination_numbers1.fromNumber = 0;
    this.pagination_numbers2.fromNumber = 0;
    this.pagination_numbers3.fromNumber = 0;
    this.pagination_numbers1.toNumber = this.page_size1;
    this.pagination_numbers2.toNumber = this.page_size2;
    this.pagination_numbers3.toNumber = this.page_size3;
/** to get login info from localstorage */
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
      this.userId = this.userData.userId;
      // alert(this.userData)
    } else {
      this.router.navigate(["/login"]);
    }
  }
  userArrIds = [];
   /**ngOnInit method
      * @constructor
      * the method to call on component loading
      */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.initCount1();
    this.initCount2();
    this.initCount3();

    this.US.getUsers({ companyId: this.companyData.companyId }).subscribe(usrData => {
      this.userArr1 = [];
      this.userArr1 = usrData;
      this.userArrIds = [];
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] + "\r\n" +
          "(" +
          this.userArr1[i]["username"] +
          ")";
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);
      }
    });
  }
  onSelectBoxChange1(m, event) {
    if (event.target.checked) {
      this.selectedItems1.push(m);
    } else {
      this.selectedItems1.splice(
        this.selectedItems1
          .map(function (x) {
            return x.transactionId;
          })
          .indexOf(m.transactionId),
        1
      );
    }
  }
  onSelectBoxChange(m, event) {
    if (event.target.checked) {
      this.selectedItems.push(m);
    } else {
      this.selectedItems.splice(
        this.selectedItems
          .map(function (x) {
            return x.transactionId;
          })
          .indexOf(m.transactionId),
        1
      );
    }
  }
/** nextPage1 method for pagination */
  nextPage1(whichPage) {
    if (whichPage == "next" && this.recordsCount1) {
      if (
        this.pagination_numbers1.toNumber ==
        this.pagination_numbers1.totalNumber
      )
        return;
      else this.page1 = this.page1 + 1;
    } else if (whichPage == "previous" && this.page1) {
      this.page1 = this.page1 - 1;
    } else if (whichPage == "first" && this.recordsCount1) {
      this.page1 = this.page1 * 0;
    } else if (whichPage == "last" && this.recordsCount1) {
      this.page1 = Math.floor(this.recordsCount1 / this.page_size1);
    } else return;
    this.pagination_numbers1.fromNumber = this.page1 * this.page_size1;
    this.pagination_numbers1.toNumber = (this.page1 + 1) * this.page_size1;
    if (
      this.pagination_numbers1.toNumber > this.pagination_numbers1.totalNumber
    )
      this.pagination_numbers1.toNumber = this.pagination_numbers1.totalNumber;
    this.getItemList1(this.page1, this.page_size1);
  }
  /** nextPage2 method for pagination */
  nextPage2(whichPage) {
    if (whichPage == "next" && this.recordsCount2) {
      if (
        this.pagination_numbers2.toNumber ==
        this.pagination_numbers2.totalNumber
      )
        return;
      else this.page2 = this.page2 + 1;
    } else if (whichPage == "previous" && this.page2) {
      this.page2 = this.page2 - 1;
    } else if (whichPage == "first" && this.recordsCount2) {
      this.page2 = this.page2 * 0;
    } else if (whichPage == "last" && this.recordsCount2) {
      this.page2 = Math.floor(this.recordsCount2 / this.page_size2);
    } else return;
    this.pagination_numbers2.fromNumber = this.page2 * this.page_size2;
    this.pagination_numbers2.toNumber = (this.page2 + 1) * this.page_size2;
    if (
      this.pagination_numbers2.toNumber > this.pagination_numbers2.totalNumber
    )
      this.pagination_numbers2.toNumber = this.pagination_numbers2.totalNumber;
    this.getItemList2(this.page2, this.page_size2);
  }
  /** nextPage3 method for pagination */
  nextPage3(whichPage) {
    if (whichPage == "next" && this.recordsCount3) {
      if (
        this.pagination_numbers3.toNumber ==
        this.pagination_numbers3.totalNumber
      )
        return;
      else this.page3 = this.page3 + 1;
    } else if (whichPage == "previous" && this.page3) {
      this.page3 = this.page3 - 1;
    } else if (whichPage == "first" && this.recordsCount3) {
      this.page3 = this.page3 * 0;
    } else if (whichPage == "last" && this.recordsCount3) {
      this.page3 = Math.floor(this.recordsCount3 / this.page_size3);
    } else return;
    this.pagination_numbers3.fromNumber = this.page3 * this.page_size3;
    this.pagination_numbers3.toNumber = (this.page3 + 1) * this.page_size3;
    if (
      this.pagination_numbers3.toNumber > this.pagination_numbers3.totalNumber
    )
      this.pagination_numbers3.toNumber = this.pagination_numbers3.totalNumber;
    this.getItemList3(this.page3, this.page_size3);
  }
  filterForPageSize1(selectedPageSize) {
    this.page_size1 = selectedPageSize;
    this.getItemList1(this.page1, this.page_size1);
    this.page1 = 0;
    // this.page_size1 = 7;
    this.nextPage1("first");
  }
  filterForPageSize2(selectedPageSize) {
    this.page_size2 = selectedPageSize;
    this.getItemList2(this.page2, this.page_size2);
    this.page2 = 0;
    // this.page_size2 = 7;
    this.nextPage2("first");
  }
  filterForPageSize3(selectedPageSize) {
    this.page_size3 = selectedPageSize;
    this.getItemList3(this.page3, this.page_size3);
    this.page3 = 0;
    // this.page_size3 = 7;
    this.nextPage3("first");
  }
/** initCount1 Method :
 * @constructor
 * @param {Object} input - input for getItemReceivedCount service
 * @param {Number} recordsCount1 - response
 * to get items received count
 */
  initCount1() {
    let input = {};
    input = {
      userId: this.userData.userId
    };
    /** getItemReceivedCount api calling */
    this.IS.getItemReceivedCount(input).subscribe(recordsCount => {
      this.recordsCount1 = recordsCount;
      this.pagination_numbers1.totalNumber = this.recordsCount1;
      this.nextPage1("first");
    });
  }
  /** initCount2 Method :
 * @constructor
 * @param {Object} input - input for getItemsOwnedCount service
 * @param {Number} recordsCount2 - response
 * to get items owned count
 */
  initCount2() {
    let input = {};
    input = {
      userId: this.userData.userId
    };
    /** getItemsOwnedCount api calling */
    this.IS.getItemsOwnedCount(input).subscribe(recordsCount => {
      this.recordsCount2 = recordsCount;
      this.pagination_numbers2.totalNumber = this.recordsCount2;
      this.nextPage2("first");
    });
  }
   /** initCount3 Method :
 * @constructor
 * @param {Object} input - input for getItemSentCount service
 * @param {Number} recordsCount3 - response
 * to get items sent count
 */
  initCount3() {
    let input = {};
    input = {
      userId: this.userData.userId
    };
    /** getItemSentCount api calling */
    this.IS.getItemSentCount(input).subscribe(recordsCount => {
      this.recordsCount3 = recordsCount;
      this.pagination_numbers3.totalNumber = this.recordsCount3;
      this.nextPage3("first");
    });
  }
/** getItemList1 Method
 * to get list of received items for given userId
 * @constructor
 * @param {Object} input - input for getItemReceived service
 * @param {Array} itemList1 - output for getItemReceived service
 */
  getItemList1(page, page_size) {
    let input = {};
    input = {
      userId: this.userData.userId,
      skip: page * page_size,
      limit: page_size
    };
    // console.log('ggggggggggggggggggggggggggggggggg ', input,page, page_size);
    /** getItemReceived api calling */
    this.IS.getItemReceived(input).subscribe(itemList => {
      this.itemList1 = itemList;
      // console.log(JSON.stringify(this.itemList1))
    }, error => {
      this.itemList1error = error.error.error.message
      // console.log(JSON.stringify(error.error.error.message))
    });
  }
  /** getItemList2 Method
 * to get list of owned items for given userId
 * @constructor
 * @param {Object} input - input for getItemsOwned service
 * @param {Array} itemList2 - output for getItemsOwned service
 */
  getItemList2(page, page_size) {
    let input = {};
    input = {
      searchQuery: { fromDate: undefined, toDate: undefined },
      companyId: this.companyData.companyId,
      userId: this.userData.userId,
      skip: page * page_size,
      limit: page_size
    };
    /** getItemsOwned api calling */
    this.IS.getItemsOwned(input).subscribe(itemList => {
      _.each(itemList, function (data) {
        data["comments"] = "";
      });
      this.itemList2 = itemList;
    }, error => {
      this.itemList2error = error.error.error.message
      // console.log(JSON.stringify(error.error.error.message))
    });
  }
   /** getItemList3 Method
 * to get list of owned items for given userId
 * @constructor
 * @param {Object} input - input for getItemSent service
 * @param {Array} itemList3 - output for getItemSent service
 */
  getItemList3(page, page_size) {
    let input = {};
    input = {
      userId: this.userData.userId,
      skip: page * page_size,
      limit: page_size
    };
    /** getItemSent api calling */
    this.IS.getItemSent(input).subscribe(itemList => {
      this.itemList3 = itemList;
      // console.log(this.itemList3)
    }, error => {
      this.itemList3error = error.error.error ? error.error.error.message : 'Please Reload Page'
      // console.log(JSON.stringify(error.error.error.message))
    });
  }
   /** cancelSentItems Method
 * cancel given item
 * @constructor
 * @param {Object} selectedItems - input for cancelSentItem service
 */
  cancelSentItems() {
    if(this.selectedItems.length > 0){
      /** cancelSentItem api calling */
      this.IS.cancelSentItem(this.selectedItems).subscribe(cancelSentItems => {
        if(cancelSentItems){
          alert('selected items are Cancelled')

          this.itemList3 = []
  
          this.itemList2 = []
  
          this.itemList1 = []
          /** calling get services and calling pagination */
          this.initCount3();
          this.initCount1();
          this.initCount2();
        }
     
      });
    }else{
      alert('Please Select Items')
    }
   
  }
  /** acknowledgeRecieveItem Method
 * cancel given item
 * @constructor
 * @param {Object} selectedItems1 - input for acknowledgeRecieveItem service
 */
  acknowledgeRecieveItem() {
    // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ', this.selectedItems1);
    if(this.selectedItems1.length > 0){
      /** acknowledgeRecieveItem api calling */
      this.IS.acknowledgeRecieveItem(this.selectedItems1).subscribe(
        cancelSentItemss => {
          if(cancelSentItemss){
            alert('selected items are owned')
            this.itemList3 = []
  
            this.itemList2 = []
    
            this.itemList1 = []
            /** calling get services and calling pagination */
            this.initCount1();
            this.initCount2();
            this.initCount3();
          }
        }
      );
    }else{
      alert('Please Select Items')
    }
  
  }
  /** onSearchChange Method  :
   *  search method of items received
   * @constructor
   * @param {Object} input - input for searchItemReceived
   * @param {Object} itemList1 - output for searchItemReceived
   */
  onSearchChange(ItemNumber) {
    if (ItemNumber) {
      let input = {};
      this.page1, this.page_size1;
      input = {
        userId: this.userData.userId,
        skip: this.page1,
        limit: this.page_size1,
        text: ItemNumber,
        feild: "itemUniqueId"
      };
      /** searchItemReceived api calling */
      this.IS.searchItemReceived(input).subscribe(itemList => {
        this.itemList1 = itemList;
        this.pagination_numbers1.totalNumber =this.itemList1.length

        this.pagination_numbers1.toNumber = this.itemList1.length
      });
    } else {
      this.initCount1();
    }
  }
    /** onSearchChange2 Method  :
   *  search method of items owned
   * @constructor
   * @param {Object} input - input for getItemsOwnedsearch
   * @param {Object} itemList2 - output for getItemsOwnedsearch
   */
  onSearchChange2(ItemNumber) {
    if (ItemNumber) {
      let input = {};
      this.page1, this.page_size1;
      input = {
        userId: this.userData.userId,
        skip: this.page1,
        limit: this.page_size1,
        text: ItemNumber,
        feild: "itemUniqueId"
      };
      /** getItemsOwnedsearch api calling */
      this.IS.getItemsOwnedsearch(input).subscribe(itemList => {
        this.itemList2 = itemList;
        this.pagination_numbers2.totalNumber =this.itemList2.length

        this.pagination_numbers2.toNumber = this.itemList2.length
      });
    } else {
      this.initCount2();
    }
  }
   /** onSearchChange3 Method  :
   *  search method of items sent
   * @constructor
   * @param {Object} input - input for getItemSentsearch
   * @param {Object} itemList2 - output for getItemSentsearch
   */
  onSearchChange3(ItemNumber) {
    if (ItemNumber) {
      let input = {};
      this.page1, this.page_size1;
      input = {
        userId: this.userData.userId,
        skip: this.page1,
        limit: this.page_size1,
        text: ItemNumber,
        feild: "itemUniqueId"
      };
      /** getItemSentsearch api calling */
      this.IS.getItemSentsearch(input).subscribe(itemList => {
        this.itemList3 = itemList;
        this.pagination_numbers3.totalNumber =this.itemList3.length

        this.pagination_numbers3.toNumber = this.itemList3.length
      });
    } else {
      this.initCount3();
    }
  }
  Pushdata(m) {
    this.SentData.push(m);
  }
  
  SentItems() {
    var obj = {};
    var items = []
    var transComments = []
    var withoutcomments = []
    if(this.SentData.length > 0){
    _.each(this.SentData, function (data) {
      if (data.comments) {
        items.push(data.itemUniqueId);
        transComments.push(data.comments);
      } else {
        withoutcomments.push(data);
      }

    });
    if (withoutcomments.length > 0) {
      alert(' items with only Comments  will Be sent')
      this.initCount2();
    }
    obj = {
      reciever: this.userArrIds[this.userArr.indexOf(this.item.reciever)],
      sender: this.userId, //sento userId
      nextTransactionId: "",
      transStatusId: 1,
      itemIds: items,
      transMailFlag: 0,
      deletedFlag: 0,
      addedByAdminFlag: 0, //based on login if admin send 1 else 0
      transComments: transComments,
      companyId: this.companyData.companyId, // sender’s Company ID
      userId: this.userId
    };
    this.IS.SentitemsTo(obj).subscribe(res => {
      if(res){
        alert('Selected Items are Sent')
        this.itemList3 = []
        this.itemList2 = []
        this.itemList1 = []
        this.initCount1();
        this.initCount2();
        this.initCount3();
        transComments = []
        items = []
        obj = {}
        this.SentData = []
        this.item.reciever = "";
      }
      
    },
      error => {
        if (error.error.error.statusCode == "500") {
          transComments = []
          items = []
          obj = {}
          console.log(transComments)
          console.log(items)
          this.SentData = []

          // alert(error.error.error.message);
        }
      }
    );
  }else{
    alert('Please Select Items')
  }
  }
}
