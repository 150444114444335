import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from '../../../../../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-time-held-htmlreport',
  templateUrl: './time-held-htmlreport.component.html',
  styleUrls: ['./time-held-htmlreport.component.css']
})
export class TimeHeldHtmlreportComponent implements OnInit {
  reportsArry:any=[];
  count: any = 0;
  reportsArryerror:any;
  constructor(private US: UserService, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getData({query: params.query})
    });
  }
  getData(data){
    this.spinner.show();
    this.US.gettemiheldreports(JSON.parse(data.query)).subscribe(usrData => {
      this.reportsArry = usrData;
      for(var i=0;i<this.reportsArry.length;i++) {
        this.count = parseInt(this.reportsArry[i].daysHeld) + this.count;
      }
      this.count = (this.count )/ (this.reportsArry.length);
      this.count = parseFloat(this.count);
      this.count = this.count.toFixed();
      _.each(this.reportsArry,function (data) {
        data['daysHeld'] = parseInt(data.daysHeld)
      })
      this.spinner.hide();
    },
    error=>{
      this.reportsArryerror = error.error.error.message
      console.log(this.reportsArryerror)
      this.spinner.hide();
    });
   
  }
}
