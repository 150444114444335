import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-add-item-type',
  templateUrl: './help-add-item-type.component.html',
  styleUrls: ['./help-add-item-type.component.css']
})
export class HelpAddItemTypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
