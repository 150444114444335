import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../../node_modules/angular-web-storage';
import { ItemService } from '../../../_services/item.service';

@Component({
  selector: 'app-item-checkout',
  templateUrl: './item-checkout.component.html',
  styleUrls: ['./item-checkout.component.css'],
  providers: [ItemService]
})
export class ItemCheckoutComponent implements OnInit {
  title = 'TapTrack Item Checkout';
  userData1: any;
  userId: any;
  companyId: any;
  totalItemsCount: any = 0
  selectedFilterValue = 7
  pageSkip = 0
  pageLimit = 7
  itemChecklist: any = [];
  itemChecklisterror: any;
  searchQuery = {
    'itemUniqId': ""
  }
  datePicker: any = {}
  page: any;
  page_size: any;
  pagination_numbers: any = {};
  constructor(private titleService: Title, public local: LocalStorageService, private IS: ItemService) {
    this.page = 0;
    this.page_size = 7;
    this.totalItemsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {
      this.userData1 = JSON.parse(this.userData1);
      this.userId = this.userData1.userId;
      this.companyId = this.userData1.userDetails.companyId;
    }
    this.itemCheckout(this.pageSkip, this.pageLimit);
    this.getItemsCount()
  }
  // itemChecklist: any;

  // Method to get Items Count
  getItemsCount() {
    var query = {
      'companyId': this.companyId,
      'itemStatusId': 1
    }
    this.IS.getItemListCount(query).subscribe((count) => {
      this.totalItemsCount = count
      this.pagination_numbers.totalNumber = this.totalItemsCount;
    })
  }
  // End ItemCount

  itemCheckout(skip, limit) {
    let input = {
      'companyId': this.companyId,
      'skip': skip ? skip : 0,
      'limit': limit ? limit : 7,
      'itemStatusId': 1,
      'searchQuery': this.searchQuery
    }
    this.IS.itemCheckout(input).subscribe((userData: any) => {

      this.itemChecklist = userData;
      if (this.searchQuery.itemUniqId != "" && this.searchQuery.itemUniqId) {
        this.pagination_numbers.toNumber = this.itemChecklist.length
        this.pagination_numbers.totalNumber = this.itemChecklist.length;
        this.pagination_numbers.fromNumber = 0
      } else {
        // this.pagination_numbers.toNumber = 7
        // this.pagination_numbers.toNumber = this.pagination_numbers.toNumber

      }
      if(this.itemChecklist.length==0) {
        console.log(this.itemChecklist)
        this.totalItemsCount=0;
        this.pagination_numbers.toNumber = this.itemChecklist.length
        this.pagination_numbers.totalNumber = this.itemChecklist.length;
        this.pagination_numbers.fromNumber = 0
      }

    },
      error => {
        this.itemChecklisterror = error.error.error.message
        console.log(this.itemChecklisterror)
      });
  }


  itemcheckToSend = [];
  idsOfItemsToSend = [];
  itemCheckoutSend(isChecked, data) {
    if (isChecked === true) {
      if (this.itemcheckToSend.length === 0) {
        this.itemcheckToSend.push(data);
        this.idsOfItemsToSend.push(data.itemId);
      } else {
        if (this.idsOfItemsToSend.indexOf(data.itemId) === -1) {
          this.itemcheckToSend.push(data);
          this.idsOfItemsToSend.push(data.itemId);
        }
      }
    } else if (isChecked === false) {
      this.itemcheckToSend.splice(this.idsOfItemsToSend.indexOf(data.itemId), 1);
      this.idsOfItemsToSend.splice(this.idsOfItemsToSend.indexOf(data.itemId), 1);
    }
  }

  scancheckoutSubmit() {
    let itemIds = [];
    this.itemcheckToSend.map((item) => {
      itemIds.push(item.itemUniqueId);
    });
    let input = {
      "reciever": this.userId,
      "sender": this.userId,
      "nextTransactionId": "",
      "transStatusId": 1,
      "itemIds": [...itemIds],
      "transMailFlag": 0,
      "deletedFlag": 0,
      "addedByAdminFlag": 0,
      "transComment": "string",
      "companyId": this.companyId

    }
    if (this.itemcheckToSend.length > 0) {
      this.IS.checkoutItemSubmt(input).subscribe((userData: any) => {
        if (userData) {

          alert('Selected Items are checkedout')
          this.searchQuery = {
            itemUniqId:''
          }
          this.page = 0;
          this.page_size = 7;
          this.totalItemsCount = 0;
          this.pagination_numbers.fromNumber = 0;
          this.pagination_numbers.toNumber = this.page_size;
          this.itemcheckToSend = [];

          this.idsOfItemsToSend = [];

          this.initconut()
        }
      });
    }
  }
  initconut() {
    this.itemCheckout(0, this.selectedFilterValue);
    this.getItemsCount()

  }

  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.itemCheckout(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.totalItemsCount) {
      if (
        this.pagination_numbers.toNumber ==
        this.pagination_numbers.totalNumber
      )
        return;
      else this.page = this.page + 1;
      // this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.totalItemsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.totalItemsCount) {
      this.page = Math.floor(this.totalItemsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.itemCheckout(this.page, this.page_size);
  }

  clearSearch() {
    this.searchQuery = {
      itemUniqId:''
    }
    this.datePicker = {
      fromDate: '',
      toDate: ''
    }
    this.pageSkip = 0
    this.pageLimit = this.selectedFilterValue
    // this.itemCheckout(this.pageSkip, this.selectedFilterValue)
    this.pagination_numbers.toNumber = 7
   this.getItemsCount()
   this.nextPage("first");
  }
  searchItems(datePicker) {
    if (datePicker.fromDate && datePicker.fromDate.jsdate)
      this.searchQuery['fromDate'] = datePicker.fromDate.jsdate
    if (datePicker.toDate && datePicker.toDate.jsdate)
      this.searchQuery['toDate'] = datePicker.toDate.jsdate
    this.pageSkip = 0
    this.pageLimit = this.selectedFilterValue
    this.nextPage("first");
  }
  onDateChanged(fromDate, toDate) {
    if (new Date(fromDate.jsdate) < new Date(toDate.jsdate)) {
      alert('todate should be greater than From date')
      this.datePicker.fromDate = ''
      this.datePicker.ToDate = ''
    }

  }
}
