import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../../node_modules/angular-web-storage';
import { ItemService } from '../../../_services/item.service';

@Component({
  selector: 'app-item-checkout-scanner',
  templateUrl: './item-checkout-scanner.component.html',
  styleUrls: ['./item-checkout-scanner.component.css'],
  providers: [ItemService]
})
export class ItemCheckoutScannerComponent implements OnInit {
  title= 'TapTrack Item Checkout Scanner';
  userData1 :any;
  companyId:any;
  userId:any;
  successMsg : any =false;
  errorMsg : any = false;
  itemUniquenumber: any;
  constructor(private titleService: Title, public local: LocalStorageService, private IS: ItemService) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.titleService.setTitle(this.title);
    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {      
      this.userData1 = JSON.parse(this.userData1);
      this.userId = this.userData1.userId;
       this.companyId = this.userData1.userDetails.companyId;
    }
  }
  itemCheckoutScanner(){
    let input = {};
        input = {
          "reciever": this.userId,
          "sender": this.userId,
          "nextTransactionId":"",
          "transStatusId": 1,
          "itemIds":[this.itemUniquenumber],
          "transMailFlag": 0,
          "deletedFlag": 0,
          "addedByAdminFlag": 0, 
          "transComment": "string",
          "companyId":this.companyId	 
        
        }
        this.IS.checkoutItemSubmt(input).subscribe((userData: any) => {      
            this.successMsg = true;
            this.errorMsg = false;
          this.itemUniquenumber = '' ;
        },    
        error =>{
          if(error.error.error.statusCode == "500"){
            this.errorMsg = true;
            this.successMsg = false;
            this.itemUniquenumber = '' ;
          }
        }
      );
      }
}
