import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { UserLocationService } from '../../app/_services/user.location.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { LoginService } from '../../app/_services/login.services';
@Component({
  selector: 'app-location-barcodes',
  templateUrl: './location-barcodes.component.html',
  styleUrls: ['./location-barcodes.component.css'],
  providers: [UserLocationService, LoginService]
})
export class LocationBarcodesComponent implements OnInit {
  uniqueId: any = 'Print All Barcodes';
  locations: any = []
  width = 3.7;
  width2 = 3.4;
  width3 = 3.1;
  width4 = 2.8;
  width5 = 2.6;
  width6 = 2.4;
  width7 = 2.4;
  width8 = 1.9;
  width9 = 1.7;
  width10 = 1.4;
  width1 = 0.7;
  printFlag: any = true;
  prntFlag: any = false;
  title = '';
  companyCity:any;
  constructor(private titleService: Title, private BS: UserLocationService, private route: ActivatedRoute,private router: Router,private LS: LoginService) { }
  /**ngOnInit method
     * @constructor
     * the method to call on component loading
     */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(paramss => {
      this.uniqueId = paramss.companyId;
      this.getLocationBarCode();
      this.getCompanyDetails(paramss)
    });
  }
  getCompanyDetails(x) {
    this.LS.getCompanyDetails({'uniqueId' :x.companyId}).subscribe(data=> {
      this.companyCity = data['companyCity'];

    });
  }
  /** getLocationBarCode method : will give locations based on input
   * @constructor
   * @param {Object} input- input for getLocationOrUserCode api
   * @param {Object} this.locations - output of getLocationOrUserCode
   */
  getLocationBarCode() {
    let input = {};
    input = {
      'uniqueId': this.uniqueId,
      'locationFlag': 1
    }
    /** getLocationOrUserCode api calling */
    this.BS.getLocationorUserCodes(input).subscribe(locationData => {
      if (locationData != null && locationData != undefined) {
        this.locations = locationData;
        this.calculateBarcodeWidth(this.locations)
        // _.each(this.locations,function (data) {
        //   // if(data.username.length > 6){
        //   // }
        // })
      }

    });
  }
  /** calculateBarcodeWidth method
   * it will calculate the barcodes length
   */
  calculateBarcodeWidth(data) {
    console.log(data)
    data.map((barcode, index) => {
      // if (barcode.userName.length === 1) {
      //   barcode.barcodeWidthFactor = 1 + ((200 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }else{
      //   barcode.barcodeWidthFactor = 1 + ((200 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }
      // if (barcode.userName.length === 1) {
      //   barcode.barcodeWidthFactor = 1 + ((200 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }else{
      //   barcode.barcodeWidthFactor = ((200 - 20))/((barcode.userName.length)*11)
      // }
      if (barcode.userName.length === 1) {
        barcode.barcodeWidthFactor = 1 + ((300 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      } else {
        barcode.barcodeWidthFactor = 1 + ((300 - 66 - (11 * (barcode.userName.length - 1))) / (46 + (11 * (barcode.userName.length - 1))))
      }
    })
  }
  
  back() {
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: this.uniqueId } });
  }

  printTable(id) {
    this.printFlag = false;
    this.prntFlag = true;
    // setTimeout(() => {
    //   var divToPrint = document.getElementById(id);
    //   // console.log('ddddddddddddddddddd ', divToPrint);
    //   var newWin = window.open("");
    //   newWin.document.write(divToPrint.innerHTML);

    //   newWin.document.write(`
    //   <html>
    //     <head>
    //       <title>Print Location Barcodes</title>
    //     </head>
    //   </html>`);
    //   newWin.document.close();
    //   newWin.focus();
    //   newWin.print();
    //   // newWin.close();


    // }
    // ) 
     var query = {
      itemUniqueId: this.uniqueId,
      // companyId : this.companyId
    }
    window.open(
      `#/locationBarcodePrint?itemUniqueId=${JSON.stringify(query)}`,
      "name",
      "width=1000,height=600"
    );
  }
}
