import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import * as _ from 'lodash';
import * as async from 'async';
import * as await from 'async';
import { Router } from "@angular/router";
import { ActivatedRoute } from '@angular/router';

import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
@Component({
  selector: 'app-items-reports-sub-componet',
  templateUrl: './items-reports-sub-componet.component.html',
  styleUrls: ['./items-reports-sub-componet.component.css'],
  providers: [LoginService]
})
export class ItemsReportsSubComponetComponent implements OnInit {
  userData: any;
  companyData: any;
  itemsreports:any=[]
  iteration:any=[]
  data:any= []
  page_size: any;
  page: any;
  pagination_numbers: any = {};
  recordsCount:any;
  itemTypeId:any
  constructor(private titleService: Title, private ss: supervisorService, private LS: LoginService, private routes: ActivatedRoute, private localStorage: LocalStorageService, private route: Router) {
    this.userData = this.localStorage.get("loginInfo");
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    // this.pagination_numbers.toNumber ;
    this.pagination_numbers.toNumber = this.page_size;
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
    }
  }

  ngOnInit() {
    const routeParams = this.routes.snapshot.params;
    this.itemTypeId = routeParams.id;
    if(this.itemTypeId ){
      let input = {
        "companyId": this.companyData.companyId, "itemTypeId": Number(this.itemTypeId ),
      }
      this.LS.getCountitemsreport(input).subscribe(listcount =>{
        this.recordsCount = listcount
        if(this.recordsCount){
          this.nextPage("first");
        }
        if(this.page_size>this.recordsCount){
          this.pagination_numbers.toNumber =this.recordsCount
        }
        this.pagination_numbers.totalNumber = this.recordsCount;
      })
    }
    // let input =
    //   {  "companyId": this.companyData.companyId, "itemTypeId": Number(this.itemTypeId ) ,skip: 0,
    //   limit: 7}
    //   this.LS.getitemsreportsarray(input).subscribe(usrData => {
    //     this.itemsreports =usrData[0];
    //     this.data=this.itemsreports.itemArrList[0];
    //   },
     
    // );
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.getItemList(this.page, this.page_size);
    this.page = 0;
    this.nextPage("first");
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
      if (this.pagination_numbers.toNumber == this.pagination_numbers.totalNumber)
      return
    else
    this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.getItemList(this.page, this.page_size);
  }
  getItemList(page, page_size){
    let input =
    {  "companyId": this.companyData.companyId, "itemTypeId": Number(this.itemTypeId ) , skip: page * page_size,
    limit: page_size}
    this.LS.getitemsreportsarray(input).subscribe(usrData => {
          this.itemsreports =usrData[0];
        this.data=this.itemsreports.itemArrList[0];
    },
   
  );
  }
  routeto(id){
this.route.navigate(['itemsReportaudit/'+id]);
  }
}
