import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'app-item-htmlreport',
  templateUrl: './item-htmlreport.component.html',
  styleUrls: ['./item-htmlreport.component.css']
})
export class ItemHtmlreportComponent implements OnInit {
  reportsArry:any=[]
  avgDaysTracking=0
  reportsArryerror:any;
  constructor(private US: UserService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getData({query: params.query})
    });
  }
  getData(data){
    this.US.getfilterreports(JSON.parse( data.query)).subscribe(usrData => {
      this.reportsArry = usrData;
      console.log( this.reportsArry)
      var sumOfDays=_.sumBy(this.reportsArry.closeList,'daysinTracking')
      this.avgDaysTracking=(sumOfDays/this.reportsArry.totalClosed)
    },error=>{
      this.reportsArryerror = 'Something went wrong!'
      // console.log(this.reportsArryerror)
      // console.log(JSON.stringify(error.error.error.message))
    });
  }
}
