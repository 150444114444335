import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppURL } from '../../apiUrl';
import { map } from 'rxjs/operators';
@Injectable()
export class LoginService {
  private messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource.asObservable();
  constructor(public http: HttpClient) { }

  login(data) {
    return this.http.post(AppURL.serverUrl + 'login', data).pipe(map((res: Response) => res));
  }

  forgotPassword(data) {
    return this.http.post(AppURL.serverUrl + 'Users/forgotPassword', data).pipe(map((res: Response) => res));
  }
  GetReportsData(data) {
    return this.http.post(AppURL.serverUrl+'Transactions/GetTrancationsReports', data).pipe(map((res: Response) => res));
  }
  getmailreports(data) {
    return this.http.post(AppURL.serverUrl+'ItemTypes/mailRoomReport', data).pipe(map((res: Response) => res));
  }
  getscarchData(data) {
    // return this.http.get(AppURL.serverUrl + '/Users');
    return this.http.post(AppURL.serverUrl+ 'UserTypes/getscarchusers', data).pipe(map((res: Response) => res));

  }
  getCompanies() {
    return this.http.get(AppURL.serverUrl + 'Companies');
  }
  getuserTypes() {
    return this.http.get(AppURL.serverUrl + 'UserTypes');
  }
  // getitemtypesconut() {
  //   return this.http.get(AppURL.serverUrl + 'ItemTypes');
  // }
  getSupervisiors() {
    return this.http.get(AppURL.serverUrl + '/Users');
  }
  getUsersList(input) {
    // return this.http.get(AppURL.serverUrl + '/Users');
    return this.http.post(AppURL.serverUrl+ 'UserTypes/getusers', input).pipe(map((res: Response) => res));

  }
  getitemsreportsarray(input) {
    // return this.http.get(AppURL.serverUrl + '/Users');

    return this.http.post(AppURL.serverUrl+ 'Items/itemsReport', input).pipe(map((res: Response) => res));

  }
  getauditreports(input) {
    // return this.http.get(AppURL.serverUrl + '/Users');

    return this.http.post(AppURL.serverUrl+ 'Transactions/getauditreports', input).pipe(map((res: Response) => res));

  }
  getCountitemsreport(input) {
    // return this.http.get(AppURL.serverUrl + '/Users');
    return this.http.post(AppURL.serverUrl+ 'Items/itemsReportconut', input).pipe(map((res: Response) => res));

  }
  getCountitemsauditsreport(input) {
    // return this.http.get(AppURL.serverUrl + '/Users');
    return this.http.post(AppURL.serverUrl+ 'Transactions/getCountitemsauditsreport', input).pipe(map((res: Response) => res));

  }
  updateFields(data) {
    return this.http.patch(AppURL.serverUrl+ 'Users/' + data.id, data).pipe(map((res: Response) => res));
  }
  getusersCount(input) {
    return this.http.post(AppURL.serverUrl+ 'UserTypes/getusersCount', input).pipe(map((res: Response) => res));
  }
  getitemtypesconut(input) {
    return this.http.post(AppURL.serverUrl+ 'ItemTypes/itemTypeCount', input).pipe(map((res: Response) => res));
  }

  getitemTypeReport(input) {
    return this.http.post(AppURL.serverUrl+ 'ItemTypes/itemTypeReport ', input).pipe(map((res: Response) => res));
  }
  validateUserName(data) {
    return this.http.post(AppURL.serverUrl + 'Users/getUserName', data).pipe(map((res: Response) => res));
  }
  validateUniqueId(data) {
    return this.http.post(AppURL.serverUrl + 'Items/findUniqueItems', data).pipe(map((res: Response) => res));
  }
  saveDataofEasy(data) {
    return this.http.post(AppURL.serverUrl + 'Transactions/createTransaction', data).pipe(map((res: Response) => res));
  }
  getCompanyDetails(data) {
    return this.http.post(AppURL.serverUrl + 'Companies/getCompanyDetails', data).pipe(map((res: Response) => res));
  }
  getBinderProcessed(data) {
    return this.http.post(AppURL.serverUrl + 'Transactions/bindersProcessed', data).pipe(map((res: Response) => res));
  }

  register(data) {
    return this.http.post(AppURL.serverUrl + 'register', data).pipe(map((res: Response) => res));
  }
  emailOTP(data) {
    return this.http.post(AppURL.serverUrl + 'sendEmail', data).pipe(map((res: Response) => res));
  }
  getUserInfo(data) {
    return this.http.post(AppURL.serverUrl + 'Users/getUserInfor', data).pipe(map((res: Response) => res));
  }
  requestSms(data) {
    return this.http.post(AppURL.serverUrl + 'requestSms', data).pipe(map((res: Response) => res));
  }

  verify(data) {
    return this.http.post(AppURL.serverUrl + 'verify', data).pipe(map((res: Response) => res));
  }
}
