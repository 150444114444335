import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../../_services/login.services';
import { NgxSpinnerService } from '../../../../../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-html-report-mail',
  templateUrl: './html-report-mail.component.html',
  styleUrls: ['./html-report-mail.component.css'],
  providers: [LoginService, UserService]
})
export class HtmlReportMailComponent implements OnInit {
  Mailreports:any=[]
  Mailreportserror:any;
  fromDate : any;
  toDate : any;
  constructor(private US: UserService,private spinner: NgxSpinnerService, private route: ActivatedRoute,private Login: LoginService) { }

  ngOnInit() {
    
    this.route.queryParams.subscribe(params => {
      let input = {};
      input = params.Query;
      this.getData(input)
    });
  }
  getData(x){
    this.spinner.show();
    let mailInput = {};
    mailInput = x
    var myobj = JSON.parse(x)
    this.US.getmailreports(myobj).subscribe(reportData => {
      // console.log(myobj);
      this.fromDate = myobj.fromDate;
      this.toDate = myobj.toDate;
      this.Mailreports = reportData;
      if(this.Mailreports.length >0){        
        this.Mailreports = reportData;
      }
      
      this.spinner.hide();
    },
    error =>{
      this.Mailreportserror = error.error.error.message
      console.log(this.Mailreportserror)
      this.spinner.hide();
    }
  );
  }
}
