import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ItemService } from "../../../_services/item.service";
import { UserService } from "../../../_services/user.service";
import * as _ from 'lodash';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import { ActivatedRoute } from '@angular/router';
import { ExcelService } from '../../../_services/excel.service';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import { error } from '@angular/compiler/src/util';
import { IMyDpOptions } from 'mydatepicker';
@Component({
  selector: 'app-items-report',
  templateUrl: './items-report.component.html',
  styleUrls: ['./items-report.component.css'],
  providers: [ItemService, UserService]

})
export class ItemsReportComponent implements OnInit {
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm/dd/yyyy',
  };
  title = 'TapTrack Items Report';
  Query: any = {};
  userData: any = []
  companyData: any = []
  itemList: any = [];
  reqErr: any = false;
  ItemNumber: any
  itemId: any
  data: any;
  Valid: any;
  error2: any = false;
  error3: any = false;
  error: any = false
  constructor(private titleService: Title, private IS: ItemService, private route: ActivatedRoute,
    private excelService: ExcelService,
    private US: UserService,
    private localStorage: LocalStorageService,
    private router: Router, ) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
    }
  }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    let input = {};
    input = {
      companyId: this.companyData.companyId
    };
    this.IS.getItemType({ companyId: this.companyData.companyId }).subscribe(itemList => {
      this.itemList = itemList;
    });
  }
  onDateChanged(fromDate, toDate) {
    if (fromDate && toDate){
      if (new Date(fromDate.jsdate) < new Date(toDate.jsdate) || new Date(toDate.jsdate) >new Date(fromDate.jsdate)) {
        alert('todate should be greater than From date')
        this.Query.fromDate = ''
        this.Query.toDate = ''
        // this.Query = {};
      }
    }
  }
  generatehtml(ItemNumber, itemId) {
    let fromDate;
    let Todate;
    if (this.Query.FromDate && this.Query.ToDate) {
      let year = this.Query.FromDate.jsdate.getFullYear()
      let month = this.Query.FromDate.jsdate.getMonth() + 1
      let date = this.Query.FromDate.jsdate.getDate()
      let year1 = this.Query.ToDate.jsdate.getFullYear()
      let month1 = this.Query.ToDate.jsdate.getMonth() + 1
      let date1 = this.Query.ToDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      fromDate = year + '-' + month + '-' + date
       Todate = year1 + '-' + month1 + '-' + date1
    }
    if ((ItemNumber && itemId) && ItemNumber != undefined && itemId != undefined) {
      let input = {
        companyId: this.companyData.companyId,
        itemUniqueId: ItemNumber,
        itemTypeId: itemId
      }
      this.US.validate(input).subscribe(count => {
        this.Valid = count
        if (this.Valid > 0) {
          this.error2 = false
          this.error = false
          this.error3 = false

          var query = {
            companyId: this.companyData.companyId,
            itemUniqueId: ItemNumber,
            itemTypeId: itemId
          }
          window.open(`#/hrmlReportitemsreport?query=${JSON.stringify(query)}`, 'name', 'width=800,height=600,scrollbars=1');
        } else {
          this.error2 = true
          this.error = false
          this.error3 = false
          return
        }
      })
    }
    else if (ItemNumber) {

      this.US.findexistance({ itemUniqueId: ItemNumber, companyId: this.companyData.companyId }).subscribe(usrData => {
        this.data = usrData
        if (this.data > 0) {
          this.error = false

          var query = {
            companyId: this.companyData.companyId,
            itemUniqueId: ItemNumber,
            itemTypeId: itemId
          }
          window.open(`#/hrmlReportitemsreport?query=${JSON.stringify(query)}`, 'name', 'width=800,height=600,scrollbars=1');
        } else {
          this.error = true
          this.error2 = false
          this.error3 = false
        }
      })
    } else if (itemId && fromDate && Todate) {
      var query = {
        companyId: this.companyData.companyId,
        itemTypeId: itemId,
        'fromDate' : fromDate,
        'toDate' : Todate
      }
      window.open(`#/hrmlReportitemsreport?query=${JSON.stringify(query)}`, 'name', 'width=800,height=600,scrollbars=1');
    } else {
      alert('Please Select Fields')
    }

  }
  clearfields() {
    this.ItemNumber = ''
    this.itemId = ''
    this.error = false
    this.error2 = false
    this.error3 = false
    this.reqErr = false
    this.Query.FromDate = "";
    this.Query.ToDate = "";
    // this.Query = {};
    // console.log('00000000000000000000000000000 ', this.Query);
  }

  itmList: any = [];
  getPdf() {
    let itemInput = {};
    let input = {}
    let fromDate;
    let Todate;
    if (this.Query.FromDate && this.Query.ToDate) {
      let year = this.Query.FromDate.jsdate.getFullYear()
      let month = this.Query.FromDate.jsdate.getMonth() + 1
      let date = this.Query.FromDate.jsdate.getDate()
      let year1 = this.Query.ToDate.jsdate.getFullYear()
      let month1 = this.Query.ToDate.jsdate.getMonth() + 1
      let date1 = this.Query.ToDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      fromDate = year + '-' + month + '-' + date
       Todate = year1 + '-' + month1 + '-' + date1
    }
    if (this.ItemNumber && (this.itemId == undefined || this.itemId == '')) {
      this.US.findexistance({ itemUniqueId: this.ItemNumber, companyId: this.companyData.companyId }).subscribe(usrData => {
        this.data = usrData
        if (this.data > 0) {
          this.error2 = false
          this.error3 = false
          input = {
            companyId: this.companyData.companyId,
            itemUniqueId: this.ItemNumber,
            itemTypeId: this.itemId
          }
          this.getResults(input);
        } else {
          this.error = true
          this.error2 = false
          this.error3 = false

        }
      })
    } else
      if ((this.ItemNumber && this.itemId) && this.ItemNumber != undefined && this.itemId != undefined) {

        input = {
          companyId: this.companyData.companyId,
          itemUniqueId: this.ItemNumber,
          itemTypeId: this.itemId
        }
        this.US.validate(input).subscribe(count => {
          this.Valid = count
          if (this.Valid > 0) {
            this.error2 = false;
            this.error3 = false;
            this.getResults(input);
          } else {
            this.error = false
            this.error2 = true
            this.error3 = false
          }

        });
      } else if (this.itemId && fromDate && Todate) {
        console.log(this.itemId)
        console.log(this.ItemNumber)
        if (this.ItemNumber) {
          input = {
            companyId: this.companyData.companyId,
            itemUniqueId: this.ItemNumber,
          }
          this.getResults(input);

        } else if (this.itemId) {
          input = {
            companyId: this.companyData.companyId,
            itemTypeId: this.itemId,
            'fromDate' : fromDate,
            'toDate' : Todate
          }
          this.getResults(input);
        }

      } else {
        this.error = false;
        this.reqErr = true;
        this.error2 = false;
        this.error3 = false;
      }


  }

  itemReport: any = [];
  usrData1Pdf : any = []
  getResults(input) {
    this.US.getitemsreportsarray(input).subscribe(usrData => {
      var daysHeldArr = [];
      this.itemReport = []
      if (usrData != null && usrData != undefined) {
        this.error = false;
        this.error2 = false;
        this.error3 = false;
        this.usrData1Pdf = usrData;
        this.itmList = usrData[0];
        if (this.usrData1Pdf.length > 0) {
        for (var i = 0; i < this.itmList.itemArrList.length; i++) {
          let objj = {};
          objj = this.itmList.itemArrList[i];
          let date = new Date(this.itmList.itemArrList[i].dateCreated);
          let firtsname = this.itmList.itemArrList[i].itemHolderDetails ? this.itmList.itemArrList[i].itemHolderDetails.userFirstName : ''
          let lastname = this.itmList.itemArrList[i].itemHolderDetails ? this.itmList.itemArrList[i].itemHolderDetails.userLastName : ''
          let users =  this.itmList.itemArrList[i].itemHolderDetails ? this.itmList.itemArrList[i].itemHolderDetails.username : ''
          let month = (date.getMonth() + 1);
          let datee = date.getDate();
          objj['holderName'] = firtsname + ' ' + lastname + '(' + users + ')'
          objj['dateCreated'] = date.getFullYear() + '-' + ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) + ' ' + ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours()) + ':' + ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()): date.getMinutes()); // + ':' + date.getSeconds() + '.' + date.getMilliseconds();
          this.itemReport.push(objj);

        }
        const doc = new jsPDF();
        // doc.autoTable({ html: '#my-table' });
        doc.setFontSize(10);
        // doc.setFontStyle('arial');
        // doc.text(11, 12, "Items Report");
        // doc.text(17, 18, "Items Count =       " + this.itmList.length);
        doc.text(20, 35, "Item Type :       " + this.itmList.itemArrList[0].itemType);
        doc.text(140, 35, "Item Total   :    " + this.itmList.noOfItems);
        doc.autoTable({
          styles: { width: 60, border: 2, halign: 'center' },
          columnStyles: { text: { cellWidth: 'auto', border: 2 } }, // European countries centered
          body: this.itemReport,
          columns: [{ header: 'Item Number', dataKey: 'itemNumber' }, { header: 'Date Created', dataKey: 'dateCreated' }, { header: 'Tracking Item', dataKey: 'trackingItem' }, { header: 'Item Holder', dataKey: 'holderName' }, { header: 'Days In Tracking', dataKey: 'daysinTracking' }],
          margin: { top: 40, bottom: 20 },
          tableLineWidth: 0.5,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            fontSize: 10,
            textColor: [25, 25, 25],
            theme: "plane",
            marginBottom: 20,
            lineWidth: 0.5,
            marginTop: 20,
            border: 4,
            // rowHeight: 6,
            lineColor: 200
          },
          beforePageContent: function (data) {
            doc.setFontSize(20), doc.setFontStyle("bold"), doc.text("Items Report", 75, 20);
          }
        });
        doc.save('ItemsReport.pdf');
      } else {
        this.error = false;
        this.error2 = false;
        this.error3 = true;
      }
    } else {
      this.error = true;
      this.error2 = false;
      this.error3 = false;
    }
    });
  }

  itmListEx: any = [];
  generateExcel() {
    let fromDate;
    let Todate;
    let itemInput = {};
    let input = {}
    if (this.Query.FromDate && this.Query.ToDate) {
      let year = this.Query.FromDate.jsdate.getFullYear()
      let month = this.Query.FromDate.jsdate.getMonth() + 1
      let date = this.Query.FromDate.jsdate.getDate()
      let year1 = this.Query.ToDate.jsdate.getFullYear()
      let month1 = this.Query.ToDate.jsdate.getMonth() + 1
      let date1 = this.Query.ToDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      fromDate = year + '-' + month + '-' + date
       Todate = year1 + '-' + month1 + '-' + date1
    }
    if (this.ItemNumber && (this.itemId == undefined || this.itemId == '')) {
      this.US.findexistance({ itemUniqueId: this.ItemNumber, companyId: this.companyData.companyId }).subscribe(usrData => {
        this.data = usrData
        if (this.data > 0) {
          this.error2 = false
          this.error3 = false
          input = {
            companyId: this.companyData.companyId,
            itemUniqueId: this.ItemNumber,
            itemTypeId: this.itemId
          }
          this.getExResult(input);
        } else {
          this.error = true
          this.error2 = false
          this.error3 = false

        }
      })
    } else
      if ((this.ItemNumber && this.itemId) && this.ItemNumber != undefined && this.itemId != undefined) {

        input = {
          companyId: this.companyData.companyId,
          itemUniqueId: this.ItemNumber,
          itemTypeId: this.itemId
        }
        this.US.validate(input).subscribe(count => {
          this.Valid = count
          if (this.Valid > 0) {
            this.error2 = false;
            this.error3 = false
            this.getExResult(input);
          } else {
            this.error = false
            this.error2 = true
            this.error3 = false
          }

        });
      // } else if (this.ItemNumber || this.itemId) {
      } else if (this.itemId && fromDate && Todate) {
        console.log(this.itemId)
        console.log(this.ItemNumber)
        if (this.ItemNumber) {
          input = {
            companyId: this.companyData.companyId,
            itemUniqueId: this.ItemNumber
           
          }
          this.getExResult(input);

        } else if (this.itemId) {
          input = {
            companyId: this.companyData.companyId,
            itemTypeId: this.itemId,
            'fromDate' : fromDate,
            'toDate' : Todate
          }
          this.getExResult(input);
        }

      } else {
        this.error = false;
        this.reqErr = true;
        this.error2 = false;
        this.error3 = false
      }


  }

  itemReportEx: any = [];
  usrData1 : any = []
    getExResult(input) {
    this.US.getitemsreportsarray(input).subscribe(usrData => {
      var daysHeldArr = [];
      this.itemReportEx = []
      if (usrData != null && usrData != undefined) {
        this.error = false;
        this.error2 = false;
        this.error3 = false
        this.itmListEx = [];
        this.usrData1 = usrData;
        this.itmListEx = usrData[0];
        
        if (this.usrData1.length > 0) {
        for (var i = 0; i < this.itmListEx.itemArrList.length; i++) {
          let objj = {};
          objj = this.itmListEx.itemArrList[i];
          let date = (this.itmListEx.itemArrList[i].dateCreated!=0)? new Date(this.itmListEx.itemArrList[i].dateCreated) : null;
          // console.log('00000000000000000000000000 ', date,this.itmListEx.itemArrList[i].dateCreated);
          let firtsname = this.itmListEx.itemArrList[i].itemHolderDetails ? this.itmListEx.itemArrList[i].itemHolderDetails.userFirstName : ''
          let lastname = this.itmListEx.itemArrList[i].itemHolderDetails ? this.itmListEx.itemArrList[i].itemHolderDetails.userLastName : ''
          let users =  this.itmListEx.itemArrList[i].itemHolderDetails ? this.itmListEx.itemArrList[i].itemHolderDetails.username : ''
          let month = (date) ? (date.getMonth() + 1) : null;
          let datee = (date) ? date.getDate() : null;
          objj['holderName'] = firtsname + ' ' + lastname + '(' + users + ')'
          objj['dateCreated'] = (date) ? date.getFullYear() + '-' + ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) + ' ' + ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours()) + ':' + ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()): date.getMinutes()) : null; // + ':' + date.getSeconds() + '.' + date.getMilliseconds();
          this.itemReportEx.push(objj);
        }
        var title = 'Items Report';
        // 'Tag Name',
        var header = ['Item Number','Date Created','Tracking Item','Item Holder','Days In Tracking'];
        var fileName = 'ItemsReport';
        var reportName = 'Items Report';
        var subTitleRow = 'Item Type :       '+this.itmListEx.itemArrList[0].itemType;
        var itemTotal = 'Item Total   :    ' + this.itmListEx.noOfItems;
        const data1 = [] = this.itemReportEx;
        const data = [];
        let data2 = [];
        var self = this
        _.each(data1, function (obj) {
          data2=[];
          data2.push(obj.itemNumber)
          // data2.push(obj.tagName)
          data2.push(obj.dateCreated)
          data2.push(obj.trackingItem)
          data2.push(obj.holderName)
          data2.push(obj.daysinTracking)
          data.push(data2)
        })
        let excelObj = {};
           excelObj = {
             'response' : data,
             'reportName' : reportName,
             'title' : title,
             'header' : header,
             'fileName' : fileName,
             'subTitleRow' : subTitleRow,
             'itemTotal' : itemTotal
           }
           this.excelService.generateExcel(excelObj);
          } else {
            this.error = false;
            this.error2 = false;
            this.error3 = true;
          }
      } else {
        this.error = true;
        this.error2 = false;
        this.error3 = false
      }
    });
  }
}
