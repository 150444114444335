import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../../../node_modules/angular-web-storage';
import { ItemService } from '../../../_services/item.service';

@Component({
  selector: 'app-print-barcode',
  templateUrl: './print-barcode.component.html',
  styleUrls: ['./print-barcode.component.css'],
  providers: [ItemService]
})
export class PrintBarcodeComponent implements OnInit {
  title = 'TapTrack Print Barcode'
  constructor(private titleService: Title, private IS:ItemService,private router:Router, public local: LocalStorageService) { }
  width1 = 2.0;
  width = 2.9;
  
  printcode:any;
  printbarfield: any= false;
  errrMsg: any=false;
  userData1:any;
  userId:any;
  companyId:any;
  printData:any ={};
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {      
      this.userData1 = JSON.parse(this.userData1);
      this.userId = this.userData1.userId;
      this.companyId = this.userData1.userDetails.companyId;
    }
  }
  printbarcode(id){
    if(this.printcode == null  || this.printcode == ''){
      this.printbarfield = true;
      this.errrMsg = false;
      return;
    }
    else {      
    let input = {};
    input = {      
      "itemUniqueId": this.printcode,
      "userId": this.userId,
      "companyId": this.companyId
    }
    this.IS.printBar1(input).subscribe(data => {
      this.printData = data;
      this.printbarfield = false;
      this.errrMsg = false;
      setTimeout(() => {
        var divToPrint = document.getElementById(id);
        var newWin = window.open("");
        newWin.document.write(divToPrint.innerHTML);
        
        newWin.document.write(`
        <html>
          <head>
            <title>Print All Barcodes</title>
          </head>
        </html>`);
        newWin.document.close();
        newWin.focus();
        newWin.print();
        //newWin.close();
    
    
      })
    },
    error =>{
      if(error.error.error.statusCode == "500"){
        this.errrMsg = true;
        this.printbarfield = false;
        this.printcode = '';
        this.printData = {};
      }
    }   
  );
  }
  this.printcode = '';  
  }
}
