
import { Injectable, Injector } from '@angular/core';
import {
HttpRequest,
HttpHandler,
HttpEvent,
HttpInterceptor,
HttpHeaders,
HttpResponse,
HttpErrorResponse
} from '@angular/common/http';
// import { CookieService } from 'angular2-cookie/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
    KEY = 'loginInfo';
// constructor(private cookieService: CookieService, public local: LocalStorageService,public session: SessionStorageService) {
// }
intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

/**
* Will get the current user data
*/
// const loginData: any = this.cookieService.getObject('LoginResponse');
// const loginData: any = this.local.get(this.KEY);
// if (loginData) {
// request = request.clone({headers: request.headers.set('yitsol-auth', JSON.parse(loginData).jwtToken)
// // .set('yitsol-auth', loginData.jwtToken.toString()) // x-sc-auth
// });
// }
return next.handle(request);
}
}
