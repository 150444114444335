import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../_services/user.service";
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import * as _ from 'lodash';
import { ExcelService } from '../../../_services/excel.service';
@Component({
  selector: 'app-user-tracking-report',
  templateUrl: './user-tracking-report.component.html',
  styleUrls: ['./user-tracking-report.component.css']
})
export class UserTrackingReportComponent implements OnInit {
  title = 'TapTrack User Tracking Filter Report';
  userArr1 :any;
  userId :any;
  item:any={}
  input:any={}
  reportsArry :any;
  error: any = false;
  showHtmlReport = false;
  userArr:any=[]
  userArrIds:any=[]
  error1: any = false;
  userData: any = {};
  companyData: any = {};
  constructor(private excelService: ExcelService,private titleService : Title,private US: UserService, private localStorage: LocalStorageService, private router: Router) { 
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }

  }

  ngOnInit() {
    
    this.titleService.setTitle(this.title);
    // this.US.getUsers().subscribe(usrData => {
    //   this.userArr1 = [];
    //   this.userArr1 = usrData;
    // });
    this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArrIds = []
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")"
          ;
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);
      }
    });
  }
  GetuseTrackingreport(id){
    this.input={
      userId:id
    }
    this.US.getusertrackingreports(this.input).subscribe(usrData => {
      this.reportsArry = [];
      this.reportsArry = usrData;
    });
  }
  itemreciever(items){
    this.userId =JSON.stringify(this.retnum(items))
    
      }
       retnum(str) { 
        var num = str.replace(/[^0-9]/g, ''); 
        return parseInt(num,10); 
    }
  getItemList() {
    if (this.userId == null || this.userId == '') {
      // alert('Please select user')
      this.error = true;
      this.error1 = false;

    }else{
      this.error = false;
      this.error1 = false;
    this.showHtmlReport = true;
    let input = {
      "userId": this.userArrIds[this.userArr.indexOf(this.item.reciever)]
    };
  this.userId =this.userArrIds[this.userArr.indexOf(this.item.reciever)]
     window.open(`#/hrmlReporttracking?userId=${this.userId}`, 'name', 'width=850,height=600,scrollbars=1');
    this.error = false;
  }
  }
  clearfields(){
    this.item.reciever = ''
  this.error1 = false;
  this.error = false;


  }
  itmList: any = [];
  trackingArr: any = [];
  usrData1 : any = [];
  usrId: any;
  avgDays:any;
  count:any = 0;
  getPdf() {
    let itemInput = {};
    if (this.item.reciever!=null && this.item.reciever!=undefined && this.item.reciever!='') {
      this.error = false;
      this.error1 = false;
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrData1 = usrData;
        let x = this.item.reciever.split("(")
        let y = x[1].split(")");
        this.usrId =  this.usrData1.find(getID);
        function getID(obj) {
          if (obj.username ==y[0]) {
            return obj.id
          }
        }
        let input = {}
        input = {'userId' : this.usrId.id}
      this.US.getusertrackingreports(input).subscribe(usrData => {
          var daysHeldArr = []
          this.trackingArr = [];
          if (usrData != null  && usrData != undefined) {
            this.error = false;
            this.error1 = false;
            this.itmList = usrData;
            this.count = 0;
            for(var i=0; i<this.itmList.length;i++) {
              let obj = {} = this.itmList[i];
              let date = new Date(this.itmList[i].transactionTime);
              this.count = (this.itmList[i].daysHeld + this.count);
              obj['daysHeld'] = parseInt(this.itmList[i].daysHeld);
              let month = (date.getMonth() + 1);
              let datee = date.getDate();
              // obj['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ date.getHours() +':'+ date.getMinutes(); //+':'+date.getSeconds() + '.' + date.getMilliseconds()
              obj['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes());
              daysHeldArr.push(this.itmList[i].daysHeld)
              this.trackingArr.push(obj)
            }
            // let original = daysHeldArr.reduce((a, b) => a + b, 0)/(this.trackingArr.length);
            // let results = Math.round(original*100)/100;
            this.count = (this.count )/ (this.trackingArr.length);
            this.count = this.count.toFixed();
            // this.avgDays = results.toFixed();
            // console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrr ', this.count);
            const doc = new jsPDF();
            // doc.autoTable({ html: '#my-table' });
            //  { header: 'Tag Name', dataKey: 'tagName' },
            doc.setFontSize(10);
            // doc.setFontStyle('arial');
            doc.text(20, 25, "Name      :    " + this.usrId.userFirstName + ' '+ this.usrId.userLastName);
            doc.text(20, 35, "Username  :     " + this.usrId.username);
            // doc.line(0,0,0,0);
            doc.text(120, 25, "Avg. Days With Item  :     " + this.count);
            doc.text(120, 35, "Number of Items      :    " + this.trackingArr.length);
            doc.autoTable({
              styles: { width: 60, border: 2, halign: "center", },
              columnStyles: { text: { cellWidth: "auto", border: 2 } }, // European countries centered
              body: this.trackingArr,
              columns: [{ header: 'Item Number', dataKey: 'itemUniqueId' }, { header: 'Date Received', dataKey: 'transactionTime' }, { header: 'Last Held By', dataKey: 'lastHeld' }, { header: 'Days Held', dataKey: 'daysHeld' }],
              margin: { top: 40, bottom: 20 },
              tableLineWidth: 0.5,
              theme: "grid",
              headStyles: {
                fillColor: [255, 255, 255],
                fontSize: 10,
                textColor: [25, 25, 25],
                theme: "plane",
                marginBottom: 20,
                lineWidth: 0.5,
                marginTop: 20,
                border: 4,
                // rowHeight: 6,
                lineColor: 200
              },
              beforePageContent: function(data) {
                doc.setFontSize(20),doc.setFontStyle("bold"), doc.text("User Tracking Filter Report", 55, 10);
              }
            });
            doc.save('UserTrackingReport.pdf');
          } else {
            this.error = false;
            this.error1 = true;
          }
        });
        
       });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  
  }


  itmexList: any = [];
  trackingexArr: any = [];
  usrexData1 : any = [];
  usrIdex: any;
  countEx:any = 0;
  generateExcel() {
    let itemInput = {};
    if (this.item.reciever!=null && this.item.reciever!=undefined && this.item.reciever!='') {
      this.error = false;
      this.error1 = false;
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrexData1 = usrData;
        let x = this.item.reciever.split("(")
        let y = x[1].split(")");
        this.usrIdex =  this.usrexData1.find(getID);
        function getID(obj) {
          if (obj.username ==y[0]) {
            return obj.id
          }
        }
        let input = {}
        input = {'userId' : this.usrIdex.id}
      this.US.getusertrackingreports(input).subscribe(usrData => {
          var daysHeldArr = []
          this.trackingexArr = [];
          if (usrData != null  && usrData != undefined) {
            this.error = false;
            this.error1 = false;
            this.itmexList = usrData;
            this.countEx = 0;
            for(var i=0; i<this.itmexList.length;i++) {
              let obj = {} = this.itmexList[i];
              let date = new Date(this.itmexList[i].transactionTime);
              this.countEx = (this.itmexList[i].daysHeld + this.countEx);
              obj['daysHeld'] = parseInt(this.itmexList[i].daysHeld);
              let month = (date.getMonth() + 1);
              let datee = date.getDate();
              // obj['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +' '+ date.getHours() +':'+ date.getMinutes();
              obj['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes());
              daysHeldArr.push(obj['daysHeld'])
              this.trackingexArr.push(obj)
              
            }
            let original = daysHeldArr.reduce((a, b) => a + b, 0)/(this.trackingexArr.length);
            let results = Math.round(original*100)/100
            this.countEx = (this.countEx )/ (this.trackingexArr.length);
            this.countEx = this.countEx.toFixed();
            var title = 'User Tracking Filter Report';
            // 'Tag Name',
            var header = ['Item Number','Date Received','Last Held By','Days Held'];
            var fileName = 'UserTrackingReport';
            var reportName = 'User Tracking Filter Report';
            var subTitleRow = 'Name           :    '+this.usrIdex.userFirstName + ' '+ this.usrIdex.userLastName;  //+ ' (' + this.usrIdex.username + ' )';
            var userNmaeSub = 'Username  :    ' + this.usrIdex.username;
            var avgDays = 'Avg. Days With Item  :     ' + this.countEx;
            var nbItems = 'Number of Items         :    '+this.trackingexArr.length;
            const data1 = [] = this.itmexList;
            const data = [];
            let data2 = [];
            var self = this
            _.each(data1, function (obj) {
              data2=[];
              data2.push(obj.itemUniqueId)
              // data2.push(obj.tagName)
              data2.push(obj.transactionTime)
              data2.push(obj.lastHeld)
              data2.push(obj.daysHeld)
              data.push(data2)
            })
            let excelObj = {};
           excelObj = {
             'response' : data,
             'reportName' : reportName,
             'title' : title,
             'header' : header,
             'fileName' : fileName,
             'subTitleRow' : subTitleRow,
             'userNmaeSub' : userNmaeSub,
             'avgDays' : avgDays,
             'nbItems' : nbItems
           }
           this.excelService.generateExcel(excelObj);
           this.countEx = 0;
          } else {
            this.error = false;
            this.error1 = true;
          }
        });
        
       });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  
  }
}
