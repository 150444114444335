import { Title } from '@angular/platform-browser';
import { Component, OnInit,  } from '@angular/core';
import { supervisorService } from '../../../../_services/supervisor.service';
import { LoginService } from '../../../../_services/login.services';
import { LocalStorageService } from '../../../../../../node_modules/angular-web-storage';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.css'],
  providers: [LoginService]
})
export class AddMessageComponent implements OnInit {
  messageinput:any;
  messageDescptn :any;
  messageName:any = false;
  messagediscr:any = false;
  userData1:any;
  userId:any;
  loginForm:any ={};
  title = 'TapTrack Add Message'
  userData: any = {};
  companyData: any = {};
  constructor(private titleService:Title, private ss : supervisorService,private router: Router, private LS: LoginService, public local: LocalStorageService) { 

    this.userData = this.local.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }
  }
  
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.LS.getCompanies().subscribe(
      data => {
        this.loginForm.companyId = data[0].companyId
      },
      error => {

      }
    )

    this.userData1 = this.local.get("loginInfo");
    if (this.userData1) {      
      this.userData1 = JSON.parse(this.userData1);
      this.userId = this.userData1.userId;
    }
  }
  
  createNewMeassage(){
    if(this.messageinput == null  || this.messageinput == '' && this.messageDescptn == null || this.messageDescptn== ''){
      this.messageName = true;
      this.messagediscr = true;
      return;
    }else if(this.messageinput == null  || this.messageinput == ''){
      this.messageName = true;
      this.messagediscr = false;
      return;
    }else if(this.messageDescptn == null || this.messageDescptn== ''){
      this.messageName = false;
      this.messagediscr = true;
      return;
    }
    else {      
    let input = {};
    input = {
      "messageName": this.messageinput,
      "messageDesc": this.messageDescptn,
      "messageType": "",
      "companyId": this.companyData.companyId,
      "userId": this.userId,
      "superAdminId": 1
    
    }
    this.ss.messageCreate(input).subscribe(data => {
      if("data.statusMessage == 'Success'"){
        this.router.navigate(['/infoMessage'])
      }
    }
   
  );
  }
  }
}
