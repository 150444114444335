import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../_services/login.services';
import { NgxSpinnerService } from '../../../../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-html-binders-processed-report',
  templateUrl: './html-binders-processed-report.component.html',
  styleUrls: ['./html-binders-processed-report.component.css'],
  providers: [LoginService]
})
export class HtmlBindersProcessedReportComponent implements OnInit {
  binderReports:any=[];
  binderReportsErr:any;
  output : any = [];
  bindersLen : any;
  dateRange : any = {};
  constructor(private route: ActivatedRoute,private Login: LoginService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getData({Query: params.Query})
     });
  }

  getData(Query){
    this.dateRange = JSON.parse(Query.Query);
    this.spinner.show();
      this.Login.getBinderProcessed(JSON.parse(Query.Query)).subscribe(reportData => {
        this.binderReports = reportData;
        
        if(this.binderReports.length >0){
          this.spinner.hide();
          this.binderReports = reportData;
          this.bindersLen = this.binderReports[0].bindersLength;
        }
      },
      /** error to handle */
      error=>{
        this.binderReportsErr = error.error.error.message
        console.log(this.binderReportsErr)
        this.spinner.hide();
      }
    );
    }
    

}
