import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { UserLocationService } from '../../../app/_services/user.location.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-command-barcode-print',
  templateUrl: './command-barcode-print.component.html',
  styleUrls: ['./command-barcode-print.component.css'],
  providers: [UserLocationService]
})
export class CommandBarcodePrintComponent implements OnInit {
  uniqueId: any ;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(paramss => {
      this.uniqueId = paramss.itemUniqueId;
      window.print();
    });
  }

}
