import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-checkout-scanner',
  templateUrl: './help-checkout-scanner.component.html',
  styleUrls: ['./help-checkout-scanner.component.css']
})
export class HelpCheckoutScannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
