import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '../../../node_modules/@angular/router';
declare var $;
import * as _ from "lodash";

@Component({
  selector: 'app-print-bar-codes',
  templateUrl: './print-bar-codes.component.html',
  styleUrls: ['./print-bar-codes.component.css']
})
export class PrintBarCodesComponent implements OnInit {
  title = 'Barcode Management';
  companyId: any;
  constructor(private titleService: Title,private route: ActivatedRoute, private router: Router) {
    // $(document).ready(function () {
    //   $('input').keyup(function () {
    //     if ($(this).val().length == $(this).attr("maxlength") || ($(this).val().indexOf("-") >= 0 && $(this).val().length == +$(this).attr("maxlength") + 1)) {
    //       var i = $('input').index(this);
    //       $('input').eq(i + 1).focus();
    //     }
    //   });
    // });

  }
  width: any;
  width2: any;

  code: any;
  /**ngOnInit method
   * @constructor
   * the method to call on component loading
   */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(params => {
      this.companyId = params.companyId;
      // console.log('cccccccccccccccccccccccccccccccccccc ', this.companyId);
    });

    for (var i = 0; i < 20; i++) {
      this.codes.push({ value: '' })
    }
  }
  // $(function() {
  //   $('[autofocus]:not(:focus)').eq(0).focus();
  // });
  /** insertDash Method : 
   * @constructor
   * it will insert the dash after 3 digits of entered uniqueId
   */
  insertDash(code) {

    $(document).ready(function () {
      $('input').keyup(function () {
        if ($(this).val().length == $(this).attr("maxlength") || ($(this).val().indexOf("-") >= 0 && $(this).val().length == +$(this).attr("maxlength") + 1)) {
          var i = $('input').index(this);
          $('input').eq(i + 1).focus();
        }
      });
    });
    var patt = /(\d{3}).*(\d{7})/;
    var donepatt = /^(\d{3})-(\d{7})$/;
    var str = code.value;
    var result;
    if (!str.match(donepatt)) {
      result = str.match(patt);
      if (result != null) {
        code.valueWithoutDash = code.value
        code.value = code.value.replace(/[^\d]/gi, '');
        str = result[1] + '-' + result[2];
        code.value = str;
        // code.valueWithoutDash = code.value;
      }
      else {
        if (code.value.match(/[^\d]/gi))
          code.value = code.value.replace(/[^\d]/gi, '');
        code.valueWithoutDash = code.value;

      }
    }




    // if (code.value.length === 10 && code.value.indexOf("-") === -1) {
    //   code.value = `${code.value.substring(0, 3)}-${code.value.substring(3, 10)}`;
    //   code.valueWithoutDash = code.value;
    //   if (code.value.length == 10) {
    //     // $('[autofocus]:not(:focus)').eq(0).focus();
    //     $('input').focus()
    //     // document.getElementById('autofocusId').focus();
    //   }
    // }
    // else {
    //   code.value = `${code.value.substring(0, code.value.indexOf("-"))}${code.value.substring(code.value.indexOf("-") + 1, code.value.length)}`;
    //   code.valueWithoutDash = code.value;
    //   if (code.value.length == 10) {
    //     // $('[autofocus]:not(:focus)').eq(0).focus();
    //     $('input').focus()
    //     // document.getElementById('autofocusId').focus();
    //   }
    // }
  }
  back() {
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: this.companyId } });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  codes: any = [];
  codeArr: any = []
  /** printTable method : 
   * @constructor
   * this method print the given list of uniqueId's 
   */
  printTable(id) {
    //this.codes = [123, 456, 789];

    this.codeArr = this.codes;
    this.codes = [];

    for (var i = 0; i < this.codeArr.length; i++) {

      if (this.codeArr[i].value && this.codeArr[i].valueWithoutDash) {
        this.codes.push(this.codeArr[i]);
      }

      if (i == 19) {
        var len = 20 - this.codes.length
        for (var j = 0; j < len; j++) {
          var obj = { "value": "", "valueWithoutDash": "" }
          this.codes.push(obj);

        }
      }
    }
    this.codes.map((barcode, index) => {
      if (barcode.value && barcode.value != '') {
        if (barcode.value.length === 1) {
          this.width = 1 + ((300 - (66) - (11 * (barcode.value.length - 1))) / 46)
          console.log(this.width)

        } else {
          this.width = 1 + ((300 - 66 - (11 * (barcode.value.length - 1))) / (46 + (11 * (barcode.value.length - 1))))
          console.log(this.width)

        }
      }
      if (barcode.valueWithoutDash && barcode.valueWithoutDash != '') {
        if (barcode.valueWithoutDash.length === 1) {
          this.width2 = 1 + ((300 - (66) - (11 * (barcode.valueWithoutDash.length - 1))) / 46)
          console.log(this.width2)

        } else {
          this.width2 = 1 + ((300 - 66 - (11 * (barcode.valueWithoutDash.length - 1))) / (46 + (11 * (barcode.valueWithoutDash.length - 1))))
          console.log(this.width2)

        }
      }
      // if (barcode.value.length === 1) {
      //   barcode.barcodeWidthFactor = 1 + ((300 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      // }else{
      //   barcode.barcodeWidthFactor = 1+((300-66-(11*(barcode.userName.length-1)))/(46+(11*(barcode.userName.length-1))))
      // }
    })
    setTimeout(() => {
      var divToPrint = document.getElementById(id);
      var newWin = window.open("");
      newWin.document.write(divToPrint.innerHTML);

      newWin.document.write(`
      <html>
        <head>
          <title>Print All Barcodes</title>
        </head>
      </html>`);
      newWin.document.close();
      newWin.focus();
      newWin.print();
      // newWin.close();


    }
    )
  }
  //On enter page should display the print barcodes page
  handleKeyDown(event, printId) {
    if (event.keyCode == 13) {
      this.printTable(printId)
    }
  }

  reset() {
    this.codes = [];
    for (var i = 0; i < 20; i++) {
      this.codes.push({ value: '' })
    }
    setTimeout(() => {
      $('input').keyup(function () {
        if ($(this).val().length == $(this).attr("maxlength") || ($(this).val().indexOf("-") >= 0 && $(this).val().length == +$(this).attr("maxlength") + 1)) {
          var i = $('input').index(this);
          $('input').eq(i + 1).focus();
        }
      });
    });
  }

}
