import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from "@angular/router";
import { AppURL } from "../../../../../../apiUrl";

@Component({
  selector: 'app-print-uniqiebarcodes',
  templateUrl: './print-uniqiebarcodes.component.html',
  styleUrls: ['./print-uniqiebarcodes.component.css']
})
export class PrintUniqiebarcodesComponent implements OnInit {
  data:any;
  irriate:any;
  width= 1.5
  parsedUrl:any;
  baseUrl:any;
  // show:any=true
  constructor(private US: UserService,private spinner : NgxSpinnerService, private routes: ActivatedRoute, private router: Router) {
    // this.parsedUrl = new URL(window.location.href);
    // this.baseUrl = this.parsedUrl.origin;
   }

  ngOnInit() {
    const routeParams = this.routes.snapshot.params;
    this.data =JSON.parse( routeParams. navigationExtras);
    this.getData(this.data)
  }
  getData(data){
    this.US.getdatausingtagid(data).subscribe(usrData => {
      this.irriate = usrData;
    });
  }
  Print_all() {
    window.print()
}
print(id): void {
  window.open( AppURL.uiUrl+"/#/viewBarcode?itemUniqId=" + id,"mywindow", "width=350,height=250");
  
}
}
