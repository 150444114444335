import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
@Component({
  selector: 'app-items-reporthtml',
  templateUrl: './items-reporthtml.component.html',
  styleUrls: ['./items-reporthtml.component.css']
})
export class ItemsReporthtmlComponent implements OnInit {
  reportsArry:any=[]
  reportsArryerror:any;
  constructor(private US: UserService, private spinner : NgxSpinnerService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getData({query: params.query})
    });
  }
  getData(data){
    this.spinner.show();
    this.US.getitemsreportsarray(JSON.parse(data.query)).subscribe(usrData => {
      this.reportsArry = usrData[0];
      this.spinner.hide();
    },
    error=>{
      this.reportsArryerror = error.error.error.message
      console.log(this.reportsArryerror)
      this.spinner.hide();
    }
  );
  }
}
