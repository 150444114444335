import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { ItemService } from "../../../_services/item.service";
import { UserService } from "../../../_services/user.service";
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import * as _ from 'lodash';
import {
  Router,
  ActivatedRoute
} from "../../../../../node_modules/@angular/router";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import { LoginService } from '../../../_services/login.services';
import { ExcelService } from '../../../_services/excel.service';
// import * as jspdf from 'jspdf';

// import html2canvas from 'html2canvas';
@Component({
  selector: "app-item-inventory-report",
  templateUrl: "./item-inventory-report.component.html",
  styleUrls: ["./item-inventory-report.component.css"],
  providers: [ItemService, UserService, LoginService]
})
export class ItemInventoryReportComponent implements OnInit {
  data_temp: any;
  itemNumber: any;
  error: any = false;
  error1: any = false;
  pdfRep: any = false;
  title = "TapTrack Item Inventory ";
  userData: any = {};
  companyData: any = {};
  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private IS: ItemService,
    private US: UserService,
    private LS: LoginService,
    private localStorage: LocalStorageService,
    private excelService: ExcelService
  ) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }
  }
  uniqueId: any = "";
  itemInvetordata: any = {};
  companyId : any;
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(params => {
      this.uniqueId = params.companyId;
      this.LS.getCompanyDetails({ 'uniqueId': params.companyId }).subscribe(data => {
        this.companyId = this.companyData.companyId;
    });
    });
  }

  clear() {
    this.itemInvetordata.itemUniqueId = "";
    this.error = false;
    this.error1 = false;
  }
  itemUniqueId: any;
  itemInvetordataArr: any = [];
  showHtmlReport = false;
  getItemList() {
    this.error1 = false;
    if (
      this.itemInvetordata.itemUniqueId == null ||
      this.itemInvetordata.itemUniqueId == ""
    ) {
      this.error = true;
    } else {
      this.showHtmlReport = true;
      let input = {
        itemUniqueId: this.itemInvetordata.itemUniqueId
      };
      this.US.findexistance({
        itemUniqueId: this.itemInvetordata.itemUniqueId,
        companyId : this.companyId
      }).subscribe(usrData => {
        this.data_temp = usrData;
        if (this.data_temp > 0) {
          var query = {
            itemUniqueId: this.itemInvetordata.itemUniqueId,
            companyId : this.companyId
          }
          window.open(
            `#/hrmlReport?itemUniqueId=${JSON.stringify(query)}`,
            "name",
            "width=1000,height=600, scrollbars=1"
          );
          this.error = false;
          this.error1 = false;
        } else {
          this.error1 = true;
        }
      });
    }
  }



  getPdf() {
    if (this.itemInvetordata.itemUniqueId) {
      var itmList = [];
      this.IS.getItemInvertory({
        itemUniqueId: this.itemInvetordata.itemUniqueId,
        companyId : this.companyId
      }).subscribe((itemList: any) => {
        if (itemList != null && itemList != "" && itemList != undefined) {
          this.error = false;
          this.error1 = false;
          itmList = itemList;
          const doc = new jsPDF();
          // doc.autoTable({ html: '#my-table' });
          doc.setFontSize(10);
          // doc.setFontStyle("Verdana");
          // doc.text(20, 30, "Item Inventory" );
          doc.text(20, 35, "Item count =       " + itmList.length);
          doc.autoTable({
            styles: { width: 60, border: 2, halign: "center" },
            columnStyles: { text: { cellWidth: "auto", border: 2 } },
            body: itmList,
            columns: [
              { header: "Item", dataKey: "itemUniqueId" },
              { header: "Sender", dataKey: "senderName" },
              { header: "Sender To Date", dataKey: "transactionSuccessTime" },
              { header: "Holder", dataKey: "recieverName" }
            ],
            margin: { top: 40, bottom: 20 },
            tableLineWidth: 0.5,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              fontSize: 10,
              textColor: [25, 25, 25],
              theme: "plane",
              marginBottom: 20,
              lineWidth: 0.5,
              marginTop: 20,
              border: 4,
              // rowHeight: 6,
              lineColor: 200
            },
            beforePageContent: function(data) {
              doc.setFontSize(20),doc.setFontType("bold"), doc.text("Item Inventory", 75, 20);
            }
          });
          doc.save("ItemInventory.pdf");
        } else {
          this.error1 = true;
          this.error = false;
        }
      });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  }
  afzal: any;
  pdfReport() {
    // this.IS.pdfGenerator().subscribe(data => {
    //   this.afzal = data;
    const blob = new Blob([this.afzal], { type: "application/pdf" });
    //   const link = document.createElement('a')
    //   link.href = window.URL.createObjectURL(blob)
    //   link.download = `your-file-name.pdf`
    //   link.click()
    //   // this.closeModal()
    // })

    // window.open(`http://localhost:3011/pdf`,'width=1000,height=600');
  }

  public captureScreen() {
    var data = document.getElementById("contentToConvert");
    // html2canvas(data).then(canvas => {
    //   // Few necessary setting options
    //   var imgWidth = 208;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;

    //   const contentDataURL = canvas.toDataURL('image/png')
    //   let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   //  pdf.save('MYPdf.pdf'); // Generated PDF
    //   // pdf.setProperties({
    //   //   title: "MYPdf.pdf"
    //   // });
    //   // pdf.output('dataurlnewwindow');

    //   let dataSrc = pdf.output("datauristring");
    //   // let win = window.open("", "myWindow");
    //   let win = window.open('MYPdf.pdf', 'name', 'scrollbar=1,resizable=1, scrollbars=yes, height=650, width=950');
    //   win.document.write("<html><head><title>jsPDF</title></head><body><embed style = 'scrollbar=1,resizable=1, scrollbars=yes, height=650, width=950' src=" +
    //     dataSrc + "></embed></body></html>");

    //   //  window.open('MYPdf.pdf','name','scrollbar=1,resizable=1, scrollbars=yes, height=650, width=950');
    // });
  }
  generateExcel() {
    this.error1 = false;
    if (
      this.itemInvetordata.itemUniqueId == null ||
      this.itemInvetordata.itemUniqueId == ""
    ) {
      this.error = true;
    } else {
      this.showHtmlReport = true;
      let input = {
        itemUniqueId: this.itemInvetordata.itemUniqueId
      };
      this.US.findexistance({
        itemUniqueId: this.itemInvetordata.itemUniqueId,
        companyId : this.companyId
      }).subscribe(usrData => {
        this.data_temp = usrData;
        if (this.data_temp > 0) {
          var query = {
            itemUniqueId: this.itemInvetordata.itemUniqueId,
            companyId : this.companyId
          }
          var itmList = [];
          /** binderInventoryReport api calling */
          this.IS.getItemInvertory({
            itemUniqueId: this.itemInvetordata.itemUniqueId,
            companyId : this.companyId
          }).subscribe((itemList: any) => {
            if (itemList != null && itemList != "" && itemList != undefined) {
              this.error = false;
              this.error1 = false;
              /** itmList is an array which stores response */
              itmList = itemList;
              var title = 'Item Inventory';
              var header = ['Item', 'Sender', 'Sender To Date', 'Holder'];
              var subTitleRow = 'Items Count =    '+itmList.length
              var fileName = 'ItemInventoryReport';
              var reportName = 'Item Inventory Report';
              const data1 = [] = itmList;
              const data = [];
              let data2 = [];
              var self = this
              _.each(data1, function (obj) {
                data2=[];
                data2.push(obj.itemUniqueId)
                data2.push(obj.senderName)
                data2.push(obj.transactionSuccessTime)
                data2.push(obj.recieverName)
                data.push(data2)
              })
              let excelObj = {};
              excelObj = {
                'response' : data,
                'reportName' : reportName,
                'title' : title,
                'header' : header,
                'subTitleRow' : subTitleRow,
                'fileName' : fileName
              }
              // this.excelService.generateExcel(data, reportName,title, header, subTitleRow, fileName);
              this.excelService.generateExcel(excelObj);
            } else {
              this.error1 = true;
              this.error = false;
            }
          });
          this.error = false;
          this.error1 = false;
        } else {
          this.error1 = true;
          this.error = false;
        }
      });
    }
  }
}
