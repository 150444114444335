import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemService } from '../../_services/item.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-html-report-inventory',
  templateUrl: './html-report-inventory.component.html',
  styleUrls: ['./html-report-inventory.component.css'],
  providers: [ItemService]
})
export class HtmlReportInventoryComponent implements OnInit {
  itemInvetordataerror:any;
  constructor(
    private route: ActivatedRoute,
    private IS: ItemService,
    private spinner: NgxSpinnerService
  ) { }
  itemInvetordata:any =[];
   /**ngOnInit method
   * @constructor
   * the method to call on component loading
   */
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // console.log('00000000000000000000000 ', JSON.stringify(params.itemUniqueId));
        this.getData(params.itemUniqueId)
      });
  }
  /** getData method : 
   * @constructor
   * @param {Object} input - input for binder inventory report
   */
  getData(data){
    this.spinner.show();
    let input = JSON.parse(data)
    // console.log('dddddddddddddddd ', input['itemUniqueId'], typeof(input));
    /** calling of binder inventory report */
    this.IS.getItemInvertory(input).subscribe((itemList: any) => {
      if (itemList.length > 0) {
       /** itemInvetordata stores the reponse of binder inventory report */
        this.itemInvetordata = itemList;
        
      }
      this.spinner.hide();
    },
    /** error to handle */
    error=>{
      this.itemInvetordataerror = error.error.error.message
      console.log(this.itemInvetordataerror)
      this.spinner.hide();
    }
  );
  }

 

}
