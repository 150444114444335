import { HelpChangeItemStatusComponent } from './tutorials/help-change-item-status/help-change-item-status.component';
import { AddprofileUrlComponent } from './addprofile-url/addprofile-url.component';
import { BarcodeManagementComponent } from './barcode-management/barcode-management.component';
import { HelpSupervisorGridComponent } from './tutorials/help-supervisor-grid/help-supervisor-grid.component';
import { HelpRegisterCompanyComponent } from './tutorials/help-register-company/help-register-company.component';
import { HelpPrintBarCodesComponent } from './tutorials/help-print-bar-codes/help-print-bar-codes.component';
import { HelpPendingUsersComponent } from './tutorials/help-pending-users/help-pending-users.component';
import { HelpMessagesComponent } from './tutorials/help-messages/help-messages.component';
import { HelpCheckoutScannerComponent } from './tutorials/help-checkout-scanner/help-checkout-scanner.component';
import { HelpAddItemTypeComponent } from './tutorials/help-add-item-type/help-add-item-type.component';
import { InfoInviteUserComponent } from './home-page/supervisor/invite-users/info-invite-user/info-invite-user.component';
import { HelpAddItemComponent } from '../app/tutorials/help-add-item/help-add-item.component';
import { ItemSearchReportComponent } from './home-page/reports/item-search-report/item-search-report.component';
import { ItemClosedFilterReportComponent } from './home-page/reports/item-closed-filter-report/item-closed-filter-report.component';
import { UserTrackingReportComponent } from './home-page/reports/user-tracking-report/user-tracking-report.component';
import { ItemInventoryReportComponent } from './home-page/reports/item-inventory-report/item-inventory-report.component';
import { MessagesComponent } from './home-page/supervisor/messages/messages.component';
import { ItemsReportsSubComponetComponent } from './home-page/itemsreport-subComponent/items-reports-sub-componet/items-reports-sub-componet.component';
import { ItemAuditreportComponent } from './home-page/item-tracking-audit/item-auditreport/item-auditreport.component';

import { PendingUsersComponent } from './home-page/supervisor/pending-users/pending-users.component';
import { MydashboardComponent } from './home-page/mydashboard/mydashboard.component';
import { HtmlReportInventoryComponent } from './item-inventor/html-report-inventory/html-report-inventory.component';
import { HtmlReportTrackingComponent } from './home-page/reports/user-tracking-report/html-report-tracking/html-report-tracking/html-report-tracking.component';
import { HtmlReportSentbyComponent } from './home-page/reports/items-send-by-user-report/html-report-sentby/html-report-sentby/html-report-sentby.component';
import { ItemHtmlreportComponent } from './home-page/reports/item-closed-filter-report/item-html-report/item-htmlreport/item-htmlreport.component';
import { ItemReporthtmlComponent } from './home-page/reports/item-search-report/item-report-html/item-reporthtml/item-reporthtml.component';

import { HtmlmailreportComponent } from './home-page/reports/mail-room-report/htmlreportmail/htmlmailreport/htmlmailreport.component';
import { HtmlReportMailComponent } from './home-page/reports/mail-room-report/html-report-mail/html-report-mail/html-report-mail.component';
import { TimeHeldHtmlreportComponent } from './home-page/reports/time-held-report/time-held-report/time-held-htmlreport/time-held-htmlreport.component';
import { UserActivityHtmlreportComponent } from './home-page/reports/user-activity-report/user-activity-html/user-activity-htmlreport/user-activity-htmlreport.component';
import { ItemsReporthtmlComponent } from './home-page/reports/items-report/items-report-html/items-reporthtml/items-reporthtml.component';

import { EditProfileComponent } from './home-page/user/edit-profile/edit-profile.component';
import { AddProfileComponent } from './home-page/user/add-profile/add-profile.component';
import { ItemInventorComponent } from './item-inventor/item-inventor.component';
import { ChangeItemStatusComponent } from './home-page/item/change-item-status/change-item-status.component';
import { AssignToUserComponent } from './home-page/item/assign-to-user/assign-to-user.component';
import { ItemCloseScannerComponent } from './home-page/item/item-close-scanner/item-close-scanner.component';
import { ItemCheckoutScannerComponent } from './home-page/item/item-checkout-scanner/item-checkout-scanner.component';
import { AddPackageItemComponent } from './home-page/item/add-package-item/add-package-item.component';
import { AddItemComponent } from './home-page/item/add-item/add-item.component';
import { CommandBarcodesComponent } from './command-barcodes/command-barcodes.component';
import { UserBarcodesComponent } from './user-barcodes/user-barcodes.component';
import { LocationBarcodesComponent } from './location-barcodes/location-barcodes.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { PrintBarCodesComponent } from './print-bar-codes/print-bar-codes.component';
import { EasyScanningComponent } from './easy-scanning/easy-scanning.component';
import { SuperAdminLoginComponent } from './super-admin-login/super-admin-login.component';
import { HomeMainComponent } from './home-page/home-main/home-main.component';
import { AddItemTypeComponent } from './home-page/item/add-item-type/add-item-type.component';
import { PrintBarcodeComponent } from './home-page/item/print-barcode/print-barcode.component';
import { ItemListComponent } from './home-page/item/item-list/item-list.component';
import { ItemCheckoutComponent } from './home-page/item/item-checkout/item-checkout.component';
import { ItemCloseComponent } from './home-page/item/item-close/item-close.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { SupervisorsGridComponent } from './home-page/supervisor/supervisors-grid/supervisors-grid.component';
import { InviteUsersComponent } from './home-page/supervisor/invite-users/invite-users.component';
import { AddMessageComponent } from './home-page/supervisor/messages/add-message/add-message.component';
import { MailRoomReportComponent } from './home-page/reports/mail-room-report/mail-room-report.component';
import { UserActivityReportComponent } from './home-page/reports/user-activity-report/user-activity-report.component';
import { ItemsReportComponent } from './home-page/reports/items-report/items-report.component';
import { TimeHeldReportComponent } from './home-page/reports/time-held-report/time-held-report.component';
import { ItemsSendByUserReportComponent } from './home-page/reports/items-send-by-user-report/items-send-by-user-report.component';
import { ItemTypeReportComponent } from './home-page/reports/item-type-report/item-type-report.component';
import { InfoMessageComponent } from './home-page/supervisor/messages/add-message/info-message/info-message.component';
import { InfoForgotComponent } from './forgot/info-forgot/info-forgot.component';
import { ViewBarcodeComponent } from './view-barcode/view-barcode.component';
import { EditItemListtagComponent } from './home-page/item/item-list/Edit-Itemlist/edit-item-listtag/edit-item-listtag.component';
import { PrintUniqiebarcodesComponent } from './home-page/item/add-item/items-barcodes/print-uniqiebarcodes/print-uniqiebarcodes.component';

import { ModuleWithProviders } from '@angular/core';
import { PdfReportComponent } from './home-page/reports/mail-room-report/pdf-report/pdf-report.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { BindersProcessedReportComponent } from './home-page/reports/binders-processed-report/binders-processed-report.component';
import { HtmlBindersProcessedReportComponent } from './home-page/reports/binders-processed-report/html-binders-processed-report/html-binders-processed-report.component';
import { LocationPrintComponent } from './location-barcodes/location-print/location-print.component';
import { UserBarcodePrintComponent } from './user-barcodes/user-barcode-print/user-barcode-print.component';
import { CommandBarcodePrintComponent } from './command-barcodes/command-barcode-print/command-barcode-print.component';
import { ItemsHeldByUserReportComponent } from './items-held-by-user-report/items-held-by-user-report.component';
import { ItemHeldHtmlComponent } from './items-held-by-user-report/item-held-html/item-held-html.component';
const routes: Routes = [
  {path: 'login', component: LoginComponent,  data: { title: 'Login' }},
  {path: 'forgotPassword', component: ForgotComponent,  data: { title: 'Forgot Password' } },
  {path:'infoForgot', component: InfoForgotComponent},
  {path: 'superAdminLogin', component: SuperAdminLoginComponent },
  {path: 'barCodeManagement', component: BarcodeManagementComponent},
  {path:'itemInventor', component: ItemInventorComponent},
  {path:'hrmlReport', component: HtmlReportInventoryComponent},
  {path:'hrmlReporttracking', component: HtmlReportTrackingComponent},
  {path:'hrmlReportSentby', component: HtmlReportSentbyComponent},
  {path:'htmlReportitemHeld', component: ItemHeldHtmlComponent},
  {path : 'htmlBindersProcessedReport', component:HtmlBindersProcessedReportComponent},
  {path:'hrmlReporttimeheld', component: TimeHeldHtmlreportComponent},
  {path:'hrmlReportsearch', component: ItemReporthtmlComponent},
  {path:'hrmlReportitemsreport', component: ItemsReporthtmlComponent},
  {path: 'inviteUserRegistration', component:AddprofileUrlComponent},
  {path:'hrmlReportactivity', component: UserActivityHtmlreportComponent},
  {path:'hrmlReportmailss', component: HtmlReportMailComponent},
  {path:'hrmlReportmailssclosed', component: ItemHtmlreportComponent},
  {path: 'printBarCodes', component:PrintBarCodesComponent},
  {path : 'printPdf', component: PdfReportComponent},
  {path : 'viewBarcode', component: ViewBarcodeComponent},
  {path: '', component: HomePageComponent, 
  children: [
    {path: 'home', component: HomeMainComponent},
  { path: 'itemsReportsub/:id', component: ItemsReportsSubComponetComponent },
  { path: 'itemsReportaudit/:id', component: ItemAuditreportComponent },

    {path: 'addItemType', component: AddItemTypeComponent},
    {path: 'addItem', component: AddItemComponent},
    {path: 'addPackageItem', component: AddPackageItemComponent},
    {path:'itemList', component: ItemListComponent},
    {path:'itemListTag/:id', component: EditItemListtagComponent},
    {path:'PrintUniqiebarcodes/:navigationExtras', component: PrintUniqiebarcodesComponent},

    {path: 'itemCheckoutScanner', component: ItemCheckoutScannerComponent},
    {path: 'itemCloseScanner', component: ItemCloseScannerComponent},
    {path: 'itemCheckout', component: ItemCheckoutComponent},
    {path: 'itemClose', component: ItemCloseComponent},
    {path:'changeItemStatus', component: ChangeItemStatusComponent},
    {path: 'barcodePrinter', component: PrintBarcodeComponent},
    {path: 'assignToUser', component: AssignToUserComponent},
    {path:'addUser', component: AddProfileComponent},
    {path: 'editUser', component: EditProfileComponent},
    {path:'myDashboard', component : MydashboardComponent},
    {path:'pendingUsers', component : PendingUsersComponent},
    {path:'supervisorGrid', component: SupervisorsGridComponent},
    {path:'inviteUser', component: InviteUsersComponent },
    {path: 'infoInivite', component: InfoInviteUserComponent},
    {path:'messages', component: MessagesComponent},
    {path:'addmessage', component: AddMessageComponent},
    {path: 'infoMessage', component : InfoMessageComponent},
    {path:'itemTypeReport', component:ItemTypeReportComponent},
    {path:'mailRoomReport', component: MailRoomReportComponent},
    {path: 'itemInventoryReport', component: ItemInventoryReportComponent},
    {path:'userActivityReport', component : UserActivityReportComponent},
    {path:'userTrackingReport', component: UserTrackingReportComponent},
    {path: 'itemsReport', component:ItemsReportComponent},
    {path: 'itemClosedFilterReport', component: ItemClosedFilterReportComponent},
    {path:'itemSearchReport', component:ItemSearchReportComponent},
    {path:'timeHeldReport', component: TimeHeldReportComponent},
    {path: 'itemsSentByUserReport', component:ItemsSendByUserReportComponent},
    {path: 'itemsHeldByUserReport', component:ItemsHeldByUserReportComponent},
    {path : 'bindersProcessedReport', component:BindersProcessedReportComponent}
    

  ]},
  {path: 'locationBarcodes', component: LocationBarcodesComponent},
  {path: 'locationBarcodePrint', component: LocationPrintComponent},
  {path: 'userBarcodes', component: UserBarcodesComponent},
  {path: 'userBarcodePrint', component: UserBarcodePrintComponent},

  {path: 'tapTrackEasyScanning', component: EasyScanningComponent},
  {path: 'commandBarcodes', component: CommandBarcodesComponent},  
  {path: 'commandBarcodePrint', component: CommandBarcodePrintComponent},  
  {path:'glossary', component: GlossaryComponent},
  {path: 'helpAddItem', component: HelpAddItemComponent},
  {path: 'helpAddItemType', component: HelpAddItemTypeComponent},
  {path: 'helpCheckoutScanner', component: HelpCheckoutScannerComponent},
  {path: 'helpMessages', component: HelpMessagesComponent},
  {path: 'helpPendingUsers', component: HelpPendingUsersComponent},
  {path: 'helpPrintbarCodes', component: HelpPrintBarCodesComponent},
  {path: 'helpRegisterCompany', component: HelpRegisterCompanyComponent},
  {path: 'helpSupervisorGrid', component: HelpSupervisorGridComponent},
  {path: 'helpChangeItemStatus', component: HelpChangeItemStatusComponent},
  {path: 'errorPage', component: ErrorPageComponent},

  { path: '*', redirectTo: 'login', pathMatch: 'full' }, 
];

// @NgModule({
  // imports: [RouterModule.forRoot(routes)],
  // exports: [RouterModule]
// })
// export class AppRoutingModule { }
export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
