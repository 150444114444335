import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsreport from "jsreport-browser-client-dist/jsreport";
import * as jsPDF from "jspdf";

@Component({
  selector: 'app-view-barcode',
  templateUrl: './view-barcode.component.html',
  styleUrls: ['./view-barcode.component.css']
})
export class ViewBarcodeComponent implements OnInit {

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.uniqueId = params.itemUniqId;
    })
  }
  uniqueId: any = ''
  ngOnInit() {
  }
  printBarcode(){
    window.print();
  }
  closeWindow(){
    window.close();
  }
}
