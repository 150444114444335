import { Component, OnInit } from '@angular/core';
declare var $; 
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';
import { CookieService } from 'angular2-cookie/core';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
    userData : any = {};
    usersData : any = {};
    companyData : any = {};
  constructor(public local: LocalStorageService, private router: Router, private cookieService: CookieService) {
    $('.dropdown11').hover(function() {
      $(this).addClass('open');
    }, function() {
      $(this).removeClass('open');
    });

   this.userData = this.local.get("loginInfo");
  if (this.userData) {
    this.userData = JSON.parse(this.userData);
    this.usersData = this.userData['userDetails'];
     this.companyData = this.userData['userDetails']['companyIdRelations'];
  } else {
    this.router.navigate(['/login'])
  }
   
   }

  ngOnInit() {
    
  }
 glossary(){
  window.open('#/glossary','name','scrollbar=1,resizable=1, scrollbars=yes,width=1000,height=600');
 }
 userbarcode(){
  // window.open(`/hrmlReporttracking?userId=${this.userId}`, 'name', 'width=850,height=600,scrollbars=1');

  window.open(`#/userBarcodes?uniqueId=${this.companyData.uniqueId}`,'name','scrollbar=1,resizable=1, scrollbars=yes, height=650, width=950');
 }
 logout() {
  this.cookieService.removeAll();
  this.local.remove('loginInfo');
  this.local.clear();
  localStorage.clear();
  // localStorage.removeItem('loginInfo');
  this.router.navigate(['/login'])

 }
 addItem(){
  window.open(`#/helpAddItem`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 addItemType(){
  window.open(`#/helpAddItemType`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 checkoutScanner(){
  window.open(`#/helpCheckoutScanner`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 message(){
  window.open(`#/helpMessages`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 pendingUser(){
  window.open(`#/helpPendingUsers`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 printbarcode(){
  window.open(`#/helpPrintbarCodes`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 registercompany(){
  window.open(`#/helpRegisterCompany`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 supervisorGrid(){
  window.open(`#/helpSupervisorGrid`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
 changeItemstatus(){
  window.open(`#/helpChangeItemStatus`, 'name', 'scrollbar=1,resizable=1, scrollbars=yes,width=750,height=550');
 }
}
