import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../_services/profile.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Router } from '@angular/router';
import { Title } from '../../../../../node_modules/@angular/platform-browser';
import {LoginService} from '../../../_services/login.services';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  providers: [ProfileService,LoginService]
})
export class EditProfileComponent implements OnInit {
  title ='TapTrack Edit Profile';
  userData: any = {};
  companyData: any = {};
  getUserData: any = [];
  type : any = {};
  companyName : any = '';
  getStateData : any = [];
  profileData : any = [];
  userType : any;
  successfulSave : boolean = false;
  successfulSave1 : boolean = true;
  usernameshw:any = false;
  locationshw:any = false;
  passwordshw:any = false;
  confirmPasswordshw :any = false;
  userFirstNameshw: any = false;
  userLastNameshw:any = false;
  UserStreet1shw:any = false;
  userCityshw:any = false;
  userZipshw:any = false;
  userMobileshw:any =false;
  emailshw :any = false;
  locationFlagshw:any =false;
  stateIdshw :any = false;
  userTypeIdshw :any = false;
  mailWrng:any = false;
  passwrdmatch:any =false;
  mobileFlag: boolean = false;
  mblFlag : boolean = false;
  lanFlag : boolean = false;
  landLineshw: any = false;
  mobileErr : boolean = false;

  constructor(private PS: ProfileService,private LS:LoginService, private titleService:Title, private localStorage: LocalStorageService, private router: Router) { 
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      
      this.userData = JSON.parse(this.userData);
      this.type = this.userData['userDetails'];
      this.companyData = this.userData['userDetails']['companyIdRelations'];
      this.companyName = this.companyData.companyName;
      this.type['companyName'] = this.companyName;
      this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
      this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
      
      if(this.type['emailFlag'] == 1) {
        this.mobileF = true;
      } else {
        this.emailF = true;
      }
    } else {
      this.router.navigate(['/login']);
    }
  }
  emailvalidation = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.getStates();
    this.getUserypes();
  }
  getStates() {
    this.PS.getState().subscribe(getStateData => {
      this.getStateData = getStateData;
    });
  }
  getUserypes() {
    this.PS.getUserType().subscribe(getUserData => {
      this.getUserData = getUserData;
      for(let i=0; i<this.getUserData.length;i++) {
        if (this.getUserData[i].userTypeId == this.userData['userDetails'].userTypeId) {
          this.type['userType'] = this.getUserData[i].userType;
        }
      }
      // this.type.userTypeId = this.getUserData.userType;
    });
  }
  cancelProfile() {
    this.router.navigate(['/home']);
  }
  errorFormFieldsArr = [];
  userProfileFormFields = [
    "username", "locationFlag", "password", "confirmPassword", "userFirstName", "userLastName",
    "UserStreet1", "userCity", "userZip", "userMobile", "email", "stateId", "userTypeId",
  ]
  editProfile() {

    this.errorFormFieldsArr = [];
    this.userProfileFormFields.forEach((item) => {
      if (item == 'locationFlag') {
        if (this.type['locationFlag'] == undefined || this.type['locationFlag'] == '' || this.type['locationFlag'] == '') {
          this.locationFlagshw = false;
        }
        if (this.type['locationFlag']) {
          if (this.type['location']) {
            this.locationFlagshw = false;
          } else {
            this.errorFormFieldsArr.push(item);
            this.locationFlagshw = true;
          }
    
        }
      }  else if(item == 'userMobile') {
        if(this.type['mobileFlag'] == "mobileFlag") {
         if(this.type[item] == null || this.type[item] == '' || this.type[item] == undefined) {
           this.userMobileshw = true;
           this.errorFormFieldsArr.push(item);
         } else {
           this.userMobileshw = false;
         }
        
       } else {
         this.userMobileshw = false;
       }

     } else {
        if (this.type[item] == null || this.type[item] == '') {
          this.errorFormFieldsArr.push(item);
          this[item + "shw"] = true;
        } else {
          this[item + "shw"] = false;
        }
      }
     
     
    });
    // if(this.type.password != this.type.confirmPassword){
    //   this.passwrdmatch = true;
    //   return;

    // }else{
    //   this.passwrdmatch = false;
    // }
    if (this.type.password != this.type.confirmPassword) {
      this.passwrdmatch = true;
      return;

    } else {
      this.passwrdmatch = false;
    }

    // console.log('errorFormFieldsArrerrorFormFieldsArrerrorFormFieldsArr', this.errorFormFieldsArr)
    // if (this.type['locationFlag'] == undefined || this.type['locationFlag'] == '' || this.type['locationFlag'] == '') {
    //   this.locationFlagshw = false;
    // }
    // if (this.type['locationFlag']) {
    //   if (this.type['location']) {
    //     this.locationFlagshw = false;
    //   } else {
    //     this.locationFlagshw = true;
    //   }

    // }
    if (!this.emailvalidation.test(this.type.email)) {
      this.mailWrng = true;
      return;
    } else {
      this.mailWrng = false;
    }
    
    if (this.errorFormFieldsArr.length > 0) {
      return;
    };
    delete this.type.companyIdRelations;
    delete this.type.userName;
    this.type['userTypeId'] = this.userData['userDetails'].userTypeId;
    this.type['emailFlag'] = (this.type['emailFlag'] == "emailFlag") ? 1 : 0;
    this.type['mobileFlag'] = (this.type['mobileFlag'] == "mobileFlag") ? 1 : 0;
    let regInput = {};
    var series = 770;
    var randNum = (series.toString()) + Math.floor(1000000 + Math.random() * 9000000).toString(); //Math.floor(Math.random() * 10000000).toString();
    regInput = {
      email : this.type['email'],
      phone : (this.type['mobileFlag'] == 1) ?  this.type['userMobile'] : randNum, //this.type['landLine'],
      // countryCode : this.type['countryCode']
      // countryCode : this.userData['userDetails'].countryCode
      countryCode : 1

    }
     
    // console.log('thisssssssssssssssssss ', this.userData['userDetails']['mobileFlag'], this.type['mobileFlag']);
    // if(this.type['userMobile'].toString().length == 10) {
      // if(this.type['landLine'].toString().length == 10) {
        this.mblFlag = false;
        this.lanFlag = false;

        if(this.type['mobileFlag'] == 1) {
          if(this.type['userMobile'].toString().length == 10) {
            this.LS.register(regInput).subscribe(regResp => {
              this.type['authId'] = regResp['user']['id'];
              this.PS.editProfile(this.type).subscribe(getUserData => {
                this.getUserypes();
                this.getStates();
                this.successfulSave = true;
                this.successfulSave1 = false;
                this.mblFlag = false;
              }, error => {
                this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
              this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
                  if (error.error.error.statusCode == "421") {
                    this.mobileErr = true;
                  }
                });
            });
          } else {
            this.mblFlag = true;
          }
        } else if(this.type['emailFlag'] == 1) {
          this.LS.register(regInput).subscribe(regResp => {
            this.type['authId'] = regResp['user']['id'];
            this.PS.editProfile(this.type).subscribe(getUserData => {
              this.getUserypes();
              this.getStates();
              this.successfulSave = true;
              this.successfulSave1 = false;
              this.mblFlag = false;
            }, error => {
              this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
            this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
                if (error.error.error.statusCode == "421") {
                  this.mobileErr = true;
                }
              });
          });
        }
      
    // } else {
    //   this.mblFlag = false;
    //     this.lanFlag = true;
    // }
   
    
  }
isDisabled = true;
  triggerSomeEvent() {
      this.isDisabled = !this.isDisabled;
      return;
  }
 
  isNumberKey(evt)
  {
    if(isNaN(evt)){
      this.type.userZip = ''
    }
  }

  emailF: boolean;
  mobileF:boolean;
  triggerEvent1() {
    if(this.type['mobileFlag']=='mobileFlag') {
      this.type['emailFlag'] = ""
      this.emailF = true;
    } else {
      this.type['emailFlag'] = "emailFlag"
      this.emailF = false;
    }
  }
 
  triggerEvent() {
    if(this.type['emailFlag']=='emailFlag') {
      this.type['mobileFlag'] = "";
    } else {
      this.type['mobileFlag'] = "mobileFlag";
    }
    
  }
}
