import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../_services/login.services';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { IMyDpOptions } from 'mydatepicker';
declare var $;
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import * as _ from 'lodash';
import { ExcelService } from '../../../_services/excel.service';
@Component({
  selector: 'app-mail-room-report',
  templateUrl: './mail-room-report.component.html',
  styleUrls: ['./mail-room-report.component.css'],
  providers: [LoginService]

})
export class MailRoomReportComponent implements OnInit {
  title = 'TapTrack Mail Room Report'
  userData: any = {};
  usersData: any = {};
  companyData: any = {};
  Query: any = {};
  error: any = false;
  error1: any = false;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: 'mm/dd/yyyy',
  };

  // Initialized to specific date (09.10.2018).
  public Mailreports: any = []
  constructor(private titleService: Title, public local: LocalStorageService, private Login: LoginService,private excelService: ExcelService) {
    this.userData = this.local.get("loginInfo");
    this.userData = JSON.parse(this.userData);
    this.usersData = this.userData['userDetails'];
    this.companyData = this.userData['userDetails']['companyIdRelations'];
    this.Query['UserId'] = this.usersData.id
    this.Query['companyId '] = this.companyData.companyId


  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }
  GetReport() {
    if (this.Query.fromDate == null || this.Query.fromDate == '' || this.Query.toDate == null || this.Query.toDate == '') {
      alert('Please Select Required Fields')
    } else {
      this.Login.getmailreports(this.Query).subscribe(reportData => {
        this.Mailreports = reportData

      });
    }
  }
  // validate(fromDate, toDate) {


  // }
  getItemList() {
    // 2019-11-01
    if (this.Query.fromDate && this.Query.toDate) {
      this.error = false;
      let year = this.Query.fromDate.jsdate.getFullYear()
      let month = this.Query.fromDate.jsdate.getMonth() + 1
      let date = this.Query.fromDate.jsdate.getDate()
      let year1 = this.Query.toDate.jsdate.getFullYear()
      let month1 = this.Query.toDate.jsdate.getMonth() + 1
      let date1 = this.Query.toDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      let fromDate = year + '-' + month + '-' + date
      let toDate = year1 + '-' + month1 + '-' + date1
      // this.Query.fromDate = fromDate
      // this.Query.toDate = toDate
      let input = {};
      input = {
        'fromDate': fromDate,
        'toDate': toDate,
        'userId': this.usersData.id,
        'companyId': this.companyData.companyId
      }

      window.open(`#/hrmlReportmailss?Query=${JSON.stringify(input)}`, 'name', 'width=850,height=600,scrollbars=1');
      // window.open(`/printPdf?Query=${JSON.stringify(input)}`, 'name', 'width=1000,height=600');


    } else {
      // alert('Please Select Required Fields')
      this.error = true;
      // return
    }
  }
  clearfilds(fromDate, toDate) {
    this.Query = {}
    this.Query.fromDate = ''
    this.Query.toDate = ''
    this.error = false;
    this.error1 = false;


  }
  onDateChanged(fromDate, toDate) {
    // if (new Date(fromDate.jsdate) > new Date(toDate.jsdate)) {
    //   alert('From date should be greater than to date')
    //   this.Query.fromDate = ''
    //   this.Query.toDate = ''
    // }
    if (new Date(fromDate.jsdate) < new Date(toDate.jsdate)) {
      alert('todate should be greater than From date')
      this.Query.fromDate = ''
      this.Query.toDate = ''
    }

  }
  itmList: any = []
  getPdf() {

    if (this.Query.fromDate && this.Query.toDate) {
     
      this.error = false;
      let year = this.Query.fromDate.jsdate.getFullYear()
    let month = this.Query.fromDate.jsdate.getMonth() + 1
    let date = this.Query.fromDate.jsdate.getDate()
    let year1 = this.Query.toDate.jsdate.getFullYear()
    let month1 = this.Query.toDate.jsdate.getMonth() + 1
    let date1 = this.Query.toDate.jsdate.getDate()
    if (date <= 9) {
      date = '0' + date
    }
    if (month <= 9) {
      month = '0' + month
    }
    if (date1 <= 9) {
      date1 = '0' + date1
    }
    if (month1 <= 9) {
      month1 = '0' + month1
    }
    let fromDate = year + '-' + month + '-' + date
    let toDate = year1 + '-' + month1 + '-' + date1
    // this.Query.fromDate = fromDate
    // this.Query.toDate = toDate
    let input = {};
    input = {
      'fromDate': fromDate,
      'toDate': toDate,
      'userId': this.usersData.id,
      'companyId': this.companyData.companyId
    }
    this.Login.getmailreports(input).subscribe(reportData => {
      if (reportData != null && reportData != undefined) {
        this.error = false;
        this.error1 = false;
        this.itmList = reportData;
        const doc = new jsPDF();
        var Dat = 'Date Range :     '+fromDate+'   -   '+toDate;
        //   // doc.autoTable({ html: '#my-table' });
        // doc.setFontSize(10);
        // doc.setFontStyle('arial');
        // doc.text(11, 12, "Mail Room Report");
        // doc.text(17, 18, "Number Of Item Types =       " + this.itmList.length);
        doc.setFontSize(10);
        // doc.setFont("sans-serif");
        // doc.text(20, 30, "Item Inventory" );
        doc.text(20, 25, "Number Of\nItem Types =       " + this.itmList.length +'\n'+"\n");
        doc.text(20, 35, Dat +'\n'+"\n");

        doc.autoTable({
          styles: { width: 10, border: 2, halign: "center" },
          columnStyles: { text: { cellWidth: "auto", border: 2 } }, // European countries centered
          body: this.itmList,
          columns: [{ header: 'ItemType', dataKey: 'itemType' }, { header: 'Number Of Items', dataKey: 'itemsList' }],
          margin: { top: 40, bottom: 20 , width: 300},
          tableLineWidth: 0.5,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            fontSize: 10,
            textColor: [25, 25, 25],
            theme: "plane",
            marginBottom: 20,
            lineWidth: 0.5,
            marginTop: 20,
            border: 4,
            // rowHeight: 6,
            lineColor: 200
          },
          beforePageContent: function(data) {
            doc.setFontSize(20),doc.setFontStyle('bold'), doc.text("Mail Room Report", 75, 20);
          }
        });
        doc.save('MailRoomReport.pdf');
        // let dataSrc = doc.output("datauristring");
        // let win = window.open("", "myWindow");
        // win.document.write("<html><head><title>jsPDF</title></head><body style=width='100%' height='100%'><embed src=" +
        //   dataSrc + "></embed></body></html>");
       
      } else {
        this.error1 = true;
      }
    });
    } else {
      this.error = true;
    }

  }

  generateExcel() {
    if (this.Query.fromDate && this.Query.toDate) {
      this.error = false;
      let year = this.Query.fromDate.jsdate.getFullYear()
      let month = this.Query.fromDate.jsdate.getMonth() + 1
      let date = this.Query.fromDate.jsdate.getDate()
      let year1 = this.Query.toDate.jsdate.getFullYear()
      let month1 = this.Query.toDate.jsdate.getMonth() + 1
      let date1 = this.Query.toDate.jsdate.getDate()
      if (date <= 9) {
        date = '0' + date
      }
      if (month <= 9) {
        month = '0' + month
      }
      if (date1 <= 9) {
        date1 = '0' + date1
      }
      if (month1 <= 9) {
        month1 = '0' + month1
      }
      let fromDate = year + '-' + month + '-' + date
      let toDate = year1 + '-' + month1 + '-' + date1
      // this.Query.fromDate = fromDate
      // this.Query.toDate = toDate
      let input = {};
      input = {
        'fromDate': fromDate,
        'toDate': toDate,
        'userId': this.usersData.id,
        'companyId': this.companyData.companyId
      }

      this.Login.getmailreports(input).subscribe(reportData => {
        if (reportData != null && reportData != undefined) {
          this.error = false;
          this.error1 = false;
          this.itmList = [];
          this.itmList = reportData;
          var title = 'Mail Room Report';
          var header = ['Item Type', 'Number Of Items'];
          var subTitleRow = 'Number Of Item Types :    '+this.itmList.length
          var fileName = 'MailRoomReport';
          var reportName = 'Mail Room Report';
          var Dat = 'Date Range:     '+fromDate+'   -   '+toDate;
          const data1 = [] = this.itmList;
          const data = [];
          let data2 = [];
          var self = this
          _.each(data1, function (obj) {
            data2=[];
            data2.push(obj.itemType)
            data2.push(obj.itemsList)
            data.push(data2)
          })
          let excelObj = {};
          excelObj = {
            'response' : data,
            'reportName' : reportName,
            'title' : title,
            'header' : header,
            'subTitleRow' : subTitleRow,
            'fileName' : fileName,
            'dateRangeSubTitle' : Dat
          }
          this.excelService.generateExcel(excelObj);
        } else {
          this.error1 = true;
        }
      });

    } else {
      // alert('Please Select Required Fields')
      this.error = true;
      // return
    }
  }
 
}
