import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';


@Component({
  selector: 'app-html-report-tracking',
  templateUrl: './html-report-tracking.component.html',
  styleUrls: ['./html-report-tracking.component.css']
})
export class HtmlReportTrackingComponent implements OnInit {
  input:any={}
 reportsArry:any = [];
 count : any =  0;
 reportsArryerror:any;
  constructor(private US: UserService,private spinner: NgxSpinnerService, private route: ActivatedRoute,) { }

  ngOnInit() {
      this.route.queryParams.subscribe(params => {
          this.getData({userId: params.userId})
        });
  }
getData(data){
  // alert();
  this.spinner.show();
  this.US.getusertrackingreports(data).subscribe(usrData => {
    this.reportsArry = usrData;
  //  console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr ', JSON.stringify(this.reportsArry));
    for(var i=0;i<this.reportsArry.length;i++) {
      this.count = (this.reportsArry[i].daysHeld + this.count);
      // console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv ', this.count,this.reportsArry[i].daysHeld);
    }
    this.count = (this.count )/ (this.reportsArry.length);
    this.count = this.count.toFixed();
    // console.log('cccccccccccccccccccccccccccccccccccccccccccc ', this.count);
    _.each(this.reportsArry,function (data) {
      data['daysHeld'] = parseInt(data.daysHeld)
      // console.log('dddddddhhhhhhhhhhhhhhhhhhhhhh ', data['daysHeld']);
      data['name'] = (data.lastHeld) ? data.lastHeld.replace(/ *\([^)]*\) */g, "") : null;
    })
    this.spinner.hide();
  },
  error=>{
    this.reportsArryerror = error.error.error.message
      console.log(this.reportsArryerror)
    this.spinner.hide();
  }
);
}
}
