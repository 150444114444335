import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { supervisorService } from '../../../_services/supervisor.service';
import { LoginService } from '../../../_services/login.services';
import * as _ from 'lodash';
import * as async from 'async';
import * as await from 'async';
import { Router } from "@angular/router";

import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
@Component({
  selector: 'app-item-type-report',
  templateUrl: './item-type-report.component.html',
  styleUrls: ['./item-type-report.component.css'],
  providers: [LoginService]

})
export class ItemTypeReportComponent implements OnInit {
  loginForm: any = {};
  Sarchfileds: any = {};
  itemstypeserror:any;
  Suprevisors: any ;
  userData: any = {};
  companyData: any = {};
  itemList: any = [];
  page_size: any;
  page: any;
  // this:any;
  res:any;
  username:any
  // editMode: any = false
  updatedData: any = []
  itemstypes: any = {}
  // userData : any = {}
  recordsCount: any;
  pagination_numbers: any = {};
  title = 'TapTrack Item Type Report'
  constructor(private titleService : Title, private ss: supervisorService, private LS: LoginService, private localStorage: LocalStorageService, private router: Router) {
    this.userData = this.localStorage.get("loginInfo");
    this.page = 0;
    this.page_size = 7;
    this.recordsCount = 0;
    this.pagination_numbers.fromNumber = 0;
    this.pagination_numbers.toNumber = this.page_size;
    if (this.userData) {
      // console
      // this.userData = JSON.parse(this.userData);
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    }
   }

  ngOnInit() {
    this.initCount();

    this.titleService.setTitle(this.title);
  }
  initCount() {
    let input = {};
    input = {
      companyId: this.companyData.companyId
    };
    // this.getItemList(input)
    this.LS.getitemtypesconut(input).subscribe(recordsCount => {
      this.recordsCount = recordsCount;
      this.pagination_numbers.totalNumber = this.recordsCount;
      this.nextPage("first");
    })
  }
  nextPage(whichPage) {
    if (whichPage == "next" && this.recordsCount) {
      if (this.pagination_numbers.toNumber == this.pagination_numbers.totalNumber)
      return
    else
    this.page = this.page + 1;
    } else if (whichPage == "previous" && this.page) {
      this.page = this.page - 1;
    } else if (whichPage == "first" && this.recordsCount) {
      this.page = this.page * 0;
    } else if (whichPage == "last" && this.recordsCount) {
      this.page = Math.floor(this.recordsCount / this.page_size);
    } else return;
    this.pagination_numbers.fromNumber = this.page * this.page_size;
    this.pagination_numbers.toNumber = (this.page + 1) * this.page_size;
    if (this.pagination_numbers.toNumber > this.pagination_numbers.totalNumber)
      this.pagination_numbers.toNumber = this.pagination_numbers.totalNumber;
    this.getItemList(this.page, this.page_size);
  }
  filterForPageSize(selectedPageSize) {
    this.page_size = selectedPageSize;
    this.getItemList(this.page, this.page_size);
    this.page = 0;
    // this.page_size = 7;
    this.nextPage("first");
    
  }
  getItemList(page, page_size) {
    let input = {};
    if (page_size == 0) {
      page_size = 7
    }
    input = {
      companyId: this.companyData.companyId,
      skip: page * page_size,
      limit: page_size
    };
    this.LS.getitemTypeReport(input).subscribe(itemstypes => {
      this.itemstypes = itemstypes;
    },error=>{
      this.itemstypeserror = error.error.error.message
      console.log(this.itemstypeserror)
      // console.log(JSON.stringify(error.error.error.message))
    });
  }
  route(id){
    // itemsReport/:id
    this.router.navigate(['itemsReportsub/'+id]);
  }
}
