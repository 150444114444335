import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { Router } from "../../../../../node_modules/@angular/router";
import { UserService } from "../../../_services";
import { ItemService } from "../../../_services/item.service";
import { LocalStorageService } from "../../../../../node_modules/angular-web-storage";
import { share } from "../../../../../node_modules/rxjs/operators";

@Component({
  selector: "app-change-item-status",
  templateUrl: "./change-item-status.component.html",
  styleUrls: ["./change-item-status.component.css"],
  providers: [ItemService, UserService]
})
export class ChangeItemStatusComponent implements OnInit {
  title = "TapTrack Change Item Status";
  userArr1: any = [];
  userArr: any = [];
  filterData: any = [];
  searchData: any = [];
  userData: any;
  companyData: any;
  filterData1: any = [];
  itemStatusId: any = "All";
  changeshow: any = true;
  userId: any;
  companyId: any;
  showsuccess: any = false;
  changeshow1: any = false;
  ownerShow: any = false;
  searchDataerror:any;
  page1: any;
  recordsCount1: any;
  page_size1: any;
  pagination_numbers1: any = {};

  constructor(
    private titleService: Title,
    private router: Router,
    private US: UserService,
    private IS: ItemService,
    private localStorage: LocalStorageService
  ) {
    this.titleService.setTitle(this.title);
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
      this.userId = this.userData.userId;
      this.companyId = this.userData.userDetails.companyId;
    }

    this.page1 = 0;
    this.page_size1 = 7;
    this.recordsCount1 = 0;
    this.pagination_numbers1.fromNumber = 0;
    this.pagination_numbers1.toNumber = this.page_size1;
  }
  userArrIds = [];
  ngOnInit() {
    this.initCount1();
    this.filter123();
    this.filter12();
    this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArr1 = usrData;
      this.userArrIds = [];
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")";
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);
      }
    });
  }
  nextPage1(whichPage) {
    if (whichPage == "next" && this.recordsCount1) {
      if (
        this.pagination_numbers1.toNumber ==
        this.pagination_numbers1.totalNumber
      )
        return;
      else this.page1 = this.page1 + 1;
    } else if (whichPage == "previous" && this.page1) {
      this.page1 = this.page1 - 1;
    } else if (whichPage == "first" && this.recordsCount1) {
      this.page1 = this.page1 * 0;
    } else if (whichPage == "last" && this.recordsCount1) {
      this.page1 = Math.floor(this.recordsCount1 / this.page_size1);
    } else return;
    this.pagination_numbers1.fromNumber = this.page1 * this.page_size1;
    this.pagination_numbers1.toNumber = (this.page1 + 1) * this.page_size1;
    if (
      this.pagination_numbers1.toNumber > this.pagination_numbers1.totalNumber
    )
      this.pagination_numbers1.toNumber = this.pagination_numbers1.totalNumber;
    this.getItemList1(this.page1, this.page_size1);
  }
  filterForPageSize1(selectedPageSize) {
    this.page_size1 = selectedPageSize;
    this.getItemList1(this.page1, this.page_size1);
    this.page1 = 0;
    // this.page_size1 = 7;
    this.nextPage1("first");
  }
  initCount1() {
    let input = {
      companyId: this.companyId,
      itemStatusId: this.itemStatusId
    };
    if (this.reciever)
      input["currentOwner"] = this.userArrIds[
        this.userArr.indexOf(this.reciever)
      ];
    this.IS.searchDataCount(input).subscribe(recordsCount => {
      this.recordsCount1 = recordsCount;
      if (this.recordsCount1 > 0) {
        this.tableshow = true;
        this.noResult = false;
      } else {
        this.tableshow = false;
        this.noResult = true;
      }
      this.pagination_numbers1.totalNumber = this.recordsCount1;
      this.nextPage1("first");
    });
  }

  filter12() {
    let input = {};
    input = {
      type: ""
    };
    this.IS.filter(input).subscribe(
      data => {
        this.filterData = data;
        this.itemStatusId = this.filterData[
          this.filterData.length - 1
        ].itemStatusId;
      },
      error => {}
    );
  }
  filter123() {
    let input = {};
    input = {
      type: "filter"
    };
    this.IS.filter(input).subscribe(
      data => {
        this.filterData1 = data;
      },
      error => {}
    );
  }
  reciever: any;
  tableshow: any = false;
  noResult: any = false;
  getItemList1(page, page_size) {
    let input = {
      companyId: this.companyId,
      itemStatusId: this.itemStatusId,
      skip: page * page_size,
      limit: page_size
    };
    if (this.reciever)
      input["currentOwner"] = this.userArrIds[
        this.userArr.indexOf(this.reciever)
      ];

    this.IS.searchData(input).subscribe(
      data => {
        this.searchData = data;
        if (this.searchData.length > 0) {
          this.tableshow = true;
          this.noResult = false;
        } else {
          this.tableshow = false;
          this.noResult = true;
        }
      },
      error => {
        this.searchDataerror = error.error.error.message
        console.log(this.searchDataerror)
      }
    );
    this.userId = "";
  }
  messagesToSend = [];
  idsOfMessagesToSend = [];
  checkstatus(isChecked, data) {
    if (isChecked === true) {
      if (this.messagesToSend.length === 0) {
        this.messagesToSend.push(data);
        this.idsOfMessagesToSend.push(data.itemUniqueId);
      } else {
        if (this.idsOfMessagesToSend.indexOf(data.itemUniqueId) === -1) {
          this.messagesToSend.push(data);
          this.idsOfMessagesToSend.push(data.itemUniqueId);
        }
      }
    } else if (isChecked === false) {
      this.messagesToSend.splice(
        this.idsOfMessagesToSend.indexOf(data.itemUniqueId),
        1
      );
      this.idsOfMessagesToSend.splice(
        this.idsOfMessagesToSend.indexOf(data.itemUniqueId),
        1
      );
    }
  }
  dataUniqueId: any;
  edit() {
    if (this.messagesToSend.length > 0) {
      this.changeshow = false;
      this.changeshow1 = true;
      // var messagesToSendFinal = JSON.parse(JSON.stringify(this.messagesToSend));
      // messagesToSendFinal.forEach((item) => {
      //   this.dataUniqueId =item.itemUniqueId;
      // });
    }
  }
  reciever1: any;
  itemStatusId1: any;
  changeStatus: any = false;
  submitEdit() {
    if (this.itemStatusId1 == null || this.itemStatusId1 == "") {
      this.changeStatus = true;
    } else {
      this.changeStatus = false;
    }
    if (this.reciever1 == null || this.reciever1 == "") {
      this.ownerShow = true;
      return;
    } else {
      this.ownerShow = false;
    }
    let input = {};
    input = {
      currentOwner: this.reciever1,
      reciever: this.reciever1,
      sender: this.userId,
      itemIds: this.idsOfMessagesToSend,
      addedByAdminFlag: 0,
      transComments: "",
      companyId: this.companyId,
      itemStatusId: this.itemStatusId1
    };
    this.IS.searchsubmitdata(input).subscribe(
      data => {
        this.showsuccess = true;
        this.changeshow = false;
        this.changeshow1 = false;
      },
      error => {}
    );
  }

  clear() {
    this.reciever = "";
    //  this.itemStatusId = '';
    this.filter12();
    this.noResult = false;
    this.initCount1();
  }
}
