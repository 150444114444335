import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  
  constructor() {

  }

  // async generateExcel(response, reportName, titles, headers, subTitleRows, fileName) {
    async generateExcel(excelObj) {

    // Excel Title, Header, Data
    const title = excelObj.title;
    const header = excelObj.header
    const data1 = [] = excelObj.response;
    const data = [] = excelObj.response;
    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(excelObj.reportName);

    // Add Row and formatting
    // const titleRow = worksheet.addRow([title]);
    if(excelObj.reportName == 'Mail Room Report' || excelObj.reportName == 'User Activity Report') {
     
      worksheet.mergeCells(`A1:B1`);
      worksheet.getCell('B1').value = title;
      /*TITLE*/
      // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
      worksheet.getCell('B1').font = {
        name: 'Comic Sans MS',
        family: 4, size: 16, underline: 'double', bold: true
      };
      worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
    } else if(excelObj.reportName == 'User Tracking Filter Report') {
      worksheet.mergeCells(`A1:D1`);
      worksheet.getCell('B1').value = title;
      /*TITLE*/
      // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
      worksheet.getCell('B1').font = {
        name: 'Comic Sans MS',
        family: 4, size: 16, underline: 'double', bold: true
      };
      worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };

    }
    else if(excelObj.reportName == 'Items Sent By User Report' || excelObj.reportName == 'Items Held By User Report') {
      worksheet.mergeCells(`A1:D1`);
      worksheet.getCell('B1').value = title;
      /*TITLE*/
      // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
      worksheet.getCell('B1').font = {
        name: 'Comic Sans MS',
        family: 4, size: 16, underline: 'double', bold: true
      };
      worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };

    }
    else if(excelObj.reportName == 'Items Report' || excelObj.reportName == 'Item Closed Filter Report' || excelObj.reportName == 'Time Held Report' || excelObj.reportName == 'Search Report') {
      worksheet.mergeCells(`A1:E1`);
      worksheet.getCell('C1').value = title;
      /*TITLE*/
      // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
      worksheet.getCell('C1').font = {
        name: 'Comic Sans MS',
        family: 4, size: 16, underline: 'double', bold: true
      };
      worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };

    } else {
      worksheet.getCell('C1').value = title;
      /*TITLE*/
      // titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
      worksheet.getCell('C1').font = {
        name: 'Comic Sans MS',
        family: 4, size: 16, underline: 'double', bold: true
      };
    }
    
    if(excelObj.reportName!= 'Items Sent By User Report' && 
    excelObj.reportName != 'User Tracking Filter Report' && 
    excelObj.reportName.toString() !='Items Report' &&
     excelObj.reportName.toString() !='Item Closed Filter Report' && 
     excelObj.reportName.toString() !='Time Held Report' &&
     excelObj.reportName.toString() !='Items Held By User Report') {
      worksheet.addRow([]);
      const subTitleRow = worksheet.addRow([excelObj.subTitleRow]);
      subTitleRow.font = { bold: true }
    }
   
    // Blank Row
    if(excelObj.reportName == 'Binders Processed Report' || excelObj.reportName == 'Mail Room Report') {
      const dateRangeSubTitle = worksheet.addRow([excelObj.dateRangeSubTitle]);
      dateRangeSubTitle.font = { bold: true }
    }
    if(excelObj.reportName == 'User Tracking Filter Report' || excelObj.reportName == 'Items Sent By User Report') {
      worksheet.addRow([]);
    const subTitleRow = worksheet.addRow([excelObj.subTitleRow]);
    subTitleRow.font = { bold: true }

    subTitleRow.getCell(3).value = excelObj.avgDays;

      const userNmaeSub = worksheet.addRow([excelObj.userNmaeSub]);
      userNmaeSub.font = { bold: true }
      userNmaeSub.getCell(3).value = excelObj.nbItems;
    }
    if(excelObj.reportName == 'Items Report' || excelObj.reportName == 'Item Closed Filter Report' || excelObj.reportName == 'Time Held Report') {
      worksheet.addRow([]);
    const subTitleRow = worksheet.addRow([excelObj.subTitleRow]);
    subTitleRow.font = { bold: true }

    subTitleRow.getCell(4).value = excelObj.itemTotal;

    }
    if(excelObj.reportName == 'Items Held By User Report') {
      worksheet.addRow([]);
      const subTitleRow = worksheet.addRow([excelObj.subTitleRow]);
      subTitleRow.font = { bold: true }
  
      subTitleRow.getCell(3).value = excelObj.avgDays;
      
    }
    worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' },
        // bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    headerRow.font = { bold: true };
    // worksheet.addRows(data);


    // Add Data and Conditional Formatting
    data.forEach(d => {
      const row = worksheet.addRow(d);
      let color = 'FF99FF99';
    }

    );
    worksheet.getColumn(1).width = 35;
    if(excelObj.reportName == 'Mail Room Report') {
      worksheet.getColumn(2).width = 17;
    } else {
      worksheet.getColumn(2).width = 50;
    }
    
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 50;
    if(excelObj.reportName == 'User Activity Report') {
      worksheet.getColumn(2).width = 17;
      worksheet.getColumn(3).width = 17;
    }  
    if(excelObj.reportName == 'User Tracking Filter Report') {
      worksheet.getColumn(1).width = 35;
      worksheet.getColumn(2).width = 20;
      worksheet.getColumn(3).width = 30;
      worksheet.getColumn(4).width = 15;
      // worksheet.getColumn(5).width = 15;
    }
    if(excelObj.reportName == 'Items Report') {
      worksheet.getColumn(1).width = 25;
      worksheet.getColumn(2).width = 20;
      worksheet.getColumn(3).width = 15;
      worksheet.getColumn(4).width = 30;
      worksheet.getColumn(5).width = 15;
    }
    if(excelObj.reportName == 'Item Closed Filter Report') {
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 18;
      worksheet.getColumn(4).width = 30;
      worksheet.getColumn(5).width = 15;
    }
    if(excelObj.reportName == 'Time Held Report') {
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(3).width = 30;
      worksheet.getColumn(2).width = 18;
      worksheet.getColumn(5).width = 22;
      worksheet.getColumn(4).width = 10;
    }
    if(excelObj.reportName == 'Search Report') {
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(4).width = 30;
      worksheet.getColumn(2).width = 20;
      worksheet.getColumn(5).width = 15;
      worksheet.getColumn(3).width = 10;
    }
    if(excelObj.reportName == 'Items Sent By User Report') {
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(4).width = 20;
      worksheet.getColumn(2).width = 20;
      worksheet.getColumn(3).width = 30;
    }
    if(excelObj.reportName == 'Items Held By User Report') {
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(4).width = 20;
      worksheet.getColumn(2).width = 20;
    }
    
    worksheet.addRow([]);


    // Footer Row
    const footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFFF' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    footerRow.font = { bold: true };
    // Merge Cells
    if(excelObj.reportName == 'Mail Room Report' || excelObj.reportName == 'User Activity Report') {
      worksheet.mergeCells(`A${footerRow.number}:B${footerRow.number}`);
    } else {
      worksheet.mergeCells(`A${footerRow.number}:D${footerRow.number}`);
    }
    
    // worksheet.mergeCells('A2984:A2987')
    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, excelObj.fileName + '.xlsx');
    });

  }
}

