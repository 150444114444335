import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../../_services/login.services';

@Component({
  selector: 'app-user-activity-htmlreport',
  templateUrl: './user-activity-htmlreport.component.html',
  styleUrls: ['./user-activity-htmlreport.component.css'],
  providers: [LoginService]

})
export class UserActivityHtmlreportComponent implements OnInit {
  reportsArry:any=[]
  selectedId:any;
  constructor(private US: UserService,private spinner: NgxSpinnerService, private route: ActivatedRoute,private Login: LoginService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      var abc = {} =JSON.stringify(params);
      var xyz = {} = JSON.parse(abc)
      
      this.getData(xyz)
    });
  }
  dummy : any = [];
  getData(data){
    var myobj = JSON.stringify(data)
    this.spinner.show();
    var  input= {}; // data.query
    input = {
      'UserId' : JSON.parse(data.query).UserId,
      'fromDate' : JSON.parse(data.query).fromDate,
      'toDate' : JSON.parse(data.query).toDate
    }
    this.Login.GetReportsData(input).subscribe(usrData => {
      this.reportsArry = [];
      if (usrData!=null && usrData!=undefined) {
        this.dummy = usrData;
        for(var i=0; i<this.dummy.length;i++) {
          let u = {} = this.dummy[i];
          let date = new Date(this.dummy[i].transactionTime);
          let month = (date.getMonth() + 1);
          let datee = date.getDate();
          u['transactionTime'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes());
          // u['transactionTime'] = date.getDate() + '/'+ (date.getMonth() + 1) + '/' + date.getFullYear().toString().substr(-2) + ' ' + this.formatAMPM(date);
          this.reportsArry.push(u);
        }
      }
      // this.reportsArry = usrData;
      this.spinner.hide();
    },
    error=>{
      this.spinner.hide();
    }
  );
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
}
