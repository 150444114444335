import { Component, OnInit, Input } from "@angular/core";
declare var $;
import { ItemService } from "../../../_services/item.service";
import { UserService } from "../../../_services/user.service";
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import { BookService } from "./users";
import { CompleterService, CompleterData } from "ng2-completer";
import { Title } from "../../../../../node_modules/@angular/platform-browser";
import * as _ from "lodash";
import { JsonPipe } from "@angular/common";

@Component({
  selector: "app-add-item",
  templateUrl: "./add-item.component.html",
  styleUrls: ["./add-item.component.css"],
  providers: [ItemService, BookService, UserService]
})
export class AddItemComponent implements OnInit {
  title = "TapTrack Add Item";
  userData: any = {};
  companyData: any = {};
  getItemData: any = [];
  uniqicdata: any;
  itemArray = [];
  item: any = {};
  userArr: any = [];
  userArr1: any = [];
  succesShow: any = false;
  format: any = "999-9999999";
  itmTypeId: any;
  errorMessage = "";
  disableButton = false;

  protected dataService: CompleterData;
  protected searchStr: string;
  protected captain: string;
  @Input() public matchClass: string | undefined;

  maxInputLength = 11;


  constructor(
    private IS: ItemService,
    private titleService: Title,
    private localStorage: LocalStorageService,
    private router: Router,
    private bs: BookService,
    private US: UserService,
    private completerService: CompleterService
  ) {

    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
      this.router.navigate(["/login"]);
    }
    this.item["itemArray"] = [];

    $(document).ready(function () {
      $("input").keyup(function () {
        if (
          $(this).val().length == $(this).attr("maxlength") ||
          ($(this)
            .val()
            .indexOf("-") >= 0 &&
            $(this).val().length == +$(this).attr("maxlength") + 1)
        ) {
          var i = $("input").index(this);
          $("input")
            .eq(i + 1)
            .focus();
        }
      });
    });
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.getItemTypes();
    // this.userArr = this.bs.getBooks();
    this.US.getUsers({ companyId: this.companyData.companyId }).subscribe(usrData => {
      this.userArr1 = [];
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        // this.userArr.push(this.userArr1[i]['username']);
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")";
        this.userArr.push(nameStr);
      }
    });
  }

  checkBoxChanged(value) {
    this.item = {};
    this.item = {
      uniqueCheckbox: value
    };
  }

  checkUniqIdExistence(value, key) {
    var self = this;
    var query = {
      itemUniqueId: value,
      companyId: this.companyData.companyId
    };
    this.IS.uIdValidation(query).subscribe(existedItem => {

    }, error => {
      this.showErrorMessage("ID Already Exist");
      this.item[key] = "";
    });
  }
  insertDash(item, value, key) {
    var nextChar = this.format.charAt(value.length);
    if (nextChar.toString() == "-") {
      var lastChar = value.charAt(value.length - 1);
      if (lastChar == "-") {
        item[key] = value.slice(0, -1);
      } else {
        item[key] = value + "-";
      }
    } else {
      item[key] = value;
    }
    if (value.length == this.format.length) {
      this.checkUniqIdExistence(value, key);
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getItemTypes() {
    this.IS.getItemType({ companyId: this.companyData.companyId }).subscribe(getItemData => {
      this.getItemData = getItemData;
    });
  }

  regexFormat: any;
  public onChange(value): void {
    this.itmTypeId = value;
    var self = this;
    this.item = {};
    _.each(this.getItemData, function (items) {
      if (items.itemTypeId == value) {
        self.regexFormat = items.regexFormat;
        self.format = items.regexFormat.toString();
        self.maxInputLength = self.format.length;
      }
    });
  }

  showErrorMessage(msg) {
    this.disableButton = false;
    var self = this;
    this.errorMessage = msg;
    setTimeout(function () {
      self.errorMessage = " ";
    }, 1000);
  }
  crateItemArr: any = [];
  createItem() {
    this.disableButton = true;
    let itemInput = {};
    itemInput = this.item;
    if (this.item.reciever) {
      let x = this.item.reciever.split("(");
      let y = x[1].split(")");
      itemInput["reciever"] = y[0];
    }

    itemInput['regexFormat'] = this.regexFormat;
    itemInput["itemType"] = this.itmTypeId;
    itemInput["companyId"] = this.companyData.companyId;
    itemInput["userId"] = this.userData["userDetails"]["id"];
    itemInput["itemArray"] = [];
    itemInput["itemArray"].push(this.item.one ? this.item.one : undefined);
    itemInput["itemArray"].push(this.item.two ? this.item.two : undefined);
    itemInput["itemArray"].push(this.item.three ? this.item.three : undefined);
    itemInput["itemArray"].push(this.item.four ? this.item.four : undefined);
    itemInput["itemArray"].push(this.item.five ? this.item.five : undefined);
    itemInput["itemArray"].push(this.item.six ? this.item.six : undefined);
    itemInput["itemArray"].push(this.item.seven ? this.item.seven : undefined);
    itemInput["itemArray"].push(this.item.eight ? this.item.eight : undefined);
    itemInput["itemArray"].push(this.item.nine ? this.item.nine : undefined);
    itemInput["itemArray"].push(this.item.ten ? this.item.ten : undefined);
    itemInput["itemArray"].push(
      this.item.eleven ? this.item.eleven : undefined
    );
    itemInput["itemArray"].push(
      this.item.twelve ? this.item.twelve : undefined
    );
    itemInput["itemArray"].push(
      this.item.thirteen ? this.item.thirteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.fourteen ? this.item.fourteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.fifteen ? this.item.fifteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.sixteen ? this.item.sixteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.seventeen ? this.item.seventeen : undefined
    );
    itemInput["itemArray"].push(
      this.item.eighteen ? this.item.eighteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.nineteen ? this.item.nineteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.twenty ? this.item.twenty : undefined
    );
    var array = itemInput["itemArray"];
    var filtered = [];
    filtered = array.filter(function (el) {
      return el != null;
    });

    if (filtered.length == 0 && !this.item.uniqueCheckbox) {
      this.showErrorMessage("Please Enter Unique Ids");
      return;
    } else if (!this.item.tagName || this.item.tagName == " ") {
      this.showErrorMessage("Please Enter Tag Name");
      return;
    } else if (!this.item.tagDesc || this.item.tagDesc == " ") {
      this.showErrorMessage("Please Enter Tag Description");
      return;
    } else if (
      this.item.transFlag &&
      (!this.item.reciever || this.item.reciever == "")
    ) {
      this.showErrorMessage("Please Select User to be Assigned");
      return;
    } else {
      itemInput["itemArray"] = filtered;
      itemInput["itemType"] = Number(itemInput["itemType"]);

      var arr = itemInput["itemArray"];
      var sorted_arr = arr.slice().sort();
      var results = [];
      for (var i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
          results.push(sorted_arr[i]);
        }
      }

      if (results.length > 0) {
        this.showErrorMessage("Duplicate Unique Ids are present");
      } else {
        this.IS.createItem(itemInput).subscribe(createdItem => {
          if (createdItem != null && createdItem != undefined) {
            this.crateItemArr = createdItem;
            this.disableButton = false;
            this.item = {};
            itemInput = {};
            this.succesShow = true;

          } else {
            this.showErrorMessage("No Items created");
            this.item = {};
            itemInput = {};
            this.succesShow = false;
          }

        }, error => {
          // console.log('erererererrrrrrreeeeeerrrrrrr',error);
          this.showErrorMessage(error.error.error.message);
          this.item = {};
          itemInput = {};
          this.succesShow = false;
        });
      }

    }

  }
  printandsave() {
    let itemInput = {};
    itemInput = this.item;
    if (this.item.reciever) {
      let x = this.item.reciever.split("(");
      let y = x[1].split(")");
      itemInput["reciever"] = y[0];
    }

    itemInput['regexFormat'] = this.regexFormat;
    itemInput["itemType"] = this.itmTypeId;
    itemInput["companyId"] = this.companyData.companyId;
    itemInput["userId"] = this.userData["userDetails"]["id"];
    itemInput["itemArray"] = [];
    itemInput["itemArray"].push(this.item.one ? this.item.one : undefined);
    itemInput["itemArray"].push(this.item.two ? this.item.two : undefined);
    itemInput["itemArray"].push(this.item.three ? this.item.three : undefined);
    itemInput["itemArray"].push(this.item.four ? this.item.four : undefined);
    itemInput["itemArray"].push(this.item.five ? this.item.five : undefined);
    itemInput["itemArray"].push(this.item.six ? this.item.six : undefined);
    itemInput["itemArray"].push(this.item.seven ? this.item.seven : undefined);
    itemInput["itemArray"].push(this.item.eight ? this.item.eight : undefined);
    itemInput["itemArray"].push(this.item.nine ? this.item.nine : undefined);
    itemInput["itemArray"].push(this.item.ten ? this.item.ten : undefined);
    itemInput["itemArray"].push(
      this.item.eleven ? this.item.eleven : undefined
    );
    itemInput["itemArray"].push(
      this.item.twelve ? this.item.twelve : undefined
    );
    itemInput["itemArray"].push(
      this.item.thirteen ? this.item.thirteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.fourteen ? this.item.fourteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.fifteen ? this.item.fifteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.sixteen ? this.item.sixteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.seventeen ? this.item.seventeen : undefined
    );
    itemInput["itemArray"].push(
      this.item.eighteen ? this.item.eighteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.nineteen ? this.item.nineteen : undefined
    );
    itemInput["itemArray"].push(
      this.item.twenty ? this.item.twenty : undefined
    );
    var array = itemInput["itemArray"];
    var filtered = [];
    filtered = array.filter(function (el) {
      return el != null;
    });

    if (filtered.length == 0 && !this.item.uniqueCheckbox) {
      this.showErrorMessage("Please Enter Unique Ids");
      return;
    } else if (!this.item.tagName || this.item.tagName == " ") {
      this.showErrorMessage("Please Enter Tag Name");
      return;
    } else if (!this.item.tagDesc || this.item.tagDesc == " ") {
      this.showErrorMessage("Please Enter Tag Description");
      return;
    } else if (
      this.item.transFlag &&
      (!this.item.reciever || this.item.reciever == "")
    ) {
      this.showErrorMessage("Please Select User to be Assigned");
      return;
    } else {
      itemInput["itemArray"] = filtered;
      itemInput["itemType"] = Number(itemInput["itemType"]);

      var arr = itemInput["itemArray"];
      var sorted_arr = arr.slice().sort();
      var results = [];
      for (var i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
          results.push(sorted_arr[i]);
        }
      }
      if (results.length > 0) {
        this.showErrorMessage("Duplicate Unique Ids are present");
      } else {
        this.IS.createItem(itemInput).subscribe(createdItem => {
          if (createdItem != undefined && createdItem != null) {

            this.uniqicdata = createdItem
            var navigationExtras = {
              userId:this.uniqicdata[0].userId,
              companyId:this.uniqicdata[0].companyId,
              tagId:this.uniqicdata[0].tagId
            }
            this.router.navigate(['PrintUniqiebarcodes/' + JSON.stringify(navigationExtras)]);
          } else {
            this.showErrorMessage("No Items created");
          }

          // if (createdItem!=null && createdItem!=undefined) {
          //   this.item = {};
          //   itemInput = {};
          // }

        }, error => {
          // console.log('erererererrrrrrreeeeeerrrrrrr',error);
          this.showErrorMessage('Error while creating items.');
          this.item = {};
          itemInput = {};
          this.succesShow = false;
        });
      }
    }
  }
  typeSelect(x) {
  }
  isDisabled = true;
  triggerSomeEvent() {
    this.isDisabled = !this.isDisabled;
    return;
  }
  cancel() {
    this.router.navigate(['/home']);
    this.item = {};
  }
  back() {
    this.succesShow = false;
  }
}
