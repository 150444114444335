import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CookieService } from 'angular2-cookie/core';
@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient, private cookieService: CookieService) { }

    // login(username: string, password: string) {
    //     return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username: username, password: password })
    //         .pipe(map(user => {
    //             // login successful if there's a jwt token in the response
    //             if (user && user.token) {
    //                 // store user details and jwt token in local storage to keep user logged in between page refreshes
    //                 localStorage.setItem('currentUser', JSON.stringify(user));
    //             }

    //             return user;
    //         }));
    // }

    logout() {
        // remove user from local storage to log user out
        this.cookieService.removeAll();
        localStorage.removeItem('currentUser');
    }
}