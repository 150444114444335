import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ItemService } from "../../../_services/item.service";
import { UserService } from "../../../_services/user.service";
import * as _ from 'lodash';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import { Router } from "@angular/router";
import { ExcelService } from '../../../_services/excel.service';
@Component({
  selector: 'app-item-search-report',
  templateUrl: './item-search-report.component.html',
  styleUrls: ['./item-search-report.component.css'],
  providers: [ItemService, UserService]

})
export class ItemSearchReportComponent implements OnInit {
  title = 'TapTrack Item Search Report ';
  userData: any = {}
  companyData: any = {}
  userArr1: any = []
  userArr: any = []
  itemList: any = []
  error3 : any = false;
  item: any = {};
  reportsArry: any = []
  selectedId: any;
  ItemNumber: any;
  itemId: any;
  userArrIds: any = [];
  error1: any = false;
  Valid:any;
  data:any;
  error:any=false
  error2:any=false
  
  constructor(private titleService: Title, private IS: ItemService,
    private US: UserService,
    private excelService: ExcelService,
    private localStorage: LocalStorageService,
    private router: Router, ) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")"
          ;
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);
      }
    });
    let input = {};
    // input = {
    //   companyId: this.companyData.companyId,
    // };
    this.IS.getItemType({companyId : this.companyData.companyId}).subscribe(itemList => {
      this.itemList = itemList;
    });
  }
  generatehtml(ItemNumber, itemId, reciever) {
    if ((ItemNumber && itemId) && ItemNumber != undefined && itemId != undefined) {
      let input = {
        companyId: this.companyData.companyId,
        itemUniqueId: ItemNumber,
        itemTypeId: itemId
      }
      this.US.validate(input).subscribe(count => {
        this.Valid = count
        if (this.Valid > 0) {
          this.error2 = false
          this.selectedId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]

          let query =
          { "userId": this.selectedId ? this.selectedId : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId, "itemTypeId": itemId }
     
          window.open(`#/hrmlReportsearch?query=${JSON.stringify(query)}`, 'name', 'width=800,height=600,scrollbars=1');
        } else {
          this.error2 = true
          this.error = false
              this.error1 = false
          return
        }
      })
    }else if(ItemNumber){

      this.US.findexistance({ itemUniqueId: ItemNumber , companyId : this.companyData.companyId}).subscribe(usrData => {
        this.data = usrData
        if (this.data > 0) {
          this.error = false
          this.error2 = false
          var query = {
            companyId: this.companyData.companyId,
            itemUniqueId: ItemNumber,
            itemTypeId: itemId
          }
          window.open(`#/hrmlReportsearch?query=${JSON.stringify(query)}`, 'name', 'width=800,height=600,scrollbars=1');
        } else {
          this.error = true
        }
      })
  
    }else if(reciever){
      this.error = false
      this.error2 = false
      this.selectedId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
      let query =
        { "userId": this.selectedId ? this.selectedId : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId, "itemTypeId": itemId }
     
      window.open(`#/hrmlReportsearch?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
    }else if(itemId){
      this.selectedId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
      let query =
        { "userId": this.selectedId ? this.selectedId : undefined, "itemUniqueId": '', "companyId": this.companyData.companyId, "itemTypeId": itemId }
      
      window.open(`#/hrmlReportsearch?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
     
    }else{
      this.error = false
      this.error2 = false
      alert('Please Select Any One Field')
    }
 
  }
  // itemreciever(items) {
  //   this.selectedId = JSON.stringify(this.retnum(items))

  // }
  // retnum(str) {
  //   var num = str.replace(/[^0-9]/g, '');
  //   return parseInt(num, 10);
  // }
  clearfields() {
    this.ItemNumber = ''
    this.itemId = ''
    this.item.reciever = ''
    this.error = false
    this.error2 = false
    this.error3 = false
  }
  itmList: any = [];
  usrId: any;
  usrData1: any = [];
  getPdf(ItemNumber, itemId, reciever) {
    let itemInput = {};
    let input = {}
    let y;

    if (ItemNumber || itemId || reciever) {
      //this.item.reciever
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrDataa => {
        this.usrData1 = usrDataa;
        if (this.item.reciever) {
          let x = this.item.reciever.split("(")
          y = x[1].split(")");
          this.usrId = this.usrData1.find(getID);

        }
        function getID(obj) {
          if (obj.username == y[0]) {
            return obj.id
          }
        }
        input = { "userId": (this.usrId) ? this.usrId.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId, "itemTypeId": itemId }
        if (ItemNumber && itemId && !reciever) {
          let qq = {
            companyId: this.companyData.companyId,
            itemUniqueId: ItemNumber,
            itemTypeId: itemId
          }
          this.US.validate(qq).subscribe(count => {
            this.Valid = count
            if (this.Valid > 0) {
              this.error2 = false;
              this.getReport(input)
            } else {
              this.error2 = true
              this.error = false
              this.error1 = false
            return
            }
          });
        } 
        // else {
        //   this.getReport(input)
        // }
        if(ItemNumber && !itemId && !reciever){
        input = { "userId": (this.usrId) ? this.usrId.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId }
          this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
            this.data = usrData
            if (this.data > 0) {
              this.error = false
              this.error2 = false
              this.getReport(input)
            } else {
              this.error = true
            }
          })
        } 
        if(!ItemNumber && itemId && !reciever){
          this.getReport(input)
        }
        if(ItemNumber && itemId && reciever) {
          input = { "userId": (this.usrId) ? this.usrId.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId }
          this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
            this.data = usrData
            if (this.data > 0) {
              this.error = false
              this.error2 = false
              this.getReport(input)
            } else {
              this.error = true
            }
          })
          }
          if(reciever && !ItemNumber && !itemId) {
            this.getReport(input)
          }
          if(reciever && !ItemNumber && itemId) {
            this.getReport(input)
          }
          if(reciever && ItemNumber && !itemId) {
            input = { "userId": (this.usrId) ? this.usrId.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId }
            this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
              this.data = usrData
              if (this.data > 0) {
                this.error = false
                this.error2 = false
                this.getReport(input)
              } else {
                this.error = true
              }
            })
          }
        // else {
        //   this.getReport(input)
        // }
       
      });
    } else {
      this.error3 = true;
      this.error1 = false;
    }

  }

  getReport(input) {
    this.US.getitemsearchreport(input).subscribe(usrData => {
      var daysHeldArr = []
      if (usrData != null && usrData != undefined) {
        this.itmList = usrData;
        const doc = new jsPDF();
        // doc.autoTable({ html: '#my-table' });
        doc.setFontSize(10);
        doc.setDrawColor(0,0, 0, 0);
        // doc.setFontStyle('arial');
        // doc.text(11, 12, "Search Report");
        // doc.text(17, 18, "Items Count =       " + this.itmList.length);
        doc.text(20, 35, "Number Of Items :       " + this.itmList[0].searchList.length);
        // doc.text(23, 24, "Item Total   :    " + this.itmList.totalItems);
        doc.autoTable({
          styles: { width: 60, border: 2, halign: 'center' },
          columnStyles: { text: { cellWidth: 'auto', border: 2 } }, // European countries centered
          body: this.itmList[0].searchList,
          columns: [{ header: 'Item Number', dataKey: 'itemNumber' }, { header: 'Item Type', dataKey: 'itemType' }, { header: 'Tracking', dataKey: 'trackingItem' }, { header: 'Last Held By', dataKey: 'itemHolder' }, { header: 'Days Held', dataKey: 'daysinTracking' }],
          margin: { top: 40, bottom: 20,lineColor:0 },
          tableLineWidth: 0.5,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            fontSize: 10,
            textColor: [25, 25, 25],
            theme: "plane",
            marginBottom: 20,
            lineWidth: 0.5,
            marginTop: 20,
            border: 4,
            // rowHeight: 6,
            // lineColor: 0
          },
          beforePageContent: function (data) {
            doc.setFontSize(20),doc.setFontStyle("bold"), doc.text("Search Report", 75, 20);
          }
        });
        doc.save('ItemSearch.pdf');
      } else {
        
        this.error3 = false;
        this.error1 = true;
      }
    } ,
    error => {
      this.error = true;
    });
  }

  itmListEx: any = [];
  usrIdEx: any;
  usrData1Ex: any = [];

    generateExcel(ItemNumber, itemId, reciever) {
    let itemInput = {};
    let input = {}
    let y;

    if (ItemNumber || itemId || reciever) {
      //this.item.reciever
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrDataa => {
        this.usrData1Ex = usrDataa;
        if (this.item.reciever) {
          let x = this.item.reciever.split("(")
          y = x[1].split(")");
          this.usrIdEx = this.usrData1Ex.find(getID);

        }
        function getID(obj) {
          if (obj.username == y[0]) {
            return obj.id
          }
        }
        input = { "userId": (this.usrIdEx) ? this.usrIdEx.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId, "itemTypeId": itemId }
        if (ItemNumber && itemId && !reciever) {
          let qq = {
            companyId: this.companyData.companyId,
            itemUniqueId: ItemNumber,
            itemTypeId: itemId
          }
          this.US.validate(qq).subscribe(count => {
            this.Valid = count
            if (this.Valid > 0) {
              this.error2 = false;
              this.getExcelReport(input)
            } else {
              this.error2 = true
              this.error = false
              this.error1 = false
            return
            }
          });
        }
        //  else {
        //   this.getExcelReport(input)
        // }
        if(ItemNumber && !itemId && !reciever){
        input = { "userId": (this.usrIdEx) ? this.usrIdEx.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId }
          this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
            this.data = usrData
            if (this.data > 0) {
              this.error = false
              this.error2 = false
              this.getExcelReport(input)
            } else {
              this.error = true
            }
          })
        }
        if(!ItemNumber && itemId && !reciever){
          this.getExcelReport(input)
        }
        if(ItemNumber && itemId && reciever) {
          input = { "userId": (this.usrIdEx) ? this.usrIdEx.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId }
            this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
              this.data = usrData
              if (this.data > 0) {
                this.error = false
                this.error2 = false
                this.getExcelReport(input)
              } else {
                this.error = true
              }
            })
          }
       
          if(reciever && !ItemNumber && !itemId) {
            this.getExcelReport(input)
          }
          if(reciever && !ItemNumber && itemId) {
            this.getExcelReport(input)
          }
          if(reciever && ItemNumber && !itemId) {
            input = { "userId": (this.usrIdEx) ? this.usrIdEx.id : undefined, "itemUniqueId": ItemNumber, "companyId": this.companyData.companyId }
            this.US.findexistance({ itemUniqueId: ItemNumber, companyId : this.companyData.companyId }).subscribe(usrData => {
              this.data = usrData
              if (this.data > 0) {
                this.error = false
                this.error2 = false
                this.getExcelReport(input)
              } else {
                this.error = true
              }
            })
          }
      });
    } else {
      this.error3 = true;
      this.error1 = false;
    }

  }

  getExcelReport(input) {
    this.US.getitemsearchreport(input).subscribe(usrData => {
      var daysHeldArr = []
      if (usrData != null && usrData != undefined) {
        this.itmListEx = usrData;
        var title = 'Search Report';
          var header = ['Item Number','Item Type','Tracking','Last Held By','Days Held'];
          var fileName = 'SearchReport';
          var reportName = 'Search Report';
          var subTitleRow = 'Number Of Items :       '+this.itmListEx[0].searchList.length;
          
          const data1 = [] = this.itmListEx[0].searchList;
          const data = [];
          let data2 = [];
          var self = this
          _.each(data1, function (obj) {
            data2=[];
            data2.push(obj.itemNumber)
            data2.push(obj.itemType)
            data2.push(obj.trackingItem)
            data2.push(obj.itemHolder)
            data2.push(obj.daysinTracking)
            data.push(data2)
          })

          let excelObj = {};
          excelObj = {
            'response' : data,
            'reportName' : reportName,
            'title' : title,
            'header' : header,
            'fileName' : fileName,
            'subTitleRow' : subTitleRow
                      }
          this.excelService.generateExcel(excelObj);
      } else {
        
        this.error3 = false;
        this.error1 = true;
      }
    } ,
    error => {
      this.error = true;
    });
  }
}
