import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "../../../../../node_modules/angular-web-storage";
import { ItemService } from "../../../_services/item.service";
import { UserService } from "../../../_services";
import * as _ from "lodash";
@Component({
  selector: "app-assign-to-user",
  templateUrl: "./assign-to-user.component.html",
  styleUrls: ["./assign-to-user.component.css"],
  providers: [ItemService, UserService]
})
export class AssignToUserComponent implements OnInit {
  title = "TapTrack Assign To User";
  userData: any = {};
  companyData: any = {};
  userArr1: any = [];
  userArr: any = [];
  errrMsg: any = false;
  userId: any;
  companyId: any;
  show: any = false;
  printData: any = {};
  reciever: any;
  detailserror:any;
  constructor(
    private titleService: Title,
    private localStorage: LocalStorageService,
    private IS: ItemService,
    private router: Router,
    private US: UserService
  ) {}
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
      this.userId = this.userData.userId;
      this.companyId = this.userData.companyId;
    }

    this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")";
        this.userArr1[i]["userx"] = nameStr;
        this.userArr.push(nameStr);
      }
    });
  }
  uniqueIds = [];
  useruniqueId: any;
  textAreaString: string = "";
  details: any;
  uniqueId() {
    let input = {};
    input = {
      itemUniqueId: this.useruniqueId,
      userId: this.userId
    };
    this.IS.printBar(input).subscribe(
      data => {
        this.errrMsg = false;
        this.printData = data;
        this.uniqueIds.push(this.printData.itemUniqueId);
        if (this.textAreaString) {
          this.textAreaString += `\n${this.printData.itemUniqueId}`;
        } else {
          this.textAreaString = this.printData.itemUniqueId;
        }
        this.printData.itemUniqueId = "";
        this.useruniqueId = "";
      },
      error => {
        if (error.error.error.statusCode == "500") {
          this.errrMsg = true;
          this.useruniqueId = "";
          this.printData = {};
        }
      }
    );
  }
  submitAssigntoUser() {
    let input = {};
    input = {
      reciever: _.find(this.userArr1, { userx: this.reciever }).id,
      sender: this.userId,
      nextTransactionId: "",
      transStatusId: 1,
      itemIds: this.uniqueIds,
      transMailFlag: 0,
      deletedFlag: 0,
      addedByAdminFlag: 0,
      transComments: ["string", "string2"],
      companyId: this.companyData.companyId
    };
    this.IS.userSubmit(input).subscribe(
      data => {
        this.details = data;
        this.printData.itemUniqueId = "";
        this.show = true;
      },
      error => {
        this.detailserror = 'Required Fields Are missing'
      }
    );
  }
  clear() {
    this.textAreaString = "";
    this.useruniqueId = "";
  }
  cancelProfile() {
    this.router.navigate(["/home"]);
  }
}
