import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ItemService } from "../../../_services/item.service";
import { UserService } from "../../../_services/user.service";
import * as _ from 'lodash';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import {
  LocalStorageService,
  SessionStorageService,
  LocalStorage,
  SessionStorage
} from "angular-web-storage";
import { Router } from "@angular/router";
import { ExcelService } from '../../../_services/excel.service';
// import { Title } from '../../../../node_modules/@angular/platform-browser';
@Component({
  selector: 'app-time-held-report',
  templateUrl: './time-held-report.component.html',
  styleUrls: ['./time-held-report.component.css'],
  providers: [ItemService, UserService]

})
export class TimeHeldReportComponent implements OnInit {
  title = 'TapTrack Time Held Report';
  userData: any = []
  eItem: any = false;
  companyData: any = []
  userArr1: any = []
  ItemNumber: any;
  FromDate: any;
  itemId: any;
  ToDate: any;
  userArr: any = []
  itemList: any = []
  item: any = {};
  error = false;
  errorNoResults = false;
  reportsArry: any = []
  selectedId: any;
  itmList: any = [];
  itmListEx: any = [];
  trackingArr: any = [];
  trackingArrEx: any = [];
  usrData1: any = [];
  usrData1Ex: any = [];
  usrId: any;
  usrIdEx: any;
  error2 = false;
  error3 = false;
  error4 = false;
  error5 = false;
  error6 = false;
  Valid: any;
  ItemValid: any;
  userArrIds: any = []
  // reciever:any;
  constructor(private titleService: Title, private IS: ItemService,
    private US: UserService,
    private excelService: ExcelService,
    private localStorage: LocalStorageService,
    private router: Router, ) {
    this.userData = this.localStorage.get("loginInfo");
    if (this.userData) {
      this.userData = JSON.parse(this.userData);
      this.companyData = this.userData["userDetails"]["companyIdRelations"];
    } else {
    }
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
      this.userArr1 = [];
      this.userArr1 = usrData;
      for (let i = 0; i < this.userArr1.length; i++) {
        let nameStr = "";
        nameStr =
          this.userArr1[i]["userFirstName"] +
          " " +
          this.userArr1[i]["userLastName"] +
          "(" +
          this.userArr1[i]["username"] +
          ")"
          ;
        this.userArr.push(nameStr);
        this.userArrIds.push(this.userArr1[i]["id"]);

      }
    });
    let input = {};
    input = {
      companyId: this.companyData.companyId,
    };
    this.IS.getItemType({companyId : this.companyData.companyId}).subscribe(itemList => {
      this.itemList = itemList;
    });
  }
  generatehtml(ItemNumber, FromDate, itemId, ToDate, reciever) {
    if (ItemNumber || itemId || reciever) {
      if (ItemNumber && itemId) {
        let input = {
          companyId: this.companyData.companyId,
          itemUniqueId: ItemNumber,
          itemTypeId: itemId
        }
        this.US.validate(input).subscribe(count => {
          this.Valid = count
          if (this.Valid > 0) {
            this.error2 = false;
            this.error = false;
            this.error3 = false;
            this.eItem = false;
            this.selectedId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
            let fromDate;
            let toDate;
            if (FromDate && ToDate) {
              let year = FromDate.jsdate.getFullYear()
              let month = FromDate.jsdate.getMonth() + 1
              let date = FromDate.jsdate.getDate()
              let year1 = ToDate.jsdate.getFullYear()
              let month1 = ToDate.jsdate.getMonth() + 1
              let date1 = ToDate.jsdate.getDate()
              if (date <= 9) {
                date = '0' + date
              }
              if (month <= 9) {
                month = '0' + month
              }
              if (date1 <= 9) {
                date1 = '0' + date1
              }
              if (month1 <= 9) {
                month1 = '0' + month1
              }
              fromDate = year + '-' + month + '-' + date
              toDate = year1 + '-' + month1 + '-' + date1
            }
            let query = {
              "itemUniqueId": ItemNumber,
              "companyId": this.companyData.companyId,
              "itemStatusId": 2,
              "userId": this.selectedId ? this.selectedId : this.userData.userId,
              "itemTypeId": itemId,
              "currentOwner": this.selectedId,
              'fromDate': fromDate,
              'toDate': toDate
            }
            window.open(`#/hrmlReporttimeheld?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
          } else {
            this.error2 = true
            this.error = false;
            this.error3 = false;
            this.eItem = false;
            return
          }
        })
      } else
        if (FromDate && ToDate) {
          let year = FromDate.jsdate.getFullYear()
          let month = FromDate.jsdate.getMonth() + 1
          let date = FromDate.jsdate.getDate()
          let year1 = ToDate.jsdate.getFullYear()
          let month1 = ToDate.jsdate.getMonth() + 1
          let date1 = ToDate.jsdate.getDate()
          if (date <= 9) {
            date = '0' + date
          }
          if (month <= 9) {
            month = '0' + month
          }
          if (date1 <= 9) {
            date1 = '0' + date1
          }
          if (month1 <= 9) {
            month1 = '0' + month1
          }
          let fromDate = year + '-' + month + '-' + date
          let Todate = year1 + '-' + month1 + '-' + date1
          this.selectedId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
          let query = {
            "itemUniqueId": ItemNumber,
            "companyId": this.companyData.companyId,
            "itemStatusId": 2,
            "userId": this.selectedId ? this.selectedId : this.userData.userId,
            "itemTypeId": itemId,
            "currentOwner": this.selectedId,
            "fromDate": fromDate,
            "toDate": Todate
          }
          window.open(`#/hrmlReporttimeheld?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
        } else {
          this.selectedId = this.userArrIds[this.userArr.indexOf(this.item.reciever)]
          let query = {
            "itemUniqueId": ItemNumber,
            "companyId": this.companyData.companyId,
            "itemStatusId": 2,
            "userId": this.selectedId ? this.selectedId : this.userData.userId,
            "itemTypeId": itemId,
            "currentOwner": this.selectedId,
          }
          this.US.validateItemUniqueId(query).subscribe(resp => {
            this.ItemValid = resp;
            if (this.ItemValid > 0) {
              window.open(`#/hrmlReporttimeheld?query=${JSON.stringify(query)}`, 'name', 'width=850,height=600,scrollbars=1');
              this.eItem = false;
            } else {
              this.eItem = true;
            }
          })

        }
    } else {
      this.error3 = true;
      this.error2 = false;
      this.error = false;
      this.eItem = false;
    }

  }
  itemreciever(items) {
    this.selectedId = JSON.stringify(this.retnum(items))
  }
  retnum(str) {
    var num = str.replace(/[^0-9]/g, '');
    return parseInt(num, 10);
  }
  clearfields() {
    this.ItemNumber = ''
    this.FromDate = ''
    this.itemId = ''
    this.ToDate = ''
    this.item.reciever = '';
    this.error = false;
    this.error3 = false;
    this.error2 = false;
    this.eItem = false;
  }


  getPdf(ItemNumber, FromDate, itemId, ToDate, reciever) {
    let itemInput = {};
    let y;
    if (ItemNumber || itemId || reciever) {
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrData1 = usrData;
        if (this.item.reciever) {
          let x = this.item.reciever.split("(")
          y = x[1].split(")");
          this.usrId = this.usrData1.find(getID);

        }
        function getID(obj) {
          if (obj.username == y[0]) {
            return obj.id
          }
        }
        let input = {}
        let fromDate;
        let Todate;
        if (FromDate && ToDate) {
          let year = FromDate.jsdate.getFullYear()
          let month = FromDate.jsdate.getMonth() + 1
          let date = FromDate.jsdate.getDate()
          let year1 = ToDate.jsdate.getFullYear()
          let month1 = ToDate.jsdate.getMonth() + 1
          let date1 = ToDate.jsdate.getDate()
          if (date <= 9) {
            date = '0' + date
          }
          if (month <= 9) {
            month = '0' + month
          }
          if (date1 <= 9) {
            date1 = '0' + date1
          }
          if (month1 <= 9) {
            month1 = '0' + month1
          }
          fromDate = year + '-' + month + '-' + date
          Todate = year1 + '-' + month1 + '-' + date1
        }


        input = {
          "itemUniqueId": ItemNumber,
          "companyId": this.companyData.companyId,
          "itemStatusId": 2,
          "userId": (this.usrId) ? this.usrId.id : this.userData.userId,
          "itemTypeId": itemId,
          "currentOwner": (this.usrId) ? this.usrId.id : undefined,
          "fromDate": fromDate,
          "toDate": Todate
        }
        
        if (ItemNumber && itemId) {
          this.US.validate(input).subscribe(count => {
            this.Valid = count
            if (this.Valid > 0) {
              this.error2 = false;
              this.error = false;
              this.error3 = false;
              this.eItem = false;
              this.getReport(input)
            } else {
              this.error2 = true;
              this.error = false;
              this.error3 = false;
              this.eItem = false;
            }
          });

        } else if (ItemNumber) {
          this.US.validateItemUniqueId(input).subscribe(resp => {
            this.ItemValid = resp;
            if (this.ItemValid > 0) {
              this.eItem = false;
              this.getReport(input)
            } else {
              this.eItem = true;
            }
          })
        } else {
          this.getReport(input)
          this.error3 = false;
          this.error2 = false;
          this.error = false;
          this.eItem = false;
        }

      });
    } else {
      this.error3 = true;
      this.error2 = false;
      this.error = false;
      this.eItem = false;
    }


  }

  getReport(input) {
    this.US.gettemiheldreports(input).subscribe(usrData => {
      var daysHeldArr = []
      this.trackingArr = [];
      if (usrData != null && usrData != undefined) {
        this.itmList = usrData;
        if(this.itmList.length > 0) { 
        for (var i = 0; i < this.itmList.length; i++) {
          let obj = {} = this.itmList[i];
          obj['daysHeld'] = this.itmList[i].daysHeld.toFixed(0)
          daysHeldArr.push(parseInt(obj['daysHeld']))
          let date = new Date(this.itmList[i].addItem);
          let month = (date.getMonth() + 1);
              let datee = date.getDate();
          obj['addItem'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes()); // + ':' + date.getSeconds() + '.' + date.getMilliseconds();
          this.trackingArr.push(obj)
        }

        let original = daysHeldArr.reduce((a, b) => a + b, 0) / (this.trackingArr.length);
        let results = Math.round(original * 100) / 100
        let ress = results.toFixed()
        const doc = new jsPDF();
        // doc.autoTable({ html: '#my-table' });
        doc.setFontSize(10);
        // doc.setFontStyle('arial');
        // doc.text(11, 12, "Time Held Report");
        doc.text(20, 35, "Number of Items   :    " + this.trackingArr.length);
        doc.text(120, 35, "Average Number of Days Held :       " + ress);
        // doc.text(19, 20, "Username  :     " + this.usrId.username);
        // doc.text(21, 22, "Avg. Days With Item  :     " + daysHeldArr.reduce((a, b) => a + b, 0)/this.trackingArr.length );
        doc.autoTable({
          styles: { width: 60, border: 2, halign: 'center' },
          columnStyles: { text: { cellWidth: 'auto', border: 2 } }, // European countries centered
          body: this.trackingArr,
          columns: [{ header: 'Item Number', dataKey: 'itemUniqueId' }, { header: 'Item Type', dataKey: 'itemType' }, { header: 'Holder', dataKey: 'holder' }, { header: 'Days Held', dataKey: 'daysHeld' }, { header: 'Date Received', dataKey: 'addItem' }],
          margin: { top: 40, bottom: 20 },
          tableLineWidth: 0.5,
          theme: "grid",
          headStyles: {
            fillColor: [255, 255, 255],
            fontSize: 10,
            textColor: [25, 25, 25],
            theme: "plane",
            marginBottom: 20,
            lineWidth: 0.5,
            marginTop: 20,
            border: 4,
            // rowHeight: 6,
            lineColor: 200
          },
          beforePageContent: function (data) {
            doc.setFontSize(20),doc.setFontStyle("bold"), doc.text("Time Held Report", 75, 20);
          }
        });
        doc.save('TimeHeldReport.pdf');
      } else {
        this.error = true;
        this.error2 = false;
        this.error3 = false;
        this.eItem = false;
      }
    } else {
      this.error = true;
      this.error2 = false;
      this.error3 = false;
      this.eItem = false;
    }
    });
  }
  onDateChanged(fromDate, toDate) {
    if (new Date(fromDate.jsdate) < new Date(toDate.jsdate)) {
      alert('todate should be greater than From date')
      this.FromDate = ''
      this.ToDate = ''
    }

  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  //get excel report

  generateExcel(ItemNumber, FromDate, itemId, ToDate, reciever) {
    let itemInput = {};
    let y;
    if (ItemNumber || itemId || reciever) {
      this.US.getUsers({companyId : this.companyData.companyId}).subscribe(usrData => {
        this.usrData1Ex = usrData;
        if (this.item.reciever) {
          let x = this.item.reciever.split("(")
          y = x[1].split(")");
          this.usrIdEx = this.usrData1Ex.find(getID);

        }
        function getID(obj) {
          if (obj.username == y[0]) {
            return obj.id
          }
        }
        let input = {}
        let fromDate;
        let Todate;
        if (FromDate && ToDate) {
          let year = FromDate.jsdate.getFullYear()
          let month = FromDate.jsdate.getMonth() + 1
          let date = FromDate.jsdate.getDate()
          let year1 = ToDate.jsdate.getFullYear()
          let month1 = ToDate.jsdate.getMonth() + 1
          let date1 = ToDate.jsdate.getDate()
          if (date <= 9) {
            date = '0' + date
          }
          if (month <= 9) {
            month = '0' + month
          }
          if (date1 <= 9) {
            date1 = '0' + date1
          }
          if (month1 <= 9) {
            month1 = '0' + month1
          }
          fromDate = year + '-' + month + '-' + date
          Todate = year1 + '-' + month1 + '-' + date1
        }


        input = {
          "itemUniqueId": ItemNumber,
          "companyId": this.companyData.companyId,
          "itemStatusId": 2,
          "userId": (this.usrIdEx) ? this.usrIdEx.id : this.userData.userId,
          "itemTypeId": itemId,
          "currentOwner": (this.usrIdEx) ? this.usrIdEx.id : undefined,
          "fromDate": fromDate,
          "toDate": Todate
        }
        
        if (ItemNumber && itemId) {
          this.US.validate(input).subscribe(count => {
            this.Valid = count
            if (this.Valid > 0) {
              this.error2 = false;
              this.error = false;
              this.error3 = false;
              this.eItem = false;
              this.getExcelReport(input)
            } else {
              this.error2 = true;
              this.error = false;
              this.error3 = false;
              this.eItem = false;
            }
          });

        } else if (ItemNumber) {
          this.US.validateItemUniqueId(input).subscribe(resp => {
            this.ItemValid = resp;
            if (this.ItemValid > 0) {
              this.eItem = false;
              this.getExcelReport(input)
            } else {
              this.eItem = true;
            }
          })
        } else {
          this.getExcelReport(input)
          this.error3 = false;
          this.error2 = false;
          this.error = false;
          this.eItem = false;
        }

      });
    } else {
      this.error3 = true;
      this.error2 = false;
      this.error = false;
      this.eItem = false;
    }


  }

  getExcelReport(input) {
    this.US.gettemiheldreports(input).subscribe(usrData => {
      var daysHeldArr = []
      this.trackingArrEx = [];
      if (usrData != null && usrData != undefined) {
        this.itmListEx = usrData;
        if(this.itmListEx.length > 0) {
          for (var i = 0; i < this.itmListEx.length; i++) {
            let obj = {} = this.itmListEx[i];
            obj['daysHeld'] = this.itmListEx[i].daysHeld.toFixed(0)
            daysHeldArr.push(parseInt(obj['daysHeld']))
            let date = new Date(this.itmListEx[i].addItem);
            let month = (date.getMonth() + 1);
                let datee = date.getDate();
            obj['addItem'] = date.getFullYear() +'-'+ ((month.toString().length == 1) ? ('0' + month) : month) + '-' + ((datee.toString().length == 1) ? ('0' +datee) : datee) +'  '+ ((date.getHours().toString().length == 1) ? ('0' + date.getHours()) : date.getHours())  +':'+ ((date.getMinutes().toString().length == 1) ? ('0' + date.getMinutes()) : date.getMinutes()); // + ':' + date.getSeconds() + '.' + date.getMilliseconds();
            this.trackingArrEx.push(obj)
          }
  
          let original = daysHeldArr.reduce((a, b) => a + b, 0) / (this.trackingArrEx.length);
          let results = Math.round(original * 100) / 100
          let resultss = results.toFixed();
  
          var title = 'Time Held Report';
          var header = ['Item Number','Item Type','Holder','Days Held','Date Received'];
          var fileName = 'TimeHeldReport';
          var reportName = 'Time Held Report';
          var subTitleRow = 'Number of Items   :    '+this.trackingArrEx.length;
          var itemTotal = 'Average Number of Days Held :   ' + resultss;
          const data1 = [] = this.trackingArrEx;
          const data = [];
          let data2 = [];
          var self = this
          _.each(data1, function (obj) {
            data2=[];
            data2.push(obj.itemUniqueId)
            data2.push(obj.itemType)
            data2.push(obj.holder)
            data2.push(obj.daysHeld)
            data2.push(obj.addItem)
            data.push(data2)
          })
          let excelObj = {};
          excelObj = {
            'response' : data,
            'reportName' : reportName,
            'title' : title,
            'header' : header,
            'fileName' : fileName,
            'subTitleRow' : subTitleRow,
            'itemTotal' : itemTotal
          }
          this.excelService.generateExcel(excelObj);
        } else {
          this.error = true;
          this.error2 = false;
          this.error3 = false;
          this.eItem = false;
        }
       
      } else {
        this.error = true;
        this.error2 = false;
        this.error3 = false;
        this.eItem = false;
      }
    });
  }
}
