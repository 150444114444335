import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../_services/profile.service';
import { Title } from '../../../node_modules/@angular/platform-browser';
import { LocalStorageService } from '../../../node_modules/angular-web-storage';
import { Router } from '../../../node_modules/@angular/router';
import { ActivatedRoute } from '../../../node_modules/@angular/router';
import { LoginService } from '../../app/_services/login.services';
@Component({
  // selector: 'app-addprofile-url',
  templateUrl: './addprofile-url.component.html',
  styleUrls: ['./addprofile-url.component.css'],
  providers: [ProfileService,LoginService]
})
export class AddprofileUrlComponent implements OnInit {
  title = 'Taptrack User Registration'
  userData: any = {};
  companyData: any = {};
  getUserData: any = [];
  locationFlagshw: any = false;
  type : any = {};
  companyName : any = '';
  getStateData : any = [];
  profileData : any = [];
  successfulSave : boolean = false;
  successfulSave1 : boolean = true;
  usernameshw:any = false;
  locationshw:any = false;
  passwordshw:any = false;
  confirmPasswordshw :any = false;
  userFirstNameshw: any = false;
  userLastNameshw:any = false;
  UserStreet1shw:any = false;
  userCityshw:any = false;
  userZipshw:any = false;
  userMobileshw:any =false;
  emailshw :any = false;
  stateIdshw :any = false;
  mailWrng:any = false;
  userTypeIdshw :any = false;
  companyId: any;
  mobileFlag : boolean = false;
  mblFlag : boolean = false;
  lanFlag : boolean = false;
  afzal: any;
  mobileErr : boolean = false;
  constructor(private PS: ProfileService,private titleService:Title,
     private localStorage: LocalStorageService, private router: Router, private route: ActivatedRoute,  private LS: LoginService) {

    // this.userData = this.localStorage.get("loginInfo");
    // if (this.userData) {
    //   this.userData = JSON.parse(this.userData);
    //   this.companyData = this.userData['userDetails']['companyIdRelations'];
    //   this.companyName = this.companyData.companyName;
    //   this.type['companyName'] = this.companyName;
    //   this.type['uniqueId'] = this.companyData.uniqueId;
    // }
    //  else {
    //   this.router.navigate(['/login']);
    // }
    this.route.queryParams.subscribe(params => {
      this.LS.getCompanyDetails({'uniqueId' :params.companyUniqueId}).subscribe(data=> {
        this.type.companyName = data['companyName']
        this.companyId = data['companyId'];
      })
      
  })

  this.type['emailFlag'] = 'emailFlag';
  this.afzal = "emailFlag";
  }
  emailvalidation = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.getUserypes();
    this.getStates();
  }
  getUserypes() {
    this.PS.getUserType().subscribe(getUserData => {
      this.getUserData = getUserData;
    });
  }
  passwrdmatch:any =false;
  getStates() {
    this.PS.getState().subscribe(getStateData => {
      this.getStateData = getStateData;

    });
  }
  cancelProfile() {
    // this.router.navigate(['/home']);
  }
  errorFormFieldsArr = [];
  userProfileFormFields = [
    "username", "locationFlag", "password", "confirmPassword", "userFirstName", "userLastName",
    "UserStreet1", "userCity", "userZip", "userMobile", "email", "stateId", "userTypeId"
  ]
  usernameErr: boolean = false;
  profileErr: boolean = false;
  createProfile() {    
    this.errorFormFieldsArr = [];
    this.userProfileFormFields.forEach((item)=>{
      if (item == 'locationFlag') {
        if (this.type['locationFlag'] == undefined || this.type['locationFlag'] == '' || this.type['locationFlag'] == '') {
          this.locationFlagshw = false;
        }
        if (this.type['locationFlag']) {
          if (this.type['location']) {
            this.locationFlagshw = false;
          } else {
            this.errorFormFieldsArr.push(item);
            this.locationFlagshw = true;
          }
    
        }
      } else if(item == 'userMobile') {
        if(this.type['mobileFlag'] == "mobileFlag") {
         if(this.type[item] == null || this.type[item] == '' || this.type[item] == undefined) {
           this.userMobileshw = true;
           this.errorFormFieldsArr.push(item);
         } else {
           this.userMobileshw = false;
         }
        
       } else {
         this.userMobileshw = false;
       }

     } else {
      if(this.type[item] == null || this.type[item] == ''){
        this.errorFormFieldsArr.push(item);
        this[item + "shw"] = true;
      }else{
        this[item + "shw"] = false;
      }
    }
    });
    // if(this.type.password != this.type.confirmPassword){
    //   this.passwrdmatch = true;
    //   return;
      
    // }else{
    //   this.passwrdmatch = false;
    // }
    if(this.type.password != this.type.confirmPassword){
      this.passwrdmatch = true;
      return;
      
    }else{
      this.passwrdmatch = false;
    }
    if(!this.emailvalidation.test(this.type.email)){
           this.mailWrng = true;
      return;
    }else{
      this.mailWrng = false;
    }
    if(this.errorFormFieldsArr.length > 0){
      return;
    };
    let profileInput = {};
    profileInput = this.type;
    profileInput['companyId'] = this.companyId;
    profileInput['toolTipFlag'] = 0;
    profileInput['userApproved'] = 0;
    profileInput['userMI'] = '';
    profileInput['userPrefix'] = '';
    profileInput['userStatus'] = 0;
    // profileInput['userStatus'] = 1;
    profileInput['emailFlag'] = (profileInput['emailFlag'] == true) ? 1 : 0;
    profileInput['mobileFlag'] = (profileInput['mobileFlag'] == true) ? 1 : 0;

    profileInput['locationFlag'] = (this.type.locationFlag) ? 1 : 0;
    let idd = this.type['userTypeId']
    let usrtype = {};
    usrtype =  this.getUserData.find(getID);
    profileInput['userType'] =usrtype['userType'];
    function getID(obj) {
      if (obj.userTypeId ==idd) {
        return obj.userType
      }
      
    }
    if (profileInput['password'] == profileInput['confirmPassword']) {
      let regInput = {};
      var series = 770;
      regInput = {
        email : profileInput['email'],
        phone : (profileInput['mobileFlag'] == 1) ?  profileInput['userMobile'] : (series.toString()) + Math.floor(1000000 + Math.random() * 9000000).toString(),  //Math.floor(Math.random() * 10000000).toString(), // profileInput['landLine'],
        // countryCode : profileInput['countryCode']
        countryCode : 1
      }
      profileInput['countryCode'] = 1;

  //     if(profileInput['userMobile'].toString().length == 10) {
  //       this.mobileFlag = false;
  //     this.LS.register(regInput).subscribe(regResp => {
  //       profileInput['authId'] = regResp['user']['id'];
  //       profileInput['authFlag'] = 1;
  //     this.PS.createProfile(profileInput).subscribe(profileData => {
  //       this.profileData = profileData;
  //       this.type = {};
  //       this.successfulSave = true;
  //       this.successfulSave1 = false;
  //     },
  //     error =>{
  //     }
      
  //   );
  //   });
  // } else {
  //   this.mobileFlag = true;
  // }

  this.mblFlag = false;
  this.lanFlag = false;
  if(profileInput['mobileFlag'] == 1) {
    if(profileInput['userMobile'].toString().length == 10) {
      this.LS.register(regInput).subscribe(regResp => {
        profileInput['authId'] = regResp['user']['id'];
        profileInput['authFlag'] = 1;
      this.PS.createProfile(profileInput).subscribe(profileData => {
        this.profileData = profileData;
        this.type = {};
        this.successfulSave = true;
        this.successfulSave1 = false;
      }, error => {
        this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
            this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
          if (error.error.error.statusCode == "422") {
            this.usernameErr = true;
          }
          if (error.error.error.statusCode == "500") {
            this.profileErr = true;
          }
          if (error.error.error.statusCode == "421") {
            this.mobileErr = true;
          }
        }

      );
      });
    } else {
      this.mblFlag = true;
    }
  } else if(profileInput['emailFlag'] == 1) {
    this.LS.register(regInput).subscribe(regResp => {
      profileInput['authId'] = regResp['user']['id'];
      profileInput['authFlag'] = 1;
    this.PS.createProfile(profileInput).subscribe(profileData => {
      this.profileData = profileData;
      this.type = {};
      this.successfulSave = true;
      this.successfulSave1 = false;
    }, error => {
      this.type['emailFlag'] = (this.type['emailFlag'] == 1) ? 'emailFlag' : 'mobileFlag';
      this.type['mobileFlag'] = (this.type['mobileFlag'] == 1) ? 'mobileFlag' : 'emailFlag';
        if (error.error.error.statusCode == "422") {
          this.usernameErr = true;
        }
        if (error.error.error.statusCode == "500") {
          this.profileErr = true;
        }
        if (error.error.error.statusCode == "421") {
          this.mobileErr = true;
        }
      }

    );
    });
  }

    }
  }
  isNumberKey(evt) {

    if (isNaN(evt)) {

      this.type.userZip = ''

    }

  }
  isDisabled = true;
  triggerSomeEvent() {
      this.isDisabled = !this.isDisabled;
      return;
  }
  emailF: boolean;
  mobileF:boolean = true;
  triggerEvent1() {
    if(this.type['mobileFlag']=='mobileFlag') {
      this.type['emailFlag'] = ""
      this.emailF = true;
    } else {
      this.type['emailFlag'] = "emailFlag"
      this.emailF = false;
    }
  }
 
  triggerEvent() {
    if(this.type['emailFlag']=='emailFlag') {
      this.type['mobileFlag'] = "";
    } else {
      this.type['mobileFlag'] = "mobileFlag";
    }
    
  }
}
