
import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from "@angular/router";

@Component({
  selector: 'app-edit-item-listtag',
  templateUrl: './edit-item-listtag.component.html',
  styleUrls: ['./edit-item-listtag.component.css']
})
export class EditItemListtagComponent implements OnInit {
  itemUniqueId:any;
  tagData:any={
    tag:{
      tagName:'',
      tagDesc:''
    }
   
  };

  constructor(private US: UserService,private spinner : NgxSpinnerService, private routes: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const routeParams = this.routes.snapshot.params;
    this.itemUniqueId = routeParams.id;
    if(this.itemUniqueId){
      let input = {
        itemUniqueId:this.itemUniqueId
      }
      this.US.getTagDetails(input.itemUniqueId).subscribe(usrData => {
        this.tagData =usrData[0]
      },
     
    );
    }
  }
  EditTag(data){
    this.US.updateFields(data).subscribe(res => {
      if(res){
        alert('Tag Updated Sucessefully')
      }
    })
  }
  cancel(){
    this.router.navigate(['itemList']);

  }
}
