import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppURL } from '../../apiUrl';
import { map } from 'rxjs/operators';
@Injectable()
export class ProfileService {
  private messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource.asObservable();
  constructor(public http: HttpClient) { }

  createProfile(data) {
    return this.http.post(AppURL.serverUrl + 'Users', data).pipe(map((res: Response) => res));
  }

  getUserType() {
    return this.http.get(AppURL.serverUrl+ 'UserTypes').pipe(map((res: Response) => res));
  } 
  getState() {
    return this.http.get(AppURL.serverUrl+ 'States').pipe(map((res: Response) => res));
  } 
  editProfile(data) {
    return this.http.patch(AppURL.serverUrl + 'Users', data).pipe(map((res: Response) => res));
  }

}