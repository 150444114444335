import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from '../../../../../../../node_modules/ngx-spinner';
import * as _ from 'lodash';
@Component({
  selector: 'app-html-report-sentby',
  templateUrl: './html-report-sentby.component.html',
  styleUrls: ['./html-report-sentby.component.css']
})
export class HtmlReportSentbyComponent implements OnInit {
  reportsArry:any =[]
  reportsArryerror:any;
  constructor(private US: UserService, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getData({qeary: params.qeary})
    });
    
  }
  getData(data){
    // alert();
    this.spinner.show();
    this.US.getusersentreports(JSON.parse(data.qeary)).subscribe(usrData => {
      this.reportsArry = usrData;
      _.each(this.reportsArry, function (data) {
       data['name'] = data.Reciever.replace(/ *\([^)]*\) */g, "")
      })
      this.spinner.hide();
    },
   error=>{
    this.reportsArryerror = error.error.error.message
    console.log(this.reportsArryerror)
    this.spinner.hide();
   }
  );
  }
}
