import { Title } from "@angular/platform-browser";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ItemService } from "../_services/item.service";
import { UserService } from "../_services/user.service";
import { LoginService } from '../../app/_services/login.services';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import jsreport from "jsreport-browser-client-dist/jsreport";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import * as _ from 'lodash';
import { ExcelService } from '../_services/excel.service';
@Component({
  selector: "app-item-inventor",
  templateUrl: "./item-inventor.component.html",
  styleUrls: ["./item-inventor.component.css"],
  providers: [ItemService, UserService, LoginService]
})
export class ItemInventorComponent implements OnInit {
  itemNumber: any;
  data_temp: any;
  error: any = false;
  error1: any = false;
  companyId: any;
  companyCity:any;
  title = "Barcode Management Item Inventory ";
  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private IS: ItemService,
    private US: UserService,
    private LS: LoginService,
    private excelService: ExcelService
  ) {}
  uniqueId: any = "";
  itemInvetordata: any = {};
    /**ngOnInit method
   * @constructor
   * the method to call on component loading
   */
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.route.queryParams.subscribe(params => {
      this.uniqueId = params.companyId;
      this.LS.getCompanyDetails({ 'uniqueId': params.companyId }).subscribe(data => {
        this.companyId = data['companyId'];
        this.getCompanyDetails(params);
    });
  });
  
  }
  // htmlreport() {
  //   if (this.itemNumber == null || this.itemNumber == '') {
  //     this.error = true;
  //   } else {
  //     window.open('/hrmlReport', 'name', 'width=1000,height=600, scroll=auto' );
  //     this.error = false;
  //   }
  // }
  pdf() {
    if (
      this.itemInvetordata.itemUniqueId == null ||
      this.itemInvetordata.itemUniqueId == ""
    ) {
      this.error = true;
    } else {
      this.showHtmlReport = true;
      let input = {
        itemUniqueId: this.itemInvetordata.itemUniqueId
      };
      this.IS.getItemInvertory(input).subscribe((itemList: any) => {
        if (itemList.length > 0) {
          this.itemInvetordataArr = itemList;
        }
      });
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI("/hrmlReport") +
          "'></iframe>"
      );
      this.error = false;
    }

    //  let pdfWindow = window.open('')
    //  pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI('yourDocumentBase64VarHere') + "'></iframe>")
  }
  clear() {
    this.itemInvetordata.itemUniqueId = "";
    this.error = false;
    this.error1 = false;
  }
  itemUniqueId: any;
  itemInvetordataArr: any = [];
  showHtmlReport = false;
  /** getItemList method :
   * @constructor
   * @param {String} itemUniqueId - input
   * @param {Number} companyId - input
   * this method gives html report
   */
  getItemList() {
    this.error1 = false;

    if (
      this.itemInvetordata.itemUniqueId == null ||
      this.itemInvetordata.itemUniqueId == ""
    ) {
      this.error = true;
    } else {
      this.showHtmlReport = true;
      let input = {
        itemUniqueId: this.itemInvetordata.itemUniqueId
      };
      /** findexistance api calling
       * usrData - response of an api
       */
      this.US.findexistance({
        itemUniqueId: this.itemInvetordata.itemUniqueId,
        companyId : this.companyId
      }).subscribe(usrData => {
        this.data_temp = usrData;
        if (this.data_temp > 0) {
          var query = {
            itemUniqueId: this.itemInvetordata.itemUniqueId,
            companyId : this.companyId
          }
          // console.log('jjjjjjjjjjjjjjj ', JSON.stringify(query));
          /** to open other window for item inventory report by passing itemUniqueId and companyId */
          window.open(
            `#/hrmlReport?itemUniqueId=${JSON.stringify(query)}`,
            "name",
            "width=1000,height=600"
          );
          this.error = false;
          this.error1 = false;
        } else {
          this.error1 = true;
        }
      });
    }
  }

  /** getPdf method : 
   * to return item inventory pdf report
   * @param {String} itemUniqueId - input
   * @param {Number} companyId - input
   */
  getPdf() {
    if (this.itemInvetordata.itemUniqueId) {
      var itmList = [];
      /** binderInventoryReport api calling */
      this.IS.getItemInvertory({
        itemUniqueId: this.itemInvetordata.itemUniqueId,
        companyId : this.companyId
      }).subscribe((itemList: any) => {
        if (itemList != null && itemList != "" && itemList != undefined) {
          this.error = false;
          this.error1 = false;
          /** itmList is an array which stores response */
          itmList = itemList;
          const doc = new jsPDF();
          // doc.autoTable({ html: '#my-table' });
          doc.setFontSize(10);
          doc.setFontStyle("arial");
          // doc.text(20, 30, "Item Inventory" );
          doc.text(20, 35, "Items Count =       " + itmList.length);
  
          // var header = function(data) {
          //   doc.setFontSize(18);
          //   doc.setTextColor(40);
          //   doc.setFontStyle('normal');
          //   //doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
          //   doc.text("Testing Report", data.settings.margin.left, 50);
          // };
  
          // var options = {
          //   beforePageContent: header,
          //   margin: {
          //     top: 80
          //   },
          //   startY: doc.autoTableEndPosY() + 20
          // };
          /** autoTable for pdf generation */
          doc.autoTable({
            styles: { width: 60, border: 2, halign: "center" },
            columnStyles: { text: { cellWidth: "auto", border: 2 } }, // European countries centered
            body: itmList,
            columns: [
              { header: "Item", dataKey: "itemUniqueId" },
              { header: "Sender", dataKey: "senderName" },
              { header: "Sender To Date", dataKey: "transactionSuccessTime" },
              { header: "Holder", dataKey: "recieverName" }
            ],
            margin: { top: 40, bottom: 20 },
            tableLineWidth: 0.5,
            theme: "grid",
            headStyles: {
              fillColor: [255, 255, 255],
              fontSize: 10,
              textColor: [25, 25, 25],
              theme: "plane",
              marginBottom: 20,
              lineWidth: 0.5,
              marginTop: 20,
              border: 4,
              // rowHeight: 6,
              lineColor: 200
            },
            beforePageContent: function(data) {
              doc.setFontSize(24), doc.text("Item Inventory", 75, 20);
            }
          });
          doc.save("ItemInventory.pdf");
          // window.open(doc.output('bloburl'), '_blank');
          // var blob = new Blob([doc.output('blob')], { type: 'application/pdf' });
          // window.navigator.msSaveOrOpenBlob(doc.output('bloburl'), 'table.pdf');
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // window.navigator.msSaveOrOpenBlob(blob, 'fileName.pdf');
          // var blob = doc.output("bloburl");
          // window.open(URL.createObjectURL(blob));
          // window.navigator.msSaveOrOpenBlob(blob, 'fileName.pdf');
  
          // const data = window.URL.createObjectURL(blob);
          // var link = document.createElement('a');
          // link.href = data;
          // link.download = "file.pdf";
          // link.click();
          // } else {
          // var objectUrl = URL.createObjectURL(doc.output('blob'));
          // window.open(objectUrl);
          // }
  
          // var string = doc.output('datauri');
          // var x = window.open();
          // x.document.open();
          // x.document.location=string;
        } else {
          this.error1 = true;
          this.error = false;
        }
      });
    } else {
      this.error = true;
      this.error1 = false;
    }
   
  }
  generatePdf() {
    const documentDefinition = {
      content: `<html>
    <div>helloo</div>
    </html>
    `
    };
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getDataUrl(dataUrl => {
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='" + dataUrl + "'></iframe>"
      );
      //window.open(dataUrl);
    });
  }

  // reportt() {
  //   this.IS.report({
  //     itemUniqueId: this.itemInvetordata.itemUniqueId
  //   }).subscribe((itemList: any) => {
  //     jsreport.serverUrl = "http://3.19.169.239:5488";
  //     var request = {
  //       template: {
  //         content: itemList,
  //         engine: "handlebars",
  //         recipe: "chrome-pdf"
  //       }
  //     };
  //     //display report in the new tab

  //     jsreport.render("_blank", request);

  //     //display report in placeholder with id reportPlaceholder
  //     jsreport.render("reportPlaceholder", request);

  //     //display report in placeholder element
  //     jsreport.render(document.getElementById("reportPlaceholder"), request);

  //     //open download dialog for report
  //     jsreport.download("myReport.pdf", request);

  //     jsreport.open("myReport.pdf", request);
  //   });
  // }

  generateExcel() {
    if (this.itemInvetordata.itemUniqueId) {
      var itmList = [];
      /** binderInventoryReport api calling */
      this.IS.getItemInvertory({
        itemUniqueId: this.itemInvetordata.itemUniqueId,
        companyId : this.companyId
      }).subscribe((itemList: any) => {
        if (itemList != null && itemList != "" && itemList != undefined) {
          this.error = false;
          this.error1 = false;
          /** itmList is an array which stores response */
          itmList = itemList;
          var title = 'Item Inventory';
          var header = ['Item', 'Sender', 'Sender To Date', 'Holder'];
          var subTitleRow = 'Items Count =    '+itmList.length
          var fileName = 'ItemInventoryReport';
          var reportName = 'Item Inventory Report';
          const data1 = [] = itmList;
          const data = [];
          let data2 = [];
          var self = this
          _.each(data1, function (obj) {
            data2=[];
            data2.push(obj.itemUniqueId)
            data2.push(obj.senderName)
            data2.push(obj.transactionSuccessTime)
            data2.push(obj.recieverName)
            data.push(data2)
          })
          let excelObj = {};
          excelObj = {
            'response' : data,
            'reportName' : reportName,
            'title' : title,
            'header' : header,
            'subTitleRow' : subTitleRow,
            'fileName' : fileName
          }
          // this.excelService.generateExcel(data, reportName,title, header, subTitleRow, fileName);
          this.excelService.generateExcel(excelObj);
        } else {
          this.error1 = true;
          this.error = false;
        }
      });
    } else {
      this.error = true;
      this.error1 = false;
    }
  }
 
  back() {
    this.router.navigate(['/barCodeManagement'], { queryParams: { companyId: this.uniqueId } });
  }
  getCompanyDetails(x) {
    this.LS.getCompanyDetails({'uniqueId' :x.companyId}).subscribe(data=> {
      this.companyCity = data['companyCity'];

    });
  }
}
