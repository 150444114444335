import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { UserLocationService } from '../../../app/_services/user.location.service';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-barcode-print',
  templateUrl: './user-barcode-print.component.html',
  styleUrls: ['./user-barcode-print.component.css'],
  providers: [UserLocationService]
})
export class UserBarcodePrintComponent implements OnInit {

  uniqueId: any ;
  locations: any = []
  width = 3.7;
  width2 = 3.4;
  width3 = 3.1;
  width4 = 2.8;
  width5 = 2.6;
  width6 = 2.4;
  width7 = 2.4;
  width8 = 1.9;
  width9 = 1.7;
  width10 = 1.4;
  width1 = 0.7;
  constructor(private BS: UserLocationService, private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(paramss => {
      this.uniqueId = paramss.itemUniqueId;
      this.getLocationBarCode();
    });
  }

  getLocationBarCode() {
    let input = {};
    input = {
      'uniqueId': JSON.parse(this.uniqueId).itemUniqueId,
      'locationFlag': 0
    }
    
    /** getLocationOrUserCode api calling */
    this.BS.getLocationorUserCodes(input).subscribe(locationData => {
      if (locationData != null && locationData != undefined) {
        this.locations = locationData;
        // console.log('dddddddddddddddddddddddddddddddddddd ', JSON.stringify(this.locations),  this.uniqueId)
        this.calculateBarcodeWidth(this.locations)
      }
      setTimeout(() => {
        // console.log('-------------------', JSON.parse(this.uniqueId).itemUniqueId)
      window.print();
      }
      )

    });
  }
  calculateBarcodeWidth(data) {
    data.map((barcode, index) => {
      if (barcode.userName.length === 1) {
        barcode.barcodeWidthFactor = 1 + ((300 - (66) - (11 * (barcode.userName.length - 1))) / 46)
      } else {
        barcode.barcodeWidthFactor = 1 + ((300 - 66 - (11 * (barcode.userName.length - 1))) / (46 + (11 * (barcode.userName.length - 1))))
      }
    })
  }
}
