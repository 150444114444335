import { Component, OnInit } from '@angular/core';
import { UserService } from "../../_services/user.service";
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
import * as _ from 'lodash';
@Component({
  selector: 'app-item-held-html',
  templateUrl: './item-held-html.component.html',
  styleUrls: ['./item-held-html.component.css']
})
export class ItemHeldHtmlComponent implements OnInit {

  reportsArry:any =[]
  reportsArryerror:any;
  constructor(private US: UserService, private route: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getData({qeary: params.qeary})
    });
    
  }

  getData(data){
    // alert();
    this.spinner.show();
    this.US.getItemsHeldByUser(JSON.parse(data.qeary)).subscribe(usrData => {
      this.reportsArry = usrData;
      // console.log('-------------------------------', this.reportsArry)
      // _.each(this.reportsArry, function (data) {
      //  data['name'] = data.Reciever.replace(/ *\([^)]*\) */g, "")
      // })
      this.spinner.hide();
    },
   error=>{
    this.reportsArryerror = error.error.error.message
    console.log(this.reportsArryerror)
    this.spinner.hide();
   }
  );
  }

}
